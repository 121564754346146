import React from "react";

export function NOC(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width="24px"
      height="24px"
    >
      <g>
        <g>
          <g>
            <path
              fill="#757575"
              d="m66.5 169.324219-.523438 1.335937c-9.1875 23.464844-18.6875 47.726563 8.328126 76.484375 10.914062 11.5 21.898437 11.5 32.519531 11.5 12.074219.265625 23.726562 4.476563 33.175781 11.996094 9.390625-7.503906 20.984375-11.71875 33-12 1.132812 0 2.179688 0 3.257812-.015625.703126-.007812 1.390626-.019531 2.09375-.042969.347657-.011719.691407-.023437 1.039063-.039062 8.273437-.351563 17.164063-1.980469 26.132813-11.433594 11.992187-12.765625 17.738281-26.105469 17.550781-40.757813-.175781-13.445312-5.289063-26.527343-9.402344-37.039062-1.257813-3.226562-2.710937-7-3.949219-10.585938-3.078125-8.90625-4.429687-15.523437-4.382812-21.457031.09375-11.015625 4.984375-19.992187 15.394531-28.246093l1.6875-1.351563-24.738281-28.035156c-19.746094 12.128906-40.703125 13.5-57.785156 3.757812-17.082032 9.742188-38.039063 8.371094-57.785157-3.753906l-24.734375 28.0625 1.699219 1.351563c23.621094 18.734374 15.382813 39.84375 7.421875 60.269531zm24.257812-5.683594c.824219-2.535156 3.015626-4.382813 5.648438-4.765625l25.71875-3.738281 11.5-23.296875c1.179688-2.390625 3.613281-3.902344 6.277344-3.902344 2.664062 0 5.097656 1.511719 6.277344 3.902344l11.5 23.296875 25.714843 3.738281c2.636719.382812 4.824219 2.230469 5.648438 4.765625.824219 2.53125.136719 5.3125-1.769531 7.171875l-18.609376 18.140625 4.394532 25.609375c.449218 2.628906-.628906 5.28125-2.785156 6.847656s-5.011719 1.773438-7.371094.53125l-23-12.089844-23 12.089844c-2.359375 1.242188-5.214844 1.035156-7.371094-.53125s-3.238281-4.21875-2.785156-6.847656l4.390625-25.609375-18.605469-18.140625c-1.910156-1.859375-2.597656-4.640625-1.773438-7.171875zm0 0"
            />
            <path
              fill="#757575"
              d="m125.554688 187.691406-2.617188 15.261719 13.707031-7.199219c2.039063-1.074218 4.472657-1.074218 6.511719 0l13.699219 7.199219-2.617188-15.261719c-.390625-2.269531.363281-4.589844 2.015625-6.199218l11.085938-10.808594-15.320313-2.222656c-2.28125-.332032-4.253906-1.765626-5.273437-3.832032l-6.847656-13.878906-6.851563 13.882812c-1.019531 2.066407-2.988281 3.5-5.269531 3.832032l-15.320313 2.222656 11.085938 10.8125c1.648437 1.605469 2.402343 3.921875 2.011719 6.191406zm0 0"
            />
            <path fill="#757575" d="m261 9.96875v60.03125h57.648438zm0 0" />
            <path
              fill="#757575"
              d="m328 84h-73.742188c-3.976562-.128906-7.160156-3.339844-7.257812-7.316406v-76.683594h-247v448h328zm-287.707031 25.214844c-.167969-1.910156.453125-3.804688 1.722656-5.242188l33.644531-38.171875c2.378906-2.703125 6.425782-3.15625 9.347656-1.046875 25.570313 18.449219 44.097657 9.382813 50.875 4.632813 2.410157-1.691407 5.625-1.691407 8.035157 0 6.777343 4.75 25.300781 13.816406 50.875-4.632813 2.917969-2.109375 6.960937-1.65625 9.34375 1.042969l33.644531 38.121094c1.269531 1.433593 1.894531 3.328125 1.726562 5.234375-.164062 1.910156-1.105468 3.667968-2.601562 4.863281l-7.445312 5.964844c-8.847657 7.015625-10.078126 12.734375-10.117188 17.410156-.035156 4.265625 1.078125 9.4375 3.613281 16.765625 1.160157 3.351562 2.550781 6.972656 3.761719 10.070312 8.875 22.710938 22.296875 57.039063-11.015625 92.5-12.527344 13.199219-25.667969 15.382813-35.71875 15.808594-.40625.019532-.804687.035156-1.210937.046875-.792969.023438-1.570313.039063-2.351563.046875-1.136719.015625-2.234375.015625-3.347656.015625-7.734375.085938-19.753907 3.363281-27.898438 12.316407-1.328125 1.460937-3.207031 2.292968-5.179687 2.292968-1.949219.0625-3.832032-.703125-5.175782-2.113281-8.140624-8.953125-20.210937-12.140625-28.074218-12.140625h-.179688c-11.566406 0-27.445312-.261719-42.445312-16.066406-33.097656-35.234375-20.945313-66.355469-11.183594-91.289063l.519531-1.367187c9.636719-24.710938 10.398438-33.535156-3.09375-44.238282l-7.460937-5.957031c-1.5-1.199219-2.441406-2.957031-2.609375-4.867187zm145.265625 216.785156c0 3.867188-3.132813 7-7 7h-129.191406c-3.863282 0-7-3.132812-7-7s3.136718-7 7-7h129.191406c3.867187 0 7 3.132812 7 7zm86.820312 61h-223.011718c-3.863282 0-7-3.132812-7-7s3.136718-7 7-7h223.011718c3.863282 0 7 3.132812 7 7s-3.136718 7-7 7zm0 0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
