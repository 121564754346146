import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { Passport } from "../../assets/svg/passport";
import { Logout } from "../../assets/svg/logout";
import { History } from "../../assets/svg/history";
import { PCC } from "../../assets/svg/pcc";
import { NOC } from "../../assets/svg/noc";
import { Report } from "../../assets/svg/report";
import { TrackChanges } from "../../assets/svg/track";
import { Upload } from "../../assets/svg/upload";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import appUrls from "../../config/appUrls";
import { logout, logoutclicked } from "../../data/session/actions";
import permissions from "../../config/permissions";
import getUsertype from "../../services/getUsertype";
import IconButton from "@material-ui/core/IconButton";
import styles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Hidden } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Assessment from "@material-ui/icons/AssessmentOutlined";

class NavigationDrawer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  componentDidUpdate(prevProps) { }

  getIcon = feature => {
    switch (feature) {
      case "Apply PCC":
        return <PCC />;
      case "Apply NOC":
        return <NOC />;
      case "Track Application":
        return <TrackChanges />;
      case "PCC":
        return <PCC />;
      case "NOC":
        return <NOC />;
      case "Passport":
        return <Passport />;
      case "History":
        return <History />;
      case "Reports":
        return <Report />;
      case "Daily Report":
        return <Assessment />;
      case "Application Report":
        return <Assessment />;
      case "Passport Upload":
        return <Upload />;
      case "Logout":
        return <Logout />;
      default:
        return "";
    }
  };

  onNavClick = (feature, event) => {
    const { navigateTo, logout, logoutclicked } = this.props;
    event.preventDefault();
    switch (feature) {
      case "Apply PCC": {
        navigateTo(appUrls.PCC);
        break;
      }
      case "Apply NOC": {
        navigateTo(appUrls.NOC);
        break;
      }
      case "Track Application": {
        navigateTo(appUrls.TRACKING_USER);
        break;
      }
      case "PCC": {
        navigateTo(appUrls.PCC_REQUESTS);
        break;
      }
      case "NOC": {
        navigateTo(appUrls.NOC_REQUESTS);
        break;
      }
      case "Passport": {
        navigateTo(appUrls.PASSPORT_REQUESTS);
        break;
      }
      case "History": {
        navigateTo(appUrls.HISTORY);
        break;
      }
      case "Daily Report": {
        navigateTo(appUrls.DAILY_REPORT);
        break;
      }
      case "Application Report": {
        navigateTo(appUrls.APPLICATION_REPORT);
        break;
      }
      case "Passport Upload": {
        navigateTo(appUrls.PASSPORT_DATA_EXPORT);
        break;
      }
      case "Logout": {
        logoutclicked();
        setTimeout(() => {
          logout();
        }, 500);

        break;
      }
      default:
        return "";
    }
  };

  getFeatures = usertype => {
    switch (usertype) {
      case "DYSP":
        return permissions.cp;
      case "DSBO":
        return permissions.sho;
      case "DCRB":
        return permissions.cpo;
      case "FVO":
        return permissions.fvo;
      case "ACP":
        return permissions.acp;
      case "DCP":
        return permissions.dcp;
      case "CUSTOMER":
        return permissions.user;
      default:
        return [];
    }
  };

  getSubMenu = menuText => {
    switch (menuText) {
      case "Reports":
        return ["Daily Report", "Application Report"];
        break;
      default:
        break;
    }
  };

  handleSubMenuClick = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { classes, userInfo, theme, isSideDrawerOpen } = this.props;
    const features = this.getFeatures(getUsertype(userInfo.user_type));
    const sideList = (
      <div className={classes.list}>
        <List>
          {features.map((text, index) =>
            text === "Reports" ? (
              <>
                <ListItem
                  onClick={e => this.handleSubMenuClick()}
                  button
                  key={text}
                >
                  <ListItemIcon>{this.getIcon(text)}</ListItemIcon>
                  <ListItemText primary={text} />
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {this.getSubMenu(text).map((subMenuText, index) => (
                      <ListItem
                        button
                        className={classes.nestedReportStyle}
                        onClick={e => this.onNavClick(subMenuText, e)}
                      >
                        <ListItemIcon>{this.getIcon(subMenuText)}</ListItemIcon>
                        <ListItemText primary={subMenuText} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
                <ListItem
                  onClick={e => this.onNavClick(text, e)}
                  button
                  key={text}
                >
                  <ListItemIcon>{this.getIcon(text)}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              )
          )}
        </List>
      </div>
    );
    return (
      <div>
        <Hidden smDown>
          <Drawer
            variant="permanent"
            open={isSideDrawerOpen}
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: isSideDrawerOpen,
              [classes.drawerClose]: !isSideDrawerOpen
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: isSideDrawerOpen,
                [classes.drawerClose]: !isSideDrawerOpen
              })
            }}
          >
            <Grid className={classes.drawerGrid}>
              <Typography className={classes.appName} variant="h6" noWrap>
                KERALA POLICE
              </Typography>
              <div className={classes.toolbar}>
                <IconButton onClick={() => this.props.closeSideDrawer()}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                      <ChevronLeftIcon />
                    )}
                </IconButton>
              </div>
            </Grid>
            <Grid className={classes.signedIdGrid}>
              <Typography
                xs={12}
                className={classes.appName}
                variant="subheading"
                noWrap
              >
                Signed in as {getUsertype(userInfo.user_type)}
              </Typography>
            </Grid>
            <div>{sideList}</div>
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <Drawer
            open={isSideDrawerOpen}
            className={classes.drawer}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: isSideDrawerOpen,
                [classes.drawerClose]: !isSideDrawerOpen
              })
            }}
          >
            <Grid className={classes.drawerGrid}>
              <Typography className={classes.appName} variant="h6" noWrap>
                KERALA POLICE
              </Typography>
              <div className={classes.toolbar}>
                <IconButton onClick={() => this.props.closeSideDrawer()}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                      <ChevronLeftIcon />
                    )}
                </IconButton>
              </div>
            </Grid>
            <Grid className={classes.signedIdGrid}>
              <Typography
                xs={12}
                className={classes.appName}
                variant="subheading"
                noWrap
              >
                Signed in as {getUsertype(userInfo.user_type)}
              </Typography>
            </Grid>
            <div>{sideList}</div>
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

NavigationDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    userInfo: state.auth.info,
    isLoading: state.auth.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: url => dispatch(push(url)),
    logout: () => dispatch(logout()),
    logoutclicked: () => dispatch(logoutclicked())
  };
}

const styledNavigationDrawer = withStyles(styles, { withTheme: true })(
  NavigationDrawer
);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledNavigationDrawer);
