import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import DetailsForm from "./DetailsForm";
import AddressForm from "./AddressForm";
import PccDetailsForm from "./PccDetailsForm";
import styles from "./styles";
import getUsertype from "../../services/getUsertype";
import { push } from "connected-react-router";
import appUrls from "../../config/appUrls";
import { setHeader } from "../../components/appBar/data/actions";
import { connect } from "react-redux";
import validator from "../../services/validator/";
import { find } from "lodash";
import { applyPCC } from "./data/actions";
import { fetchUserData } from "./data/actions";
import { showAlert } from "../../components/snackbar/data/actions";
import CircularIndeterminate from "../../components/circular/CircularIndeterminate";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import dropdownData from "../../constants";

const steps = ["Applicant Details", "Applicant Address", "PCC Details"];

class PccApplication extends React.Component {
  state = {
    applyFor: "self",
    isOpen: true,
    activeStep: 0,
    chosen_image_name: "",
    chosen_image: "",
    data: {
      isPermanentAddress: true,
      firstName: "",
      middleName: "",
      lastName: "",
      uid: "",
      email: "",
      relationType: "",
      relativeName: "",
      nationality: "",
      mobileNumber: "",
      landline: "",
      identificationType: "",
      identificationNumber: "",
      gender: "",
      dob: "",
      permanentHouseNo: "",
      permanentStreetName: "",
      permanentArea: "",
      permanentVillage: "",
      permanentTehsil: "",
      permanentCountry: "",
      permanentState: "",
      permanentDistrict: "",
      permanentPoliceStation: "",
      permanentPincode: "",
      presentHouseNo: "",
      presentStreetName: "",
      presentArea: "",
      presentVillage: "",
      presentTehsil: "",
      presentCountry: "",
      presentState: "",
      presentDistrict: "",
      presentPoliceStation: "",
      presentPincode: "",
      chosen_file_identity: "",
      chosen_file_identity_name: "",
      chosen_file_address: "",
      chosen_file_address_name: "",
      idProofType: "",
      addressProofType: "",
      pccType: "Without Pic",
      purpose: "",
      applyingReason: "",
      previousPccNumber: "",
      previousDateTaken: "",
      previousApplyingReason: ""
    },
    errors: {
      firstName: "",
      middleName: "",
      lastName: "",
      uid: "",
      email: "",
      relationType: "",
      relativeName: "",
      nationality: "",
      mobileNumber: "",
      landline: "",
      identificationType: "",
      identificationNumber: "",
      gender: "",
      dob: "",
      permanentHouseNo: "",
      permanentStreetName: "",
      permanentArea: "",
      permanentVillage: "",
      permanentTehsil: "",
      permanentCountry: "",
      permanentState: "",
      permanentDistrict: "",
      permanentPoliceStation: "",
      permanentPincode: "",
      presentHouseNo: "",
      presentStreetName: "",
      presentArea: "",
      presentVillage: "",
      presentTehsil: "",
      presentCountry: "",
      presentState: "",
      presentDistrict: "",
      presentPoliceStation: "",
      presentPincode: "",
      idProofType: "",
      addressProofType: "",
      chosen_file_identity: "",
      chosen_file_identity_name: "",
      chosen_file_address: "",
      chosen_file_address_name: "",
      pccType: "",
      purpose: "",
      applyingReason: "",
      previousPccNumber: "",
      previousDateTaken: "",
      previousApplyingReason: ""
    },
    detailsRules: {
      firstName: {
        required: true
      },
      lastName: {
        required: true
      },
      mobileNumber: {
        required: true,
        number: true,
        mobile: true
      },
      nationality: {
        required: true
      },
      gender: {
        required: true
      },
      dob: {
        required: true
      },
      identificationType: {
        required: true
      },
      identificationNumber: {
        required: true
      }
    },
    addressRules: {
      presentHouseNo: { required: true },
      presentVillage: { required: true },
      presentCountry: { required: true },
      presentState: { required: true },
      presentDistrict: { required: true },
      presentPoliceStation: { required: true },
      presentPincode: { required: true },
      permanentHouseNo: { required: true },
      permanentVillage: { required: true },
      permanentCountry: { required: true },
      permanentState: { required: true },
      permanentDistrict: { required: true },
      permanentPoliceStation: { required: true },
      permanentPincode: { required: true }
    },
    presentSameAddressRules: {
      permanentHouseNo: { required: true },
      permanentVillage: { required: true },
      permanentCountry: { required: true },
      permanentState: { required: true },
      permanentDistrict: { required: true },
      permanentPoliceStation: { required: true },
      permanentPincode: { required: true }
    },
    pccDetailsRules: {
      idProofType: { required: true },
      addressProofType: { required: true },
      purpose: { required: true },
      applyingReason: { required: true }
    }
  };

  componentDidMount() {
    if (getUsertype(this.props.userInfo.user_type) !== "CUSTOMER") {
      this.props.navigateTo(appUrls.PCC_REQUESTS);
    }
    this.props.setHeader({ header: "Apply For PCC" });
  }

  componentDidUpdate(prevProps) {
    if (this.props.savePCC && this.props.savePCC !== prevProps.savePCC) {
      let messageInfo = {
        message: this.props.message,
        variant: "success"
      };
      this.props.showAlert(messageInfo);
      this.setState(state => ({
        ...this.state,
        activeStep: 3
      }));
    }
    if (
      this.props.error &&
      !this.props.savePCC &&
      this.props.error !== prevProps.error
    ) {
      let messageInfo = {
        message: this.props.error,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.fetchUserDetails &&
      this.props.fetchUserDetails !== prevProps.fetchUserDetails
    ) {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          firstName: this.props.fetchUserDetails.first_name,
          middleName: this.props.fetchUserDetails.middle_name
            ? this.props.fetchUserDetails.middle_name
            : "",
          lastName: this.props.fetchUserDetails.last_name,
          email: this.props.fetchUserDetails.email,
          nationality: this.props.fetchUserDetails.nationality,
          mobileNumber: this.props.fetchUserDetails.mobile_no,
          landline: this.props.fetchUserDetails.landline_no
            ? this.props.fetchUserDetails.landline_no
            : "",
          identificationType: this.props.fetchUserDetails.id_type
            ? this.props.fetchUserDetails.id_type
            : "",
          identificationNumber: this.props.fetchUserDetails.id_number
            ? this.props.fetchUserDetails.id_number
            : "",
          gender: dropdownData.gender.find(obj => {
            return obj.value === this.props.fetchUserDetails.gender;
          }).name,
          dob: this.props.fetchUserDetails.dob,
          permanentHouseNo: this.props.fetchUserDetails.permanent_address
            .permanentHouseNo
            ? this.props.fetchUserDetails.permanent_address.permanentHouseNo
            : "",
          permanentStreetName: this.props.fetchUserDetails.permanent_address
            .permanentStreetName
            ? this.props.fetchUserDetails.permanent_address.permanentStreetName
            : "",
          permanentArea: this.props.fetchUserDetails.permanent_address
            .permanentArea
            ? this.props.fetchUserDetails.permanent_address.permanentArea
            : "",
          permanentVillage: this.props.fetchUserDetails.permanent_address
            .permanentVillage
            ? this.props.fetchUserDetails.permanent_address.permanentVillage
            : "",
          permanentTehsil: this.props.fetchUserDetails.permanent_address
            .permanentTehsil
            ? this.props.fetchUserDetails.permanent_address.permanentTehsil
            : "",
          permanentCountry: this.props.fetchUserDetails.permanent_address
            .permanentCountry
            ? this.props.fetchUserDetails.permanent_address.permanentCountry
            : "",
          permanentState: this.props.fetchUserDetails.permanent_address
            .permanentState
            ? this.props.fetchUserDetails.permanent_address.permanentState
            : "",
          permanentDistrict: this.props.fetchUserDetails.permanent_address
            .permanentDistrict
            ? this.props.fetchUserDetails.permanent_address.permanentDistrict
            : "",
          permanentPoliceStation: this.props.fetchUserDetails.permanent_address
            .permanentPoliceStation
            ? this.props.fetchUserDetails.permanent_address
                .permanentPoliceStation
            : "",
          permanentPincode: this.props.fetchUserDetails.permanent_address
            .permanentPincode
            ? this.props.fetchUserDetails.permanent_address.permanentPincode
            : "",
          presentHouseNo: this.props.fetchUserDetails.present_address
            .presentHouseNo
            ? this.props.fetchUserDetails.present_address.presentHouseNo
            : "",
          presentStreetName: this.props.fetchUserDetails.present_address
            .presentStreetName
            ? this.props.fetchUserDetails.present_address.presentStreetName
            : "",
          presentArea: this.props.fetchUserDetails.present_address.presentArea
            ? this.props.fetchUserDetails.present_address.presentArea
            : "",
          presentVillage: this.props.fetchUserDetails.present_address
            .presentVillage
            ? this.props.fetchUserDetails.present_address.presentVillage
            : "",
          presentTehsil: this.props.fetchUserDetails.present_address
            .presentTehsil
            ? this.props.fetchUserDetails.present_address.presentTehsil
            : "",
          presentCountry: this.props.fetchUserDetails.present_address
            .presentCountry
            ? this.props.fetchUserDetails.present_address.presentCountry
            : "",
          presentState: this.props.fetchUserDetails.present_address.presentState
            ? this.props.fetchUserDetails.present_address.presentState
            : "",
          presentDistrict: this.props.fetchUserDetails.present_address
            .presentDistrict
            ? this.props.fetchUserDetails.present_address.presentDistrict
            : "",
          presentPoliceStation: this.props.fetchUserDetails.present_address
            .presentPoliceStation
            ? this.props.fetchUserDetails.present_address.presentPoliceStation
            : "",
          presentPincode: this.props.fetchUserDetails.present_address
            .presentPincode
            ? this.props.fetchUserDetails.present_address.presentPincode
            : "",
          isPermanentAddress: this.props.fetchUserDetails.is_address_same
        }
      });
    }
  }

  handleChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  handleRadioButtonChange = event => {
    this.setState({
      ...this.state,
      applyFor: event.target.value
    });
  };

  handleDateChange = (name, date) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: moment(date).format("YYYY-MM-DD")
      }
    });
  };

  handleSameAsPermanent = () => {
    if (this.state.data.isPermanentAddress) {
      this.setState(state => ({
        ...this.state,
        data: {
          ...state.data,
          isPermanentAddress: !state.data.isPermanentAddress,
          presentHouseNo: "",
          presentStreetName: "",
          presentArea: "",
          presentVillage: "",
          presentTehsil: "",
          presentCountry: "",
          presentState: "",
          presentDistrict: "",
          presentPoliceStation: "",
          presentPincode: ""
        }
      }));
    } else {
      this.setState(state => ({
        ...this.state,
        data: {
          ...this.state.data,
          isPermanentAddress: !state.data.isPermanentAddress
        }
      }));
    }
  };

  handleNext = () => {
    if (this.state.activeStep === 0) {
      if (this.validate(this.state.detailsRules, this.state.data)) {
        this.setState(state => ({
          ...this.state,
          activeStep: state.activeStep + 1
        }));
      }
    } else if (this.state.activeStep === 1) {
      if (this.state.data.isPermanentAddress) {
        if (
          this.validate(this.state.presentSameAddressRules, this.state.data)
        ) {
          this.setState(state => ({
            ...this.state,
            activeStep: state.activeStep + 1,
            data: {
              ...state.data,
              presentHouseNo: state.data.permanentHouseNo,
              presentStreetName: state.data.permanentStreetName,
              presentArea: state.data.permanentArea,
              presentVillage: state.data.permanentVillage,
              presentTehsil: state.data.permanentTehsil,
              presentCountry: state.data.permanentCountry,
              presentState: state.data.permanentState,
              presentDistrict: state.data.permanentDistrict,
              presentPoliceStation: state.data.permanentPoliceStation,
              presentPincode: state.data.permanentPincode
            }
          }));
        }
      } else {
        if (this.validate(this.state.addressRules, this.state.data)) {
          this.setState(state => ({
            ...this.state,
            activeStep: state.activeStep + 1
          }));
        }
      }
    } else if (this.state.activeStep === 2) {
      if (
        this.validateIdentityImageField() &&
        this.validateAddressImageField()
      ) {
        const data = new FormData();
        const regex = new RegExp("^(image)");

        if (
          this.state.data.chosen_file_identity &&
          regex.test(this.state.data.chosen_file_identity.type) &&
          this.state.data.chosen_file_address &&
          regex.test(this.state.data.chosen_file_address.type)
        ) {
          data.append(
            "id_proof_image",
            this.state.data.chosen_file_identity,
            this.state.data.chosen_file_identity_name
          );
          data.append(
            "address_proof_image",
            this.state.data.chosen_file_address,
            this.state.data.chosen_file_address_name
          );
          data.append("chosen_image", this.state.chosen_image);
          data.append(
            "data",
            JSON.stringify({
              application_details: this.state.data
            })
          );
          if (this.validate(this.state.pccDetailsRules, this.state.data)) {
            this.props.applyPCC(data);
          }
        }
      }
    }
  };

  validateIdentityImageField() {
    if (this.state.data.chosen_file_identity === "") {
      let messageInfo = {
        message: "Please select a valid identity proof image file!",
        variant: "warning"
      };
      this.props.showAlert(messageInfo);
      return false;
    }
    const { chosen_file_identity: file } = this.state.data;

    const regex = new RegExp("^(image)");
    if (!file) return true;
    if (regex.test(file.type)) {
      if (file.size < 2097152) {
        // image should be grater than 2MB
        return true;
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            chosen_file_identity_name: "Image should be less than 2MB"
          }
        });
        return false;
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          chosen_file_identity_name: "Invalid file type"
        }
      });
      return false;
    }
  }

  validateAddressImageField() {
    if (this.state.data.chosen_file_address === "") {
      let messageInfo = {
        message: "Please select a valid address proof image file!",
        variant: "warning"
      };
      this.props.showAlert(messageInfo);
      return false;
    }
    const { chosen_file_address: file } = this.state.data;

    const regex = new RegExp("^(image)");
    if (!file) return true;
    if (regex.test(file.type)) {
      if (file.size < 2097152) {
        // image should be grater than 2MB
        return true;
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            chosen_file_address_name: "Image should be less than 2MB"
          }
        });
        return false;
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          chosen_file_address_name: "Invalid file type"
        }
      });
      return false;
    }
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleDone = () => {
    this.props.navigateTo(appUrls.TRACKING_USER);
  };

  handleSubmit = () => {
    this.setState(state => ({
      ...this.state,
      isOpen: false
    }));
    if (this.state.applyFor === "self") {
      this.props.fetchUserData(this.props.userInfo.user_id);
    }
  };

  onFileLoad = (e, idType) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (idType === "IDENTITY") {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            chosen_file_identity: file,
            chosen_file_identity_name: file.name
          }
        });
      } else if (idType === "ADDRESS") {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            chosen_file_address: file,
            chosen_file_address_name: file.name
          }
        });
      } else if (idType === "USERIMAGE") {
        this.setState({
          ...this.state,
          chosen_image: file,
          data: {
            ...this.state.data,
            pccType: "With Pic"
          }
        });
      }
    }
  };

  removeFile = (e, idType) => {
    if (idType === "IDENTITY") {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          chosen_file_identity: "",
          chosen_file_identity_name: ""
        }
      });
    } else if (idType === "ADDRESS") {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          chosen_file_address: "",
          chosen_file_address_name: ""
        }
      });
    } else if (idType === "USERIMAGE") {
      this.setState({
        ...this.state,
        chosen_image_name: "",
        chosen_image: "",
        data: {
          ...this.state.data,
          pccType: "Without Pic"
        }
      });
    }
  };

  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, error => error !== "");

    this.setState({ errors });
    return !hasErrors;
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <DetailsForm
            onChange={this.handleChange}
            onDateChange={this.handleDateChange}
            data={this.state.data}
            errors={this.state.errors}
          />
        );
      case 1:
        return (
          <AddressForm
            isSamePermanentAddress={this.state.data.isPermanentAddress}
            onPresentChange={this.handleSameAsPermanent}
            onChange={this.handleChange}
            data={this.state.data}
            errors={this.state.errors}
          />
        );
      case 2:
        return (
          <PccDetailsForm
            onFileLoad={this.onFileLoad}
            removeFile={this.removeFile}
            imageFile={this.state.data.pcc}
            userImageFile={this.state.chosen_image ? true : false}
            identityFile={
              this.state.data.chosen_file_identity_name
                ? this.state.data.chosen_file_identity_name
                : ""
            }
            addressFile={
              this.state.data.chosen_file_address_name
                ? this.state.data.chosen_file_address_name
                : ""
            }
            onChange={this.handleChange}
            onDateChange={this.handleDateChange}
            data={this.state.data}
            errors={this.state.errors}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Application for PCC
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              {activeStep === steps.length ? (
                <>
                  <Typography variant="h5" gutterBottom>
                    Your PCC application is successfully registered.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your application number is #{this.props.applicationNumber}.
                    You can track your application with the provided application
                    number.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleDone}
                    className={classes.button}
                  >
                    Track your application
                  </Button>
                </>
              ) : (
                <>
                  {this.getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      disabled={this.props.isLoading ? true : false}
                      variant="contained"
                      color="primary"
                      onClick={e => {
                        e.preventDefault();
                        this.handleNext();
                      }}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Submit" : "Next"}
                    </Button>
                  </div>
                </>
              )}
            </>
          </Paper>
        </main>
        {this.props.isLoading ? <CircularIndeterminate /> : null}

        <Grid justify="center" container>
          <Grid item sm={12} md={8} xs={12}>
            <div>
              <Dialog
                open={this.state.isOpen}
                disableBackdropClick="true"
                scroll="body"
              >
                <DialogTitle id="responsive-dialog-title">
                  <span color="primary">Apply Certificate For:</span>
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <RadioGroup
                            aria-label="applyFor"
                            name="applyFor"
                            className={classes.group}
                            value={this.state.applyFor}
                            onChange={this.handleRadioButtonChange}
                          >
                            <FormControlLabel
                              value="self"
                              control={<Radio />}
                              label="Self"
                            />
                            <FormControlLabel
                              value="others"
                              control={<Radio />}
                              label="Friends/Relatives"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      className={classes.footerButtons}
                      item
                      xs={12}
                      md={12}
                    >
                      <div className={classes.buttons}>
                        <Button
                          disabled={this.props.isLoading ? true : false}
                          variant="contained"
                          color="primary"
                          onClick={e => {
                            e.preventDefault();
                            this.handleSubmit(e);
                          }}
                          className={classes.button}
                        >
                          OK
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                {this.props.isLoading ? <CircularIndeterminate /> : null}
              </Dialog>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

PccApplication.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    isLoading: state.pcc.isLoading,
    error: state.pcc.error,
    message: state.pcc.message,
    applicationNumber: state.pcc.applicationNumber,
    savePCC: state.pcc.savePCC,
    fetchUserDetails: state.pcc.fetchUserDetails
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHeader: data => dispatch(setHeader(data)),
    navigateTo: url => dispatch(push(url)),
    applyPCC: data => dispatch(applyPCC(data)),
    fetchUserData: data => dispatch(fetchUserData(data)),
    showAlert: messageInfo => dispatch(showAlert(messageInfo))
  };
}

export const styledPccApplication = withStyles(styles)(PccApplication);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledPccApplication);
