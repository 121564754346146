import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { showAlert } from "../../../components/snackbar/data/actions";
import {
  setTabs,
  resetTabs
} from "../../../components/appbarTabs/data/actions";
import { changeTab } from "../../../components/appbarTabs/data/actions";
import {
  listPCCInboundRequests,
  listPCCOutboundRequests,
  resetProps
} from "./data/actions";
import InboundRequests from "./inboundRequests";
import { setHeader } from "../../../components/appBar/data/actions";
import OutboundRequests from "./outboundRequests";
import CircularIndeterminate from "../../../components/circular/CircularIndeterminate";
import getUsertype from "../../../services/getUsertype";
import InboundRequestDialog from "../InboundRequestDialog";
import OutboundRequestDialog from "../OutboundRequestDialog";
import ApplicationDetailsDialog from "../applicationDetailsDialog";
import appUrls from "../../../config/appUrls";

class ManagePCCRequests extends Component {
  state = {
    value: 0,
    expanded: null,
    showInboundDialog: false,
    showOutboundDialog: false,
    showApplicationDialog: false,
    appliationId: "",
    applicationNumber: "",
    isApprove: false
  };

  componentDidMount() {
    if (getUsertype(this.props.userInfo.user_type) === "CUSTOMER") {
      this.props.navigateTo(appUrls.TRACKING_USER);
    }
    this.props.setHeader({ header: "Manage PCC Requests" });
    if (this.props.userInfo.user_type === 1) {
      let tabs = [{ name: "Outbound" }, { name: "Inbound" }];
      this.props.setTabs({ tabs });
    }
  }
  componentWillUnmount() {
    this.props.resetTabs();
    this.props.resetProps();
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      let messageInfo = {
        message: this.props.error,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.outboundSuccess &&
      this.props.outboundSuccess !== prevProps.outboundSuccess
    ) {
      this.handleOutboundDialogClose();
      let messageInfo = {
        message: this.props.outboundMessage,
        variant: "success"
      };
      let params = {
        in_bound: false,
        out_bound: true
      };
      this.props.listPCCOutboundRequests(params);
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.inboundSuccess &&
      this.props.inboundSuccess !== prevProps.inboundSuccess
    ) {
      this.handleInboundDialogClose();
      let messageInfo = {
        message: this.props.inboundMessage,
        variant: "success"
      };
      let params = {
        in_bound: true,
        out_bound: false
      };
      this.props.listPCCInboundRequests(params);
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.outboundError &&
      this.props.outboundError !== prevProps.outboundError
    ) {
      let messageInfo = {
        message: this.props.outboundError,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.inboundError &&
      this.props.inboundError !== prevProps.inboundError
    ) {
      let messageInfo = {
        message: this.props.inboundError,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
  }

  onApplicationDetailsHandler = (id, applicationNumber) => {
    this.setState({
      showApplicationDialog: true,
      appliationId: id,
      applicationNumber: applicationNumber
    });
  };

  inboundActionHandler = (id, applicationNumber, isApprove) => {
    this.setState({
      showInboundDialog: true,
      appliationId: id,
      applicationNumber: applicationNumber,
      isApprove: isApprove
    });
  };

  outboundActionHandler = (id, applicationNumber, isApprove) => {
    this.setState({
      showOutboundDialog: true,
      appliationId: id,
      applicationNumber: applicationNumber,
      isApprove: isApprove
    });
  };

  handleInboundDialogClose = () => {
    this.setState({
      showInboundDialog: false
    });
  };

  handleOutboundDialogClose = () => {
    this.setState({
      showOutboundDialog: false
    });
  };

  handleApplicationsDetailsDialogClose = () => {
    this.setState({
      showApplicationDialog: false
    });
  };

  render() {
    return (
      <Fragment>
        {this.props.currentTab === 0 && (
          <OutboundRequests
            listPCCOutboundRequests={this.props.listPCCOutboundRequests}
            outboundListData={this.props.outboundListData}
            outboundActionHandler={this.outboundActionHandler}
            onApplicationDetailsHandler={this.onApplicationDetailsHandler}
          />
        )}
        {this.props.currentTab === 1 && (
          <InboundRequests
            listPCCInboundRequests={this.props.listPCCInboundRequests}
            inboundListData={this.props.inboundListData}
            inboundActionHandler={this.inboundActionHandler}
            onApplicationDetailsHandler={this.onApplicationDetailsHandler}
          />
        )}
        <InboundRequestDialog
          isOpen={this.state.showInboundDialog}
          handleInboundDialogClose={this.handleInboundDialogClose}
          applicationNumber={this.state.applicationNumber}
          appliationId={this.state.appliationId}
          isApprove={this.state.isApprove}
          showAlert={this.props.showAlert}
        />
        <OutboundRequestDialog
          isOpen={this.state.showOutboundDialog}
          handleOutboundDialogClose={this.handleOutboundDialogClose}
          applicationNumber={this.state.applicationNumber}
          appliationId={this.state.appliationId}
          showAlert={this.props.showAlert}
          isApprove={this.state.isApprove}
        />
        <ApplicationDetailsDialog
          isOpen={this.state.showApplicationDialog}
          handleApplicationsDetailsDialogClose={
            this.handleApplicationsDetailsDialogClose
          }
          appliationId={this.state.appliationId}
        />

        {this.props.isLoading ? <CircularIndeterminate /> : null}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentTab: state.tabs.activeTab,
    userInfo: state.auth.info,
    isLoading: state.managePCCRequests.isLoading,
    inboundListData: state.managePCCRequests.inboundListData,
    outboundListData: state.managePCCRequests.outboundListData,
    inboundMessage: state.pccInboundRequestDialog.inboundMessage,
    inboundSuccess: state.pccInboundRequestDialog.inboundSuccess,
    inboundError: state.pccInboundRequestDialog.error,
    outboundMessage: state.pccOutboundRequestDialog.outboundMessage,
    outboundSuccess: state.pccOutboundRequestDialog.outboundSuccess,
    outboundError: state.pccOutboundRequestDialog.error,
    error: state.managePCCRequests.error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTabs: tabs => dispatch(setTabs(tabs)),
    resetTabs: () => dispatch(resetTabs()),
    setHeader: data => dispatch(setHeader(data)),
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    tabs: data => dispatch(changeTab(data)),
    navigateTo: url => dispatch(push(url)),
    listPCCInboundRequests: params => dispatch(listPCCInboundRequests(params)),
    listPCCOutboundRequests: params =>
      dispatch(listPCCOutboundRequests(params)),
    resetProps: () => dispatch(resetProps())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePCCRequests);
