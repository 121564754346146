import requestHandler from "../../../../services/api/requestHandler";

import { PASSPORT_API } from "../../../../config/apiUrls.js";

import {
  LIST_PASSPORT_INBOUND_REQUESTS,
  LIST_PASSPORT_OUTBOUND_REQUEST,
  RESET_PASSPORT_REQUESTS_PROPS
} from "./constants";

export function listPassportInboundRequests(params) {
  return requestHandler(LIST_PASSPORT_INBOUND_REQUESTS, PASSPORT_API, "GET", {
    params
  });
}

export function listPassportOutboundRequests(params) {
  return requestHandler(LIST_PASSPORT_OUTBOUND_REQUEST, PASSPORT_API, "GET", {
    params
  });
}

export function resetProps() {
  return { type: RESET_PASSPORT_REQUESTS_PROPS };
}
