import { handle } from "redux-pack";

import {
  NOC_OUTBOUND_REQUEST_ACTION,
  NOC_OUTBOUND_RESET_REQUEST
} from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  outboundMessage: "",
  outboundSuccess: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case NOC_OUTBOUND_REQUEST_ACTION:
      return handle(state, action, {
        start: prevState => {
          return {
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time",
          outboundSuccess: false
        }),
        success: prevState => {
          return {
            outboundMessage: payload.data.message,
            outboundSuccess: true
          };
        }
      });

    case NOC_OUTBOUND_RESET_REQUEST:
      return { ...initialState };

    default:
      return state;
  }
};
