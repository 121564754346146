export const styles = theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "4px",
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 4
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 1
    },
    overflowX: "auto"
  },
  rootWithTabs: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "4px",
      marginTop: theme.spacing.unit * 10,
      marginBottom: theme.spacing.unit * 4
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "4px",
      marginTop: theme.spacing.unit * 7
    },
    overflowX: "auto"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    // marginTop: theme.spacing.unit * 1
    marginTop: 4
  },
  iconTextStyle: {
    position: "relative",
    bottom: 7,
    left: 10
  },
  headingGrid: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  dateSummary: {
    marginTop: 8,
    position: "relative",
    marginRight: 8
  },
  tablePagination: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }
});
