import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { DatePicker } from "material-ui-pickers";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachFile from "@material-ui/icons/AttachFile";
import dropdownData from "../../constants";
import { Button } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import DetailsForm from "./DetailsForm";

function NocDetailsForm({
  onFileLoad,
  removeFile,
  userImageFile,
  identityFile,
  addressFile,
  onChange,
  onDateChange,
  data,
  errors
}) {
  return (
    <>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography
            style={{ marginTop: 16, marginBottom: 0 }}
            variant="h6"
            gutterBottom
          >
            NOC Details
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={errors.nocType ? true : false}>
            <InputLabel htmlFor="pcc-purpose">Type of NOC*</InputLabel>
            <Select
              inputProps={{
                name: "nocType",
                id: "nocType"
              }}
              value={data.nocType ? data.nocType : ""}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dropdownData.NOCTypes.map(item => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
            {errors.nocType ? (
              <FormHelperText>{errors.nocType}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl error fullWidth>
            <TextField
              multiline
              required
              id="nocDetails"
              name="nocDetails"
              onChange={onChange}
              value={data.nocDetails}
              label="NOC Details"
              fullWidth
            />
            {errors.nocDetails ? (
              <FormHelperText>{errors.nocDetails}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      {data.nocType && data.nocType === "Vehicle" ? (
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography
              style={{ marginTop: 16, marginBottom: 0 }}
              variant="h6"
              gutterBottom
            >
              Vehicle Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={errors.vehicleType ? true : false}>
              <InputLabel htmlFor="pcc-purpose">Type of Vehilce</InputLabel>
              <Select
                inputProps={{
                  name: "vehicleType",
                  id: "vehicleType"
                }}
                value={data.vehicleType ? data.vehicleType : ""}
                onChange={onChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dropdownData.vehicleTypes.map(item => (
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                ))}
              </Select>
              {errors.vehicleType ? (
                <FormHelperText>{errors.vehicleType}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="registrationNumber"
              name="registrationNumber"
              onChange={onChange}
              value={data.registrationNumber}
              label="Registration Number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="make"
              name="make"
              onChange={onChange}
              value={data.make}
              label="Make"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="engineNumber"
              name="engineNumber"
              onChange={onChange}
              value={data.engineNumber}
              label="Engine Number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="model"
              name="model"
              onChange={onChange}
              value={data.model}
              label="Model"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="chasisNumber"
              name="chasisNumber"
              onChange={onChange}
              value={data.chasisNumber}
              label="Chasis Number"
              fullWidth
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

export default NocDetailsForm;
