export const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  criminalDetailsGrid: {
    padding: theme.spacing.unit * 1.5
  },
  approvalDetailsGrid: {
    padding: theme.spacing.unit * 1.5
  },
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
});
