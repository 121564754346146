import React from "react";

export function Passport(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 488.2 488.2"
      xmlSpace="preserve"
      width="24px"
      height="24px"
    >
      <g>
        <g>
          <g>
            <path
              fill="#757575"
              d="m136.917969 238.867188h-41.496094c5.050781 32.574218 26.292969 59.859374 55.214844 73.363281-.484375-1.234375-.960938-2.496094-1.425781-3.808594-6.855469-19.191406-11.109376-43.476563-12.292969-69.554687zm0 0"
            />
            <path
              fill="#757575"
              d="m176.753906 296.292969c6.605469 19.570312 13.601563 24.78125 15.011719 25.039062h.027344c1.320312-.261719 8.316406-5.476562 14.921875-25.039062 5.347656-15.84375 8.738281-35.839844 9.8125-57.425781h-49.589844c1.078125 21.585937 4.46875 41.582031 9.816406 57.425781zm0 0"
            />
            <path
              fill="#757575"
              d="m149.210938 139.3125c.445312-1.257812.921874-2.527344 1.421874-3.808594-28.917968 13.507813-50.160156 40.792969-55.210937 73.363282h41.496094c1.183593-26.082032 5.4375-50.367188 12.292969-69.554688zm0 0"
            />
            <path
              fill="#757575"
              d="m336.332031 0h-321.332031c-8.285156 0-15 6.714844-15 15v482c0 8.285156 6.714844 15 15 15h321.332031c26.277344 0 47.136719-21.324219 47.136719-47.132812v-417.734376c-.003906-26.339843-21.394531-47.132812-47.136719-47.132812zm-64.265625 415.601562h-160.667968c-8.28125 0-15-6.71875-15-15 0-8.285156 6.71875-15 15-15h160.667968c8.285156 0 15 6.714844 15 15 0 8.28125-6.714844 15-15 15zm-80.332031-64.269531c-70.285156 0-127.46875-57.179687-127.46875-127.464843 0-70.285157 57.183594-127.46875 127.46875-127.46875s127.464844 57.183593 127.464844 127.46875c0 70.285156-57.179688 127.464843-127.464844 127.464843zm0 0"
            />
            <path
              fill="#757575"
              d="m191.734375 126.40625c-1.488281.335938-8.425781 5.617188-14.980469 25.03125-5.347656 15.84375-8.738281 35.839844-9.816406 57.429688h49.589844c-1.074219-21.589844-4.464844-41.582032-9.8125-57.429688-6.554688-19.414062-13.496094-24.695312-14.980469-25.03125zm0 0"
            />
            <path
              fill="#757575"
              d="m234.257812 308.421875c-.449218 1.257813-.921874 2.527344-1.421874 3.808594 28.917968-13.507813 50.15625-40.792969 55.210937-73.363281h-41.5c-1.183594 26.078124-5.4375 50.363281-12.289063 69.554687zm0 0"
            />
            <path
              fill="#757575"
              d="m232.832031 135.503906c.480469 1.234375.957031 2.496094 1.425781 3.804688 6.851563 19.191406 11.105469 43.476562 12.289063 69.558594h41.5c-5.054687-32.574219-26.296875-59.859376-55.214844-73.363282zm0 0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
