import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { DatePicker } from "material-ui-pickers";

import dropdownData from "../../constants";

const PersonalDetails = ({ onChange, onDateChange, data, errors }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Applicant Details
      </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={6}>
          <FormControl error fullWidth>
            <TextField
              required
              id="fname"
              name="firstname"
              value={data.firstname ? data.firstname : ""}
              onChange={onChange}
              label="First Name"
              fullWidth
              autoComplete="fname"
            />
            {errors.firstname ? (
              <FormHelperText>{errors.firstname}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="middlename"
            name="middlename"
            value={data.middlename ? data.middlename : ""}
            onChange={onChange}
            label="Middle Name"
            fullWidth
            autoComplete="middlename"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl error fullWidth>
            <TextField
              required
              id="lname"
              name="lastname"
              value={data.lastname ? data.lastname : ""}
              onChange={onChange}
              label="Last Name"
              fullWidth
              autoComplete="lname"
            />
            {errors.lastname ? (
              <FormHelperText>{errors.lastname}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl error fullWidth>
            <TextField
              id="email"
              name="email"
              value={data.email ? data.email : ""}
              onChange={onChange}
              label="Email"
              fullWidth
              autoComplete="email"
            />
            {errors.email ? (
              <FormHelperText>{errors.email}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl error fullWidth>
            <TextField
              required
              id="mobNum"
              name="mobileNumber"
              value={data.mobileNumber ? data.mobileNumber : ""}
              onChange={onChange}
              label="Mobile Number"
              fullWidth
              autoComplete="mobnumber"
            />
            {errors.mobileNumber ? (
              <FormHelperText>{errors.mobileNumber}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="landline"
            name="landline"
            value={data.landline ? data.landline : ""}
            onChange={onChange}
            label="Landline Number"
            fullWidth
            autoComplete="landline"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="identification-type">
              Identification Type
            </InputLabel>
            <Select
              inputProps={{
                name: "identificationType",
                id: "identification-type"
              }}
              value={data.identificationType ? data.identificationType : ""}
              onChange={onChange}
            >
              <MenuItem value="0">
                <em>None</em>
              </MenuItem>
              {dropdownData.identificationTypes.map(item => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="identificationNumber"
            name="identificationNumber"
            value={data.identificationNumber ? data.identificationNumber : ""}
            onChange={onChange}
            label="Identification Number"
            fullWidth
            autoComplete="idnum"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            error={errors.nationality ? errors.nationality : ""}
          >
            <InputLabel htmlFor="nationality">Nationality*</InputLabel>
            <Select
              inputProps={{
                name: "nationality",
                id: "nationality"
              }}
              required
              value={data.nationality ? data.nationality : ""}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dropdownData.countries.map(item => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            {errors.nationality ? (
              <FormHelperText>{errors.nationality}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={errors.gender ? errors.gender : ""}>
            <InputLabel htmlFor="gender">Gender*</InputLabel>
            <Select
              inputProps={{
                name: "gender",
                id: "gender"
              }}
              value={data.gender ? data.gender : ""}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dropdownData.gender.map(item => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
            {errors.gender ? (
              <FormHelperText>{errors.gender}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error>
            <DatePicker
              fullWidth
              required
              disableFuture
              openTo="year"
              views={["year", "month", "day"]}
              placeholder=""
              format="DD/MM/YYYY"
              mask={value =>
                value
                  ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                  : []
              }
              keyboard
              autoOk
              label="Date Of Birth"
              name="dob"
              value={data.dob ? data.dob : null}
              onChange={date => onDateChange("dob", date)}
              disableOpenOnEnter
            />
            {errors.dob ? <FormHelperText>{errors.dob}</FormHelperText> : ""}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalDetails;
