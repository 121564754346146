import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import Grid from "@material-ui/core/Grid";
import appUrls from "../../config/appUrls";
import SimpleAppBar from "../../components/appBar/";
import NavigationDrawer from "../../components/navigationDrawer/";
import { openSideDrawer, closeSideDrawer } from "./data/actions";
import { showAlert } from "../../components/snackbar/data/actions";
import { setHeader } from "../../components/appBar/data/actions";
import styles from "./styles";
import CircularIndeterminate from "../../components/circular/CircularIndeterminate";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import ChangePasswordDialog from "../../components/Dialogs/changePassword";

class AppShell extends Component {
  componentDidMount() {
    let messageInfo = {
      message: "Welcome!",
      variant: "success"
    };
    this.props.showAlert(messageInfo);
    if (!this.props.isLoggedIn) {
      this.props.navigateTo(appUrls.LOGIN);
    }
  }

  componentDidUpdate() {
    if (!this.props.isLoggedIn) {
      this.props.navigateTo(appUrls.LOGIN);
      let messageInfo = {
        message: "Successfully logged out!",
        variant: "success"
      };
      this.props.showAlert(messageInfo);
    }
  }

  render() {
    const {
      openSideDrawer,
      isSideDrawerOpen,
      closeSideDrawer,
      children,
      classes
    } = this.props;
    return (
      <div className={classes.root}>
        <Grid className={classes.root}>
          <SimpleAppBar
            isSideDrawerOpen={isSideDrawerOpen}
            openSideDrawer={() => openSideDrawer()}
          />
          <Hidden smDown>
            <main
              className={classNames(classes.content, {
                [classes.contentShift]: isSideDrawerOpen
              })}
            >
              <Grid justify="center" container>
                <Grid item xs={12} md={12}>
                  {children}
                  <ChangePasswordDialog
                    isOpen={this.props.isChangePasswordOpen}
                  />
                  {this.props.isLoading ? <CircularIndeterminate /> : null}
                </Grid>
              </Grid>
            </main>
          </Hidden>
          <Hidden mdUp>
            <main className={classes.content}>
              <Grid justify="center" container>
                <Grid item xs={12} md={12}>
                  {children}
                  <ChangePasswordDialog
                    isOpen={this.props.isChangePasswordOpen}
                  />
                  {this.props.isLoading ? <CircularIndeterminate /> : null}
                </Grid>
              </Grid>
            </main>
          </Hidden>
        </Grid>
        <NavigationDrawer
          isSideDrawerOpen={isSideDrawerOpen}
          closeSideDrawer={() => closeSideDrawer()}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.auth.isLoading,
    isSideDrawerOpen: state.appShell.isSideDrawerOpen,
    isLoggedIn: state.auth.isLoggedIn,
    isChangePasswordOpen: state.changePasswordDialog.isOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openSideDrawer: () => dispatch(openSideDrawer()),
    closeSideDrawer: () => dispatch(closeSideDrawer()),
    navigateTo: url => dispatch(push(url)),
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    setHeader: title => dispatch(setHeader(title))
  };
}

export let StyledAppShell = withStyles(styles)(AppShell);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledAppShell);
