import { handle } from "redux-pack";

import {
  PASSPORT_QUESTIONNAIRE,
  SUBMIT_PASSPORT_QUESTIONNAIRE,
  RESET_PASSPORT_QUESTIONNAIRE
} from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  message: "",
  retrievePassportQuestionnaireData: "",
  submitSuccess: false,
  transactionHash: ""
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PASSPORT_QUESTIONNAIRE:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            retrievePassportQuestionnaireData: payload.data.data
          };
        }
      });
    case SUBMIT_PASSPORT_QUESTIONNAIRE:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time",
          submitSuccess: false
        }),
        success: prevState => {
          return {
            ...prevState,
            message: payload.data.message,
            transactionHash: payload.data.transaction_hash,
            submitSuccess: true
          };
        }
      });
    case RESET_PASSPORT_QUESTIONNAIRE:
      return {
        isLoading: false,
        error: null,
        message: "",
        retrievePassportQuestionnaireData: "",
        submitSuccess: false
      };
    default:
      return state;
  }
};
