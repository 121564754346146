import React from "react";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import moment from "moment";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularIndeterminate from "../../components/circular/CircularIndeterminate";
import EmptyListing from "../../components/emptyListing";

const styles = {
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
};

const StepperDialog = ({ open, handleClose, data, isLoading }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen
      fullWidth={true}
    >
      <AppBar style={styles.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" style={styles.flex}>
            Activity Log
          </Typography>
        </Toolbar>
      </AppBar>
      {data.length !== 0 ? (
        <DialogContent>
          <Stepper orientation="vertical">
            {data.map((index, i) => (
              <Step key={i} active={true}>
                <StepLabel>{index.application_status}</StepLabel>
                <StepContent>
                  <Typography>
                    {moment(index.timestamp).format("DD-MM-YYYY HH:mm a")}
                  </Typography>
                  {index.remarks && <Typography>{index.remarks}</Typography>}
                  {index.attachment && (
                    <Typography>{index.attachment}</Typography>
                  )}
                  <Typography>{index.ip_address}</Typography>
                  {index.latitude || index.longitude ? (
                    <Typography>
                      Lat/Long: {index.latitude}, {index.longitude}
                    </Typography>
                  ) : (
                    <Typography>Lat/Long: Unknown, Unknown</Typography>
                  )}
                  {index.location_name ? (
                    <Typography>Location: {index.location_name}</Typography>
                  ) : (
                    <Typography>Location: Location Not Available</Typography>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </DialogContent>
      ) : (
        <EmptyListing emptyText="Application activity log will appear here" />
      )}
      {isLoading ? <CircularIndeterminate /> : null}
    </Dialog>
  );
};
export default StepperDialog;
