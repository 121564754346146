import requestHandler from "../../../../services/api/requestHandler";

import { RETRIEVE_PCC_API, PCC_API } from "../../../../config/apiUrls";

import {
  LIST_PCC_INBOUND_REQUESTS,
  LIST_PCC_OUTBOUND_REQUEST,
  RETRIEVE_PCC,
  RESET_PCC_REQUESTS_PROPS
} from "./constants";

export function listPCCInboundRequests(params) {
  return requestHandler(LIST_PCC_INBOUND_REQUESTS, PCC_API, "GET", {
    params
  });
}

export function listPCCOutboundRequests(params) {
  return requestHandler(LIST_PCC_OUTBOUND_REQUEST, PCC_API, "GET", {
    params
  });
}

export function retrievePCCRequest(id) {
  return requestHandler(RETRIEVE_PCC, `${RETRIEVE_PCC_API}${id}/`, "GET", {});
}

export function resetProps() {
  return { type: RESET_PCC_REQUESTS_PROPS };
}
