import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { DatePicker } from "material-ui-pickers";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachFile from "@material-ui/icons/AttachFile";
import dropdownData from "../../constants";
import { Button } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

function PccDetailsForm({
  onFileLoad,
  removeFile,
  userImageFile,
  identityFile,
  addressFile,
  onChange,
  onDateChange,
  data,
  errors
}) {
  return (
    <>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography
            style={{ marginTop: 16, marginBottom: 0 }}
            variant="h6"
            gutterBottom
          >
            PCC Details
          </Typography>
        </Grid>
        {/* <Grid item xs={12}> */}
        <Grid item xs={6}>
          <Typography
            variant="button"
            style={{ marginTop: 10, marginBottom: 0 }}
          >
            Applicant Photo (Non-mandatory)
          </Typography>
        </Grid>
        {/* </Grid> */}

        {userImageFile ? (
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <Button color="primary">
              <CheckCircle />
            </Button>
            <Button
              component="span"
              // variant="fab"
              color="primary"
              aria-label="upload"
              onClick={e => {
                e.preventDefault();
                removeFile(e, "USERIMAGE");
              }}
            >
              <DeleteIcon />
              Remove
            </Button>
          </Grid>
        ) : (
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <input
              accept="image/*"
              id="upload-user-image-fab"
              style={{ display: "none" }}
              multiple
              type="file"
              onChange={e => {
                e.preventDefault();
                onFileLoad(e, "USERIMAGE");
              }}
            />
            <label htmlFor="upload-user-image-fab">
              <Button
                component="span"
                // variant="fab"
                color="primary"
                aria-label="upload"
              >
                <AttachFile />
                Attach
              </Button>
            </label>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            style={{ marginTop: 16, marginBottom: 0 }}
            variant="button"
            color="primary"
          >
            Proof of Identity
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth error={errors.idProofType ? true : false}>
            <InputLabel htmlFor="id-proof-type">ID Prood Type*</InputLabel>
            <Select
              inputProps={{
                name: "idProofType",
                id: "id-proof-type"
              }}
              value={data.idProofType ? data.idProofType : ""}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dropdownData.identificationTypes.map(item => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
            {errors.idProofType ? (
              <FormHelperText>{errors.idProofType}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        {identityFile ? (
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <Button color="primary">
              <CheckCircle />
            </Button>
            <Button
              component="span"
              // variant="fab"
              color="primary"
              aria-label="upload"
              onClick={e => {
                e.preventDefault();
                removeFile(e, "IDENTITY");
              }}
            >
              <DeleteIcon />
              Remove
            </Button>
          </Grid>
        ) : (
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <input
              accept="image/*"
              id="upload-identity-fab"
              style={{ display: "none" }}
              multiple
              type="file"
              onChange={e => {
                e.preventDefault();
                onFileLoad(e, "IDENTITY");
              }}
            />
            <label htmlFor="upload-identity-fab">
              <Button
                component="span"
                // variant="fab"
                color="primary"
                aria-label="upload"
              >
                <AttachFile />
                Attach
              </Button>
            </label>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            style={{ marginTop: 16, marginBottom: 0 }}
            variant="button"
            color="primary"
          >
            Proof of Address
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth error={errors.addressProofType ? true : false}>
            <InputLabel htmlFor="address-proof-type">
              Address Prood Type*
            </InputLabel>
            <Select
              inputProps={{
                name: "addressProofType",
                id: "address-proof-type"
              }}
              value={data.addressProofType ? data.addressProofType : ""}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dropdownData.addressProofTypes.map(item => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
            {errors.addressProofType ? (
              <FormHelperText>{errors.addressProofType}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        {addressFile ? (
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <Button color="primary">
              <CheckCircle />
            </Button>
            <Button
              component="span"
              // variant="fab"
              color="primary"
              aria-label="upload"
              onClick={e => {
                e.preventDefault();
                removeFile(e, "ADDRESS");
              }}
            >
              <DeleteIcon />
              Remove
            </Button>
          </Grid>
        ) : (
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <input
              accept="image/*"
              id="upload-address-fab"
              style={{ display: "none" }}
              multiple
              type="file"
              onChange={e => {
                e.preventDefault();
                onFileLoad(e, "ADDRESS");
              }}
            />
            <label htmlFor="upload-address-fab">
              <Button
                component="span"
                // variant="fab"
                color="primary"
                aria-label="upload"
              >
                <AttachFile />
                Attach
              </Button>
            </label>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            style={{ marginTop: 16, marginBottom: 0 }}
            variant="button"
            color="primary"
          >
            PCC Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={errors.purpose ? true : false}>
            <InputLabel htmlFor="pcc-purpose">Purpose*</InputLabel>
            <Select
              inputProps={{
                name: "purpose",
                id: "pcc-purpose"
              }}
              value={data.purpose ? data.purpose : ""}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dropdownData.pccTypes.map(item => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
            {errors.purpose ? (
              <FormHelperText>{errors.purpose}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl error fullWidth>
            <TextField
              multiline
              required
              id="applying-reason"
              name="applyingReason"
              onChange={onChange}
              value={data.applyingReason}
              label="Reason for applying"
              fullWidth
            />
            {errors.applyingReason ? (
              <FormHelperText>{errors.applyingReason}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 16, marginBottom: 0 }}>
          <Typography inline variant="button" color="primary">
            Previous PCC Details{"   "}
          </Typography>
          <Typography variant="subtitle2" inline color="textPrimary">
            (If applicable)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="pcc-number"
            name="previousPccNumber"
            onChange={onChange}
            value={data.previousPccNumber}
            label="PCC Number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <DatePicker
              fullWidth
              disableFuture
              openTo="year"
              views={["year", "month", "day"]}
              placeholder=""
              format="DD/MM/YYYY"
              mask={value =>
                value
                  ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                  : []
              }
              keyboard
              autoOk
              label="Date Taken"
              name="previousDateTaken"
              value={data.previousDateTaken ? data.previousDateTaken : null}
              onChange={date => onDateChange("previousDateTaken", date)}
              disableOpenOnEnter
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            id="previous-applying-reason"
            name="previousApplyingReason"
            onChange={onChange}
            value={data.previousApplyingReason}
            label="Previous reason for applying"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

export default PccDetailsForm;
