import { handle } from "redux-pack";

import { RESET_DAILY_REPORT_PROPS, DAILY_REPORT } from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  dailyReportData: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DAILY_REPORT:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: false,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => ({
          ...prevState,
          dailyReportData: payload.data
        })
      });
    case RESET_DAILY_REPORT_PROPS:
      return {
        ...state,
        isLoading: false,
        error: null,
        dailyReportData: null
      };
    default:
      return state;
  }
};
