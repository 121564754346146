import requestHandler from "../../../services/api/requestHandler";

import {
  NOC_LOG_API,
  NOC_CERTIFICATE_VIEW_API,
  PCC_API,
  PCC_LOG_API,
  PCC_CERTIFICATE_VIEW_API,
  NOC_API
} from "../../../config/apiUrls.js";

import {
  PCC_CERTIFICATE_VIEW,
  NOC_CERTIFICATE_VIEW,
  RESET_USER_TRACKING_LOG,
  PCC_HASHCODE_VIEW,
  NOC_HASHCODE_VIEW,
  RESET_USER_TRACKING_VIEW_HASH,
  PCC_USER_TRACKING_LIST,
  NOC_USER_TRACKING_LIST,
  PCC_USER_TRACKING_LOG,
  NOC_USER_TRACKING_LOG,
  RESET_USER_TRACKING_PROPS
} from "./constants";

export function getPCCs(params) {
  return requestHandler(PCC_USER_TRACKING_LIST, PCC_API, "GET", {
    params
  });
}

export function getNOCs(params) {
  return requestHandler(NOC_USER_TRACKING_LIST, NOC_API, "GET", { params });
}

export function getPCCHistory(id) {
  return requestHandler(
    PCC_USER_TRACKING_LOG,
    `${PCC_LOG_API}${id}/`,
    "GET",
    {}
  );
}

export function getNOCHistory(id) {
  return requestHandler(
    NOC_USER_TRACKING_LOG,
    `${NOC_LOG_API}${id}/`,
    "GET",
    {}
  );
}

export function getPCCCertificate(id) {
  return requestHandler(
    PCC_CERTIFICATE_VIEW,
    `${PCC_CERTIFICATE_VIEW_API}${id}/`,
    "GET",
    {}
  );
}
export function getNOCCertificate(id) {
  return requestHandler(
    NOC_CERTIFICATE_VIEW,
    `${NOC_CERTIFICATE_VIEW_API}${id}/`,
    "GET",
    {}
  );
}
export function getPCCHashCode(id) {
  return requestHandler(
    PCC_HASHCODE_VIEW,
    `${PCC_CERTIFICATE_VIEW_API}${id}/`,
    "GET",
    {}
  );
}

export function getNOCHashCode(id) {
  return requestHandler(
    NOC_HASHCODE_VIEW,
    `${NOC_CERTIFICATE_VIEW_API}${id}/`,
    "GET",
    {}
  );
}

export function resetLog() {
  return { type: RESET_USER_TRACKING_LOG };
}

export function resetViewHash() {
  return { type: RESET_USER_TRACKING_VIEW_HASH };
}

export function resetProps() {
  return { type: RESET_USER_TRACKING_PROPS };
}
