import { handle } from "redux-pack";

import { PASSPORT_DATA_EXPORT, RESET_PROP } from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  message: "",
  verificationResponse: ""
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PASSPORT_DATA_EXPORT:
      return handle(state, action, {
        start: prevState => {
          return {
            ...state,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...state,
          error:
            payload.response !== undefined
              ? payload.response.data.error
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...state,
            verificationResponse: payload.data
          };
        }
      });
    case RESET_PROP:
      return {
        isLoading: false,
        error: null,
        message: "",
        verificationResponse: ""
      };
    default:
      return state;
  }
};
