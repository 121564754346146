import { fade } from "@material-ui/core/styles/colorManipulator";

export const styles = theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "4px",
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 4
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "4px",
      marginTop: theme.spacing.unit * 1
    },
    overflowX: "auto"
  },
  rootWithTabs: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "4px",
      marginTop: theme.spacing.unit * 10,
      marginBottom: theme.spacing.unit * 4
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "4px",
      marginTop: theme.spacing.unit * 7
    },
    overflowX: "auto"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    // marginTop: theme.spacing.unit * 1
    marginLeft: theme.spacing.unit * 1.5
  },
  iconTextStyle: {
    position: "relative",
    bottom: 7,
    left: 10,
    color: "rgba(0, 0, 0, 0.54)"
  },
  headingGrid: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  dateSummary: {
    marginTop: 8,
    position: "relative",
    marginRight: 8
  },
  tablePagination: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  divStyle: {
    display: "flex",
    alignItems: "center"
  },
  avatarStyle: {
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10,
    color: "#fff",
    backgroundColor: theme.palette.secondary.light,
    boxShadow:
      "0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  nameGridStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: theme.spacing.unit * 1
  },
  statusTextStyle: {
    fontSize: "medium",
    // fontWeight: "bold",
    color: "#757575",
    marginTop: theme.spacing.unit * 0.5,
    marginLeft: theme.spacing.unit * 1.5
  },
  searchGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%"
  },
  search: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade("#EEEEEE", 1),
    "&:focus": {
      backgroundColor: fade("#f5f5f5", 1)
    }
  },
  searchDiv: {
    width: "100%"
  },
  searchIcon: {
    padding: theme.spacing.unit * 1
  },
  inputRoot: {
    color: "inherit",
    height: "100%",
    width: "80%",
    paddingTop: theme.spacing.unit * 0.5
  }
});
