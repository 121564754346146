import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { showAlert } from "../../../components/snackbar/data/actions";
import {
  setTabs,
  resetTabs
} from "../../../components/appbarTabs/data/actions";
import { changeTab } from "../../../components/appbarTabs/data/actions";
import {
  listPassportInboundRequests,
  listPassportOutboundRequests,
  resetProps
} from "./data/actions";
import InboundRequests from "./inboundRequests";
import { setHeader } from "../../../components/appBar/data/actions";
import OutboundRequests from "./outboundRequests";
import CircularIndeterminate from "../../../components/circular/CircularIndeterminate";
import getUsertype from "../../../services/getUsertype";
import OutboundRequestDialog from "../OutboundRequestDialog";
import appUrls from "../../../config/appUrls";
import ApplicationDetailsDialog from "../applicationDetailsDialog";
import QuestionnaireDialog from "../questionnaireDialog";
import Grid from "@material-ui/core/Grid";

class ManagePassportRequests extends Component {
  state = {
    value: 0,
    expanded: null,
    showInboundDialog: false,
    showOutboundDialog: false,
    showApplicationDialog: false,
    showQuestionnaireDialog: false,
    appliationId: "",
    applicationNumber: "",
    isApprove: false
  };

  componentDidMount() {
    if (getUsertype(this.props.userInfo.user_type) === "CUSTOMER") {
      this.props.navigateTo(appUrls.TRACKING_USER);
    }
    this.props.setHeader({ header: "Manage Passport Requests" });
    if (this.props.userInfo.user_type === 2) {
      let tabs = [{ name: "Outbound" }, { name: "Inbound" }];
      this.props.setTabs({ tabs });
    }
  }

  componentWillUnmount() {
    this.props.resetTabs();
    this.props.resetProps();
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      let messageInfo = {
        message: this.props.error,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.outboundSuccess &&
      this.props.outboundSuccess !== prevProps.outboundSuccess
    ) {
      this.handleOutboundDialogClose();
      let messageInfo = {
        message:
          this.props.outboundMessage +
          (this.props.transactionHash ? " " + this.props.transactionHash : ""),
        variant: "success"
      };
      let params = {
        outbound: true,
        per_page: 10,
        page_no: 1
      };
      this.props.listPassportOutboundRequests(params);
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.outboundError &&
      this.props.outboundError !== prevProps.outboundError
    ) {
      let messageInfo = {
        message: this.props.outboundError,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.submitQuestionnaireSuccess &&
      this.props.submitQuestionnaireSuccess !==
        prevProps.submitQuestionnaireSuccess
    ) {
      let params = {
        per_page: 10,
        page_no: 1,
        outbound: true
      };
      this.props.listPassportOutboundRequests(params);
    }
  }

  onApplicationDetailsHandler = (id, applicationNumber) => {
    this.setState({
      showApplicationDialog: true,
      appliationId: id,
      applicationNumber: applicationNumber
    });
  };

  handleApplicationsDetailsDialogClose = () => {
    this.setState({
      showApplicationDialog: false
    });
  };

  handleQuestionnaireDialogClose = () => {
    this.setState({
      showQuestionnaireDialog: false
    });
  };

  inboundActionHandler = (id, applicationNumber, isApprove) => {
    this.setState({
      showInboundDialog: true,
      appliationId: id,
      applicationNumber: applicationNumber,
      isApprove: isApprove
    });
  };

  outboundActionHandler = (id, applicationNumber, isApprove) => {
    if (this.props.userInfo.user_type === 3) {
      this.props.navigateTo(`${appUrls.CCTNS_RESULTS}/${id}`);
    } else if (this.props.userInfo.user_type === 2) {
      this.setState({
        showQuestionnaireDialog: true,
        appliationId: id
      });
    } else {
      this.setState({
        showOutboundDialog: true,
        appliationId: id,
        applicationNumber: applicationNumber,
        isApprove: isApprove
      });
    }
  };

  handleInboundDialogClose = () => {
    this.setState({
      showInboundDialog: false
    });
  };

  handleOutboundDialogClose = () => {
    this.setState({
      showOutboundDialog: false
    });
  };

  render() {
    return (
      <Grid justify="center" container>
        <Grid item xs={12} md={8}>
          {this.props.currentTab === 0 && (
            <OutboundRequests
              listPassportOutboundRequests={
                this.props.listPassportOutboundRequests
              }
              outboundListData={this.props.outboundListData}
              handleOutboundDialogClose={this.handleOutboundDialogClose}
              outboundActionHandler={this.outboundActionHandler}
              onApplicationDetailsHandler={this.onApplicationDetailsHandler}
            />
          )}
          {this.props.currentTab === 1 && (
            <InboundRequests
              isOpen={this.state.showInboundDialog}
              listPassportInboundRequests={
                this.props.listPassportInboundRequests
              }
              inboundListData={this.props.inboundListData}
              inboundActionHandler={this.inboundActionHandler}
              onApplicationDetailsHandler={this.onApplicationDetailsHandler}
            />
          )}
          <OutboundRequestDialog
            isOpen={this.state.showOutboundDialog}
            handleOutboundDialogClose={this.handleOutboundDialogClose}
            applicationNumber={this.state.applicationNumber}
            appliationId={this.state.appliationId}
            isApprove={this.state.isApprove}
          />
          <ApplicationDetailsDialog
            isOpen={this.state.showApplicationDialog}
            handleApplicationsDetailsDialogClose={
              this.handleApplicationsDetailsDialogClose
            }
            appliationId={this.state.appliationId}
          />
          <QuestionnaireDialog
            isOpen={this.state.showQuestionnaireDialog}
            handleQuestionnaireDialogClose={this.handleQuestionnaireDialogClose}
            appliationId={this.state.appliationId}
          />
          {this.props.isLoading ? <CircularIndeterminate /> : null}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentTab: state.tabs.activeTab,
    userInfo: state.auth.info,
    isLoading: state.managePassportRequests.isLoading,
    inboundListData: state.managePassportRequests.inboundListData,
    outboundListData: state.managePassportRequests.outboundListData,
    outboundMessage: state.passportOutboundRequestDialog.outboundMessage,
    outboundSuccess: state.passportOutboundRequestDialog.outboundSuccess,
    outboundError: state.passportOutboundRequestDialog.error,
    error: state.managePassportRequests.error,
    transactionHash: state.passportOutboundRequestDialog.transactionHash,
    submitQuestionnaireSuccess: state.passportQuestionnaire.submitSuccess
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTabs: tabs => dispatch(setTabs(tabs)),
    resetTabs: () => dispatch(resetTabs()),
    setHeader: data => dispatch(setHeader(data)),
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    tabs: data => dispatch(changeTab(data)),
    navigateTo: url => dispatch(push(url)),
    listPassportInboundRequests: params =>
      dispatch(listPassportInboundRequests(params)),
    listPassportOutboundRequests: params =>
      dispatch(listPassportOutboundRequests(params)),
    resetProps: () => dispatch(resetProps())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePassportRequests);
