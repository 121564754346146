export const styles = theme => ({
  paper: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[10],
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 4,
    [theme.breakpoints.up("md")]: {
      width: theme.spacing.unit * 80
    }
  },
  footerButtons: {
    padding: "0 5px",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1
  },
  subTitle: {
    margin: "10px 0",
    paddingLeft: "10px"
  },
  textViewGridContainer: {
    paddingRight: 2.5,
    paddingLeft: 2.5
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3
  }
});
