import { handle } from "redux-pack";

import {
  LIST_PCC_INBOUND_REQUESTS,
  LIST_PCC_OUTBOUND_REQUEST,
  RETRIEVE_PCC,
  RESET_PCC_REQUESTS_PROPS
} from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  message: "",
  inboundListData: [],
  outboundListData: [],
  retrievePCCRequestData: [],
  totalCount: 0
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_PCC_INBOUND_REQUESTS:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            message: payload.data.message,
            inboundListData: payload.data.results,
            totalCount: payload.data.total_count
          };
        }
      });
    case LIST_PCC_OUTBOUND_REQUEST:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            message: payload.data.message,
            outboundListData: payload.data.results,
            totalCount: payload.data.total_count
          };
        }
      });
    case RETRIEVE_PCC:
      return handle(state, action, {
        start: prevState => {
          return {
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            message: payload.data.message,
            retrievePCCRequestData: payload.data
          };
        }
      });
    case RESET_PCC_REQUESTS_PROPS:
      return {
        isLoading: false,
        error: null,
        message: "",
        inboundListData: [],
        outboundListData: [],
        retrievePCCRequestData: [],
        totalCount: 0
      };
    default:
      return state;
  }
};
