import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#5c43e9",
      main: "#0016b5",
      dark: "#000084",
      contrastText: "#fff"
    },
    secondary: {
      light: "#EF5350",
      main: "#C62828",
      dark: "#B71C1C",
      contrastText: "#fff"
    }
  }
});

export default theme;
