import {
  PASSPORT_OUTBOUND_REQUEST_ACTION,
  PASSPORT_OUTBOUND_RESET_REQUEST
} from "./constants";
import requestHandler from "../../../../services/api/requestHandler";
import { PASSPORT_API } from "../../../../config/apiUrls.js";

export function outboundRequestAction(id, data) {
  return requestHandler(
    PASSPORT_OUTBOUND_REQUEST_ACTION,
    `${PASSPORT_API}${id}/`,
    "PUT",
    {
      data
    }
  );
}

export function resetProps() {
  return { type: PASSPORT_OUTBOUND_RESET_REQUEST };
}
