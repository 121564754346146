import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { find } from "lodash";
import { connect } from "react-redux";
import { styles } from "./styles";
import validator from "../../../services/validator";
import { inboundRequestAction, resetProps } from "./data/actions";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CircularIndeterminate from "../../../components/circular/CircularIndeterminate";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachFile from "@material-ui/icons/AttachFile";
import TextField from "@material-ui/core/TextField";

class InboundRequestDialog extends React.Component {
  constructor(props) {
    super(props);
    var today = moment(new Date()).format("YYYY-MM-DDTHH:mm");
    this.state = {
      data: {
        chosen_file: "",
        chosen_file_name: "",
        remarks: ""
      },
      errors: {
        remarks: "",
        chosen_file: "",
        chosen_file_name: ""
      },
      rules: {
        chosen_file: {
          required: true
        },
        chosen_file_name: {
          required: true
        },
        remarks: {
          required: true
        }
      }
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  handleChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  onSubmitHandler = () => {
    //User type Commisioner
    if (this.props.userInfo.user_type === 1) {
      if (this.validateImageField()) {
        const formData = new FormData();
        const regex = new RegExp("^(image)");
        if (
          this.state.data.chosen_file &&
          regex.test(this.state.data.chosen_file.type)
        ) {
          formData.append("chosen_image", this.state.data.chosen_file);
          formData.append("approve", this.props.isApprove);
          formData.append("remarks", this.state.data.remarks);
          if (this.validate(this.state.rules, this.state.data)) {
            this.props.inboundRequestAction(this.props.appliationId, formData);
          }
        }
      }
    }
  };

  handleCancel = () => {
    this.props.handleInboundDialogClose();
  };

  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, error => error !== "");

    this.setState({ errors });
    return !hasErrors;
  };

  onFileLoad = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.setState({
        data: {
          ...this.state.data,
          chosen_file: file,
          chosen_file_name: file.name
        }
      });
    }
  };

  removeFile = e => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        chosen_file: "",
        chosen_file_name: ""
      }
    });
  };

  validateImageField() {
    if (this.state.data.chosen_file === "") {
      let messageInfo = {
        message: "Please select a valid image file!",
        variant: "warning"
      };
      this.props.showAlert(messageInfo);
      return false;
    }
    const { chosen_file: file } = this.state.data;

    const regex = new RegExp("^(image)");
    if (!file) return true;
    if (regex.test(file.type)) {
      if (file.size < 2097152) {
        // image should be grater than 2MB
        return true;
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            chosen_file_name: "Image should be less than 2MB"
          }
        });
        return false;
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          chosen_file_name: "Invalid file type"
        }
      });
      return false;
    }
  }

  resetState = () => {
    this.setState({});
  };

  render() {
    const { classes, fullScreen, isOpen, applicationNumber } = this.props;
    return (
      <Grid justify="center" container>
        <Grid item sm={12} md={8} xs={12}>
          <div>
            <Dialog
              fullScreen={fullScreen}
              open={isOpen}
              disableBackdropClick="true"
              scroll="body"
            >
              <DialogTitle id="responsive-dialog-title">
                NOC-{applicationNumber}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={24}>
                  <Grid item xs={6}>
                    <Typography
                      variant="button"
                      style={{
                        marginTop: 10,
                        marginBottom: 0
                      }}
                    >
                      Upload Verification Image
                    </Typography>
                  </Grid>
                  {this.state.data.chosen_file ? (
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <Button color="primary">
                        <CheckCircle />
                      </Button>
                      <Button
                        component="span"
                        color="primary"
                        aria-label="upload"
                        onClick={e => {
                          e.preventDefault();
                          this.removeFile(e);
                        }}
                      >
                        <DeleteIcon />
                        Remove
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <input
                        accept="image/*"
                        id="upload-user-image-fab"
                        style={{ display: "none" }}
                        multiple
                        type="file"
                        onChange={e => {
                          e.preventDefault();
                          this.onFileLoad(e);
                        }}
                      />
                      <label htmlFor="upload-user-image-fab">
                        <Button
                          component="span"
                          color="primary"
                          aria-label="upload"
                        >
                          <AttachFile />
                          Attach
                        </Button>
                      </label>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControl error fullWidth>
                      <TextField
                        required
                        multiline
                        id="remarks"
                        name="remarks"
                        onChange={e => {
                          e.preventDefault();
                          this.handleChange(e);
                        }}
                        value={this.state.data.remarks}
                        label="Remarks"
                        fullWidth
                      />
                      {this.state.errors.remarks ? (
                        <FormHelperText>
                          {this.state.errors.remarks}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid className={classes.footerButtons} item xs={4} md={4}>
                    <div className={classes.cancelButton}>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          this.handleCancel();
                        }}
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Grid>
                  <Grid className={classes.footerButtons} item xs={8} md={8}>
                    <div className={classes.buttons}>
                      <Button
                        disabled={this.props.isLoading ? true : false}
                        variant="contained"
                        color="primary"
                        onClick={e => {
                          e.preventDefault();
                          this.onSubmitHandler();
                        }}
                        className={classes.button}
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              {this.props.isLoading ? <CircularIndeterminate /> : null}
            </Dialog>
          </div>
        </Grid>
      </Grid>
    );
  }
}

InboundRequestDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    isLoading: state.nocInboundRequestDialog.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inboundRequestAction: (id, data) =>
      dispatch(inboundRequestAction(id, data)),
    resetProps: () => dispatch(resetProps())
  };
}

export const styledInboundRequestDialog = withStyles(styles)(
  withMobileDialog()(InboundRequestDialog)
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledInboundRequestDialog);
