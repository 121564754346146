import requestHandler from "../../../../services/api/requestHandler";

import {
  CCTNS_API,
  CCTNS_ADDRESSES_API,
  CCTNS_FIR_SECTIONS_API,
  PASSPORT_API,
  CCTNS_COUNTRY_API,
  CCTNS_STATE_API,
  CCTNS_DISTRICT_API,
  CCTNS_STATION_API
} from "../../../../config/apiUrls";

import {
  RETRIEVE_CCTNS_RESULTS,
  FORWARD_CCTNS_RESULTS,
  RETRIEVE_PASSPORT_DETAILS,
  PASSPORT_ADDRESSES_DETAILS,
  PASSPORT_FIR_SECTIONS_DETAILS,
  RESET_CCTNS_RESULTS_PROPS,
  RETRIEVE_CCTNS_COUNTRY,
  RETRIEVE_CCTNS_STATE,
  RETRIEVE_CCTNS_DISTRICT,
  RETRIEVE_CCTNS_STATION
} from "./constants";

export function retrievePassportDetails(id) {
  return requestHandler(
    RETRIEVE_PASSPORT_DETAILS,
    `${PASSPORT_API}${id}/`,
    "GET",
    {}
  );
}

export function retrieveCountryData() {
  return requestHandler(RETRIEVE_CCTNS_COUNTRY, CCTNS_COUNTRY_API, "GET", {});
}

export function retrieveStateData(params) {
  return requestHandler(RETRIEVE_CCTNS_STATE, CCTNS_STATE_API, "GET", {
    params
  });
}
export function retrieveDistrictData(params) {
  return requestHandler(RETRIEVE_CCTNS_DISTRICT, CCTNS_DISTRICT_API, "GET", {
    params
  });
}

export function retrieveStationData(params) {
  return requestHandler(RETRIEVE_CCTNS_STATION, CCTNS_STATION_API, "GET", {
    params
  });
}

export function retrieveCCTNSResults(data) {
  return requestHandler(RETRIEVE_CCTNS_RESULTS, CCTNS_API, "POST", { data });
}

export function forwardCCTNSResults(id, data) {
  return requestHandler(FORWARD_CCTNS_RESULTS, `${PASSPORT_API}${id}/`, "PUT", {
    data
  });
}

export function retrieveAddresses(params) {
  return requestHandler(
    PASSPORT_ADDRESSES_DETAILS,
    CCTNS_ADDRESSES_API,
    "GET",
    { params }
  );
}

export function retrieveFIRSections(params) {
  return requestHandler(
    PASSPORT_FIR_SECTIONS_DETAILS,
    CCTNS_FIR_SECTIONS_API,
    "GET",
    { params }
  );
}

export function resetProps() {
  return {
    type: RESET_CCTNS_RESULTS_PROPS
  };
}
