import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { DatePicker } from "material-ui-pickers";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachFile from "@material-ui/icons/AttachFile";
import dropdownData from "../../constants";
import { Button } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import DetailsForm from "./DetailsForm";

function NocDocumentForm({
  onFileLoad,
  removeFile,
  insuranceFile,
  licenseFile,
  rcFile,
  pucFile,
  identityFile,
  addressFile,
  onChange,
  onDateChange,
  data,
  errors
}) {
  return (
    <>
      {data.nocType && data.nocType === "Vehicle" ? (
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography
              style={{ marginTop: 16, marginBottom: 0 }}
              variant="h6"
              gutterBottom
            >
              Documents
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="button"
              style={{ marginTop: 10, marginBottom: 0 }}
            >
              Certificate of Registration *
            </Typography>
          </Grid>
          {/* </Grid> */}

          {rcFile ? (
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <Button color="primary">
                <CheckCircle />
              </Button>
              <Button
                component="span"
                // variant="fab"
                color="primary"
                aria-label="upload"
                onClick={e => {
                  e.preventDefault();
                  removeFile(e, "RC");
                }}
              >
                <DeleteIcon />
                Remove
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <input
                accept="image/*"
                id="upload-user-image-fab"
                style={{ display: "none" }}
                multiple
                type="file"
                onChange={e => {
                  e.preventDefault();
                  onFileLoad(e, "RC");
                }}
              />
              <label htmlFor="upload-user-image-fab">
                <Button
                  component="span"
                  // variant="fab"
                  color="primary"
                  aria-label="upload"
                >
                  <AttachFile />
                  Attach
                </Button>
              </label>
            </Grid>
          )}

          <Grid item xs={6}>
            <Typography
              variant="button"
              style={{ marginTop: 10, marginBottom: 0 }}
            >
              Certificate of Insurance *
            </Typography>
          </Grid>
          {/* </Grid> */}

          {insuranceFile ? (
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <Button color="primary">
                <CheckCircle />
              </Button>
              <Button
                component="span"
                // variant="fab"
                color="primary"
                aria-label="upload"
                onClick={e => {
                  e.preventDefault();
                  removeFile(e, "INSURANCE");
                }}
              >
                <DeleteIcon />
                Remove
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <input
                accept="image/*"
                id="upload-user-image-fab"
                style={{ display: "none" }}
                multiple
                type="file"
                onChange={e => {
                  e.preventDefault();
                  onFileLoad(e, "INSURANCE");
                }}
              />
              <label htmlFor="upload-user-image-fab">
                <Button
                  component="span"
                  // variant="fab"
                  color="primary"
                  aria-label="upload"
                >
                  <AttachFile />
                  Attach
                </Button>
              </label>
            </Grid>
          )}

          <Grid item xs={6}>
            <Typography
              variant="button"
              style={{ marginTop: 10, marginBottom: 0 }}
            >
              Driving License of RC Owner *
            </Typography>
          </Grid>
          {/* </Grid> */}

          {licenseFile ? (
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <Button color="primary">
                <CheckCircle />
              </Button>
              <Button
                component="span"
                // variant="fab"
                color="primary"
                aria-label="upload"
                onClick={e => {
                  e.preventDefault();
                  removeFile(e, "LICENSE");
                }}
              >
                <DeleteIcon />
                Remove
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <input
                accept="image/*"
                id="upload-user-image-fab"
                style={{ display: "none" }}
                multiple
                type="file"
                onChange={e => {
                  e.preventDefault();
                  onFileLoad(e, "LICENSE");
                }}
              />
              <label htmlFor="upload-user-image-fab">
                <Button
                  component="span"
                  // variant="fab"
                  color="primary"
                  aria-label="upload"
                >
                  <AttachFile />
                  Attach
                </Button>
              </label>
            </Grid>
          )}

          <Grid item xs={6}>
            <Typography
              variant="button"
              style={{ marginTop: 10, marginBottom: 0 }}
            >
              PUC Certificate(Pollution Under Control) *
            </Typography>
          </Grid>
          {/* </Grid> */}

          {pucFile ? (
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <Button color="primary">
                <CheckCircle />
              </Button>
              <Button
                component="span"
                // variant="fab"
                color="primary"
                aria-label="upload"
                onClick={e => {
                  e.preventDefault();
                  removeFile(e, "PUC");
                }}
              >
                <DeleteIcon />
                Remove
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <input
                accept="image/*"
                id="upload-user-image-fab"
                style={{ display: "none" }}
                multiple
                type="file"
                onChange={e => {
                  e.preventDefault();
                  onFileLoad(e, "PUC");
                }}
              />
              <label htmlFor="upload-user-image-fab">
                <Button
                  component="span"
                  // variant="fab"
                  color="primary"
                  aria-label="upload"
                >
                  <AttachFile />
                  Attach
                </Button>
              </label>
            </Grid>
          )}

          <Grid item xs={4}>
            <Typography
              style={{ marginTop: 16, marginBottom: 0 }}
              variant="button"
            >
              Proof of Identity
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: 0 }}>
            <FormControl fullWidth error={errors.idProofType ? true : false}>
              <InputLabel htmlFor="id-proof-type">ID Proof Type</InputLabel>
              <Select
                inputProps={{
                  name: "idProofType",
                  id: "id-proof-type"
                }}
                value={data.idProofType ? data.idProofType : ""}
                onChange={onChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dropdownData.identificationTypes.map(item => (
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                ))}
              </Select>
              {errors.idProofType ? (
                <FormHelperText>{errors.idProofType}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          {identityFile ? (
            <Grid item xs={4} style={{ textAlign: "end" }}>
              <Button color="primary">
                <CheckCircle />
              </Button>
              <Button
                component="span"
                // variant="fab"
                color="primary"
                aria-label="upload"
                onClick={e => {
                  e.preventDefault();
                  removeFile(e, "IDENTITY");
                }}
              >
                <DeleteIcon />
                Remove
              </Button>
            </Grid>
          ) : (
            <Grid item xs={4} style={{ textAlign: "end" }}>
              <input
                accept="image/*"
                id="upload-identity-fab"
                style={{ display: "none" }}
                multiple
                type="file"
                onChange={e => {
                  e.preventDefault();
                  onFileLoad(e, "IDENTITY");
                }}
              />
              <label htmlFor="upload-identity-fab">
                <Button
                  component="span"
                  // variant="fab"
                  color="primary"
                  aria-label="upload"
                >
                  <AttachFile />
                  Attach
                </Button>
              </label>
            </Grid>
          )}
          <Grid item xs={4}>
            <Typography
              style={{ marginTop: 16, marginBottom: 0 }}
              variant="button"
            >
              Proof of Address
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: 0 }}>
            <FormControl
              fullWidth
              error={errors.addressProofType ? true : false}
            >
              <InputLabel htmlFor="address-proof-type">
                Address Proof Type
              </InputLabel>
              <Select
                inputProps={{
                  name: "addressProofType",
                  id: "address-proof-type"
                }}
                value={data.addressProofType ? data.addressProofType : ""}
                onChange={onChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dropdownData.addressProofTypes.map(item => (
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                ))}
              </Select>
              {errors.addressProofType ? (
                <FormHelperText>{errors.addressProofType}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {addressFile ? (
            <Grid item xs={4} style={{ textAlign: "end" }}>
              <Button color="primary">
                <CheckCircle />
              </Button>
              <Button
                component="span"
                // variant="fab"
                color="primary"
                aria-label="upload"
                onClick={e => {
                  e.preventDefault();
                  removeFile(e, "ADDRESS");
                }}
              >
                <DeleteIcon />
                Remove
              </Button>
            </Grid>
          ) : (
            <Grid item xs={4} style={{ textAlign: "end" }}>
              <input
                accept="image/*"
                id="upload-address-fab"
                style={{ display: "none" }}
                multiple
                type="file"
                onChange={e => {
                  e.preventDefault();
                  onFileLoad(e, "ADDRESS");
                }}
              />
              <label htmlFor="upload-address-fab">
                <Button
                  component="span"
                  // variant="fab"
                  color="primary"
                  aria-label="upload"
                >
                  <AttachFile />
                  Attach
                </Button>
              </label>
            </Grid>
          )}
        </Grid>
      ) : null}
    </>
  );
}

export default NocDocumentForm;
