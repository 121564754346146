import { handle } from "redux-pack";

import { MASTER_FVO_LIST, MASTER_STATION_LIST } from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  stationListData: [],
  fvoListData: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MASTER_FVO_LIST:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: false,
            error: null,
            fvoListData: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => ({
          ...prevState,
          fvoListData: payload.data
        })
      });
    case MASTER_STATION_LIST:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: false,
            error: null,
            stationListData: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => ({
          ...prevState,
          stationListData: payload.data
        })
      });
    default:
      return state;
  }
};
