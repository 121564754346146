export const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  criminalDetailsGrid: {
    padding: theme.spacing.unit * 1.5
    // marginBottom: theme.spacing.unit * 2
  },
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  paperStyle: {
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2
  },
  gridStyle: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1
  },
  questionnaireGridStyle: {
    marginBottom: theme.spacing.unit * 6,
    marginTop: theme.spacing.unit * 6,
    marginLeft: theme.spacing.unit * 24,
    marginRight: theme.spacing.unit * 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing.unit * 1,
      marginTop: theme.spacing.unit * 1,
      marginLeft: theme.spacing.unit * 1,
      marginRight: theme.spacing.unit * 1
    }
  },
  radioGroupStyle: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  radioControlStyle: {
    marginTop: theme.spacing.unit * 1
  }
});
