import requestHandler from "../../../services/api/requestHandler";

import { CERTIFICATE_VERIFICATION_API } from "../../../config/apiUrls.js";

import { CERTIFICATE_VERIFICATION, RESET_PROP } from "./constants";


export function submitHashCode(data) {
  return requestHandler(
    CERTIFICATE_VERIFICATION,
    CERTIFICATE_VERIFICATION_API,
    "POST",
    { data }
  );
}

export function resetProps() {
  return { type: RESET_PROP };
}