import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { setHeader, setBackURL } from "../../components/appBar/data/actions";
import { styles } from "./styles";
import { exportData, resetProps } from "./data/actions";
import { showAlert } from "../../components/snackbar/data/actions";
import validator from "../../services/validator/";
import { find } from "lodash";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularIndeterminate from "../../components/circular/CircularIndeterminate";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import SheetIcon from "@material-ui/icons/LibraryBooks";
import appUrls from "../../config/appUrls";
import getUsertype from "../../services/getUsertype";
import Fab from "@material-ui/core/Fab";
import { push } from "connected-react-router";

class PassportDataExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fileExcel: null,
        fileZip: null
      }
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeZip = this.onChangeZip.bind(this);
  }

  onChange(e) {
    this.setState({
      data: { ...this.state.data, fileExcel: e.target.files[0] }
    });
  }
  onChangeZip(e) {
    this.setState({
      data: { ...this.state.data, fileZip: e.target.files[0] }
    });
  }

  componentDidMount() {
    this.props.resetProps();
    if (getUsertype(this.props.userInfo.user_type) === "DSBO") {
      this.props.setHeader({ header: "Export Data" });
    } else if (getUsertype(this.props.userInfo.user_type) === "CUSTOMER") {
      this.props.navigateTo(appUrls.TRACKING_USER);
    } else {
      this.props.navigateTo("/not-found");
    }
  }

  handleNext = () => {
    if (this.state.data.fileZip && this.state.data.fileExcel) {
      if (this.validateFile()) {
        const data = new FormData();
        const regex = new RegExp("^(zip)");

        data.append("excel_file", this.state.data.fileExcel);
        data.append("pdf_file", this.state.data.fileZip);

        this.props.exportData(data);
      }
    } else {
      if (
        this.state.data &&
        !this.state.data.fileExcel &&
        this.state.data.fileZip
      ) {
        let messageInfo = {
          message: "Please attach Excel file!",
          variant: "warning"
        };
        this.props.showAlert(messageInfo);
      } else if (
        this.state.data &&
        !this.state.data.fileZip &&
        this.state.data.fileExcel
      ) {
        let messageInfo = {
          message: "Please attach Zip file!",
          variant: "warning"
        };
        this.props.showAlert(messageInfo);
      } else {
        let messageInfo = {
          message: "Please attach Zip and Excel file!",
          variant: "warning"
        };
        this.props.showAlert(messageInfo);
      }
    }
  };

  validateFile() {
    if (this.state.data.fileZip === "") {
      let messageInfo = {
        message: "Please select a valid file!",
        variant: "warning"
      };
      this.props.showAlert(messageInfo);
      return false;
    }

    const { fileZip: file } = this.state.data.fileZip;

    const regex = new RegExp("^(zip)");
    if (!file) return true;
    if (regex.test(file.type)) {
      return true;
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          chosen_file_address_name: "Invalid file type"
        }
      });
      return false;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.verificationResponse) {
      let messageInfo = {
        message: this.props.verificationResponse.message,
        variant: "success"
      };
      this.props.showAlert(messageInfo);
      this.props.navigateTo(appUrls.PASSPORT_REQUESTS);
    }

    if (this.props.error && this.props.error !== prevProps.error) {
      let messageInfo = { message: this.props.error, variant: "error" };
      this.props.showAlert(messageInfo);
    }
  }

  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, error => error !== "");

    this.setState({ errors });
    return !hasErrors;
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.root}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={12}>
                <Grid item xs={12}>
                  <div className={classes.headingDiv}>
                    <PDFIcon color="primary" className={classes.headingIcon} />
                    <Typography
                      variant="h5"
                      color="primary"
                      component="h2"
                      className={classes.headingText}
                    >
                      Zip File Upload
                    </Typography>
                  </div>
                  <input
                    className={classes.customFileUpload}
                    type="file"
                    onChange={this.onChangeZip}
                    accept="application/zip,application/x-zip,application/x-zip-compressed,application/octet-stream"
                  />
                </Grid>
                <Grid item xs={12} className={classes.heading}>
                  <div className={classes.headingDiv}>
                    <SheetIcon
                      color="primary"
                      className={classes.headingIcon}
                    />
                    <Typography
                      variant="h5"
                      component="h2"
                      color="primary"
                      className={classes.headingText}
                    >
                      Excel File Upload
                    </Typography>
                  </div>
                  <input
                    className={classes.customFileUpload}
                    type="file"
                    onChange={this.onChange}
                    accept=".xls,.xlsx"
                  />
                </Grid>
                <Grid item xs={12} className={classes.buttonfieldGrid}>
                  <Fab
                    disabled={this.props.isLoading ? true : false}
                    type="submit"
                    variant="extended"
                    color="primary"
                    className={classes.button}
                    onClick={e => {
                      e.preventDefault();
                      this.handleNext();
                    }}
                  >
                    Upload
                    <CloudUploadIcon className={classes.buttonIcon} />
                  </Fab>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        {this.props.isLoading ? <CircularIndeterminate /> : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    isLoading: state.passportDataExport.isLoading,
    error: state.passportDataExport.error,
    verificationResponse: state.passportDataExport.verificationResponse
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    setHeader: data => dispatch(setHeader(data)),
    setBackURL: data => dispatch(setBackURL(data)),
    exportData: data => dispatch(exportData(data)),
    resetProps: () => dispatch(resetProps()),
    navigateTo: url => dispatch(push(url))
  };
}

export const styledPassportDataExport = withStyles(styles)(PassportDataExport);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledPassportDataExport);
