import { handle } from "redux-pack";
import {
  PCC_CERTIFICATE_VIEW,
  NOC_CERTIFICATE_VIEW,
  PCC_HASHCODE_VIEW,
  NOC_HASHCODE_VIEW,
  PCC_USER_TRACKING_LOG,
  NOC_USER_TRACKING_LOG,
  PCC_USER_TRACKING_LIST,
  NOC_USER_TRACKING_LIST,
  RESET_USER_TRACKING_LOG,
  RESET_USER_TRACKING_VIEW_HASH,
  RESET_USER_TRACKING_PROPS
} from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  message: "",
  historyList: [],
  historyData: [],
  viewPCCHashCodeSuccess: false,
  viewNOCHashCodeSuccess: false,
  pccCertificateHash: "",
  nocCertificateHash: "",
  pccCertificateData: "",
  nocCertificateData: "",
  totalCount: 0
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PCC_USER_TRACKING_LIST:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null,
            historyData: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            historyList: payload.data.results,
            totalCount: payload.data.total_count
          };
        }
      });
    case NOC_USER_TRACKING_LIST:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null,
            historyData: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            historyList: payload.data.results,
            totalCount: payload.data.total_count
          };
        }
      });
    case PCC_USER_TRACKING_LOG:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            historyData: payload.data
          };
        }
      });
    case NOC_USER_TRACKING_LOG:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            historyData: payload.data
          };
        }
      });
    case PCC_CERTIFICATE_VIEW:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          window.open(payload.data.pdf_url);
          return {
            ...prevState,
            pccCertificateData: payload.data
          };
        }
      });
    case NOC_CERTIFICATE_VIEW:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          window.open(payload.data.pdf_url);
          return {
            ...prevState,
            nocCertificateData: payload.data
          };
        }
      });
    case PCC_HASHCODE_VIEW:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            viewPCCHashCodeSuccess: true,
            pccCertificateHash: payload.data.certificate_hash
          };
        }
      });
    case NOC_HASHCODE_VIEW:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            viewNOCHashCodeSuccess: true,
            nocCertificateHash: payload.data.certificate_hash
          };
        }
      });
    case RESET_USER_TRACKING_LOG:
      return {
        ...state,
        historyData: []
      };
    case RESET_USER_TRACKING_VIEW_HASH:
      return {
        ...state,
        viewPCCHashCodeSuccess: false,
        pccCertificateHash: "",
        viewNOCHashCodeSuccess: false,
        nocCertificateHash: ""
      };
    case RESET_USER_TRACKING_PROPS:
      return {
        isLoading: false,
        error: null,
        message: "",
        historyList: [],
        historyData: [],
        viewPCCHashCodeSuccess: false,
        viewNOCHashCodeSuccess: false,
        pccCertificateHash: "",
        nocCertificateHash: "",
        pccCertificateData: "",
        nocCertificateData: "",
        totalCount: 0
      };
    default:
      return state;
  }
};
