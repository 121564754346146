import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import { styles } from "./styles";
import EmptyListing from "../../../components/emptyListing";
import DateRange from "@material-ui/icons/DateRange";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActionsWrapped } from "../../../components/pagination/";

class InboundRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
      id: null,
      page: 0,
      rowsPerPage: 10
    };
  }
  componentDidMount() {
    let params = {
      in_bound: true,
      out_bound: false,
      per_page: this.state.rowsPerPage,
      page_no: this.state.page + 1
    };
    this.props.listPCCInboundRequests(params);
  }

  componentDidUpdate(prevProps) {}

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      let params = {
        in_bound: true,
        out_bound: false,
        per_page: this.state.rowsPerPage,
        page_no: this.state.page + 1
      };
      this.props.listPCCInboundRequests(params);
    });
  };

  inboundActionHandler = (id, applicationNumber, isApprove) => {
    this.props.inboundActionHandler(id, applicationNumber, isApprove);
  };

  onApplicationDetailsHandler = (id, applicationNumber) => {
    this.props.onApplicationDetailsHandler(id, applicationNumber);
  };

  render() {
    const { classes } = this.props;
    const { expanded, page, rowsPerPage } = this.state;
    return (
      <Grid
        className={
          this.props.userInfo.user_type === 1
            ? classes.rootWithTabs
            : classes.root
        }
      >
        {this.props.inboundListData &&
        this.props.inboundListData.length !== 0 ? (
          <div className={classes.tablePagination}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={3}
              count={this.props.totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              ActionsComponent={TablePaginationActionsWrapped}
            />
          </div>
        ) : null}
        {this.props.inboundListData &&
        this.props.inboundListData.length !== 0 ? (
          this.props.inboundListData.map((item, i) => {
            return (
              <ExpansionPanel
                expanded={expanded === "panel" + i}
                onChange={this.handleChange("panel" + i)}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid xs={12} container>
                    <Grid item className={classes.headingGrid}>
                      <Typography className={classes.secondaryHeading}>
                        PCC-{item.application_number}
                      </Typography>
                      <Tooltip
                        title="Date of Application"
                        placement="left-start"
                      >
                        <Typography
                          color="textSecondary"
                          className={classes.dateSummary}
                          gutterBottom
                        >
                          <DateRange color="primary" />
                          <span className={classes.iconTextStyle}>
                            {item.applied_on}
                          </span>
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid xs={12} container>
                    <Grid item md={6} smDown={12}>
                      <Typography className={classes.secondaryHeading}>
                        Name of Applicant: {item.name_of_applicant}
                      </Typography>
                    </Grid>
                    <Grid item md={6} smDown={12}>
                      <Typography className={classes.secondaryHeading}>
                        PCC Type: {item.purpose}
                      </Typography>
                    </Grid>
                    <Grid xs={12} container>
                      <Grid item xs={12}>
                        <Typography className={classes.secondaryHeading}>
                          Reason: {item.reason_for_applying}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions
                  style={{ justifyContent: "space-between" }}
                >
                  <div>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.onApplicationDetailsHandler(
                          item.id,
                          item.application_number
                        );
                      }}
                    >
                      View Application
                    </Button>
                  </div>
                  <div>
                    {/* {this.props.userInfo.user_type === 1 ||
                    this.props.userInfo.user_type === 5 ||
                    this.props.userInfo.user_type === 6 ? ( */}
                    <Button
                      color="secondary"
                      onClick={() =>
                        this.inboundActionHandler(
                          item.id,
                          item.application_number,
                          false
                        )
                      }
                    >
                      Reject
                    </Button>
                    {/* ) : (
                      ""
                    )} */}
                    <Button
                      color="primary"
                      onClick={() =>
                        this.inboundActionHandler(
                          item.id,
                          item.application_number,
                          true
                        )
                      }
                    >
                      {/* {this.props.userInfo.user_type === 1 ||
                      this.props.userInfo.user_type === 5 ||
                      this.props.userInfo.user_type === 6
                        ? "Approve"
                        : ""} */}
                      Approve
                    </Button>
                  </div>
                </ExpansionPanelActions>
              </ExpansionPanel>
            );
          })
        ) : (
          <EmptyListing emptyText="No pending requests" />
        )}
      </Grid>
    );
  }
}

InboundRequests.propTypes = {
  classes: PropTypes.object.isRequired
};

export const styledInboundRequests = withStyles(styles)(InboundRequests);

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    inboundListData: state.managePCCRequests.inboundListData,
    totalCount: state.managePCCRequests.totalCount
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledInboundRequests);
