import requestHandler from "../../../../services/api/requestHandler";

import { RETRIEVE_PCC_API } from "../../../../config/apiUrls.js";

import { APPLICATION_DETAILS } from "./constants";

export function retrievePCCApplication(id) {
  return requestHandler(
    APPLICATION_DETAILS,
    `${RETRIEVE_PCC_API}${id}/`,
    "GET",
    {}
  );
}
