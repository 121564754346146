import {
  PCC_INBOUND_REQUEST_ACTION,
  PCC_INBOUND_RESET_REQUEST
} from "./constants";
import requestHandler from "../../../../services/api/requestHandler";
import { PCC_API } from "../../../../config/apiUrls.js";

export function inboundRequestAction(id, data) {
  return requestHandler(PCC_INBOUND_REQUEST_ACTION, `${PCC_API}${id}/`, "PUT", {
    data
  });
}

export function resetProps() {
  return { type: PCC_INBOUND_RESET_REQUEST };
}
