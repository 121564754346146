import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { connect } from "react-redux";
import { styles } from "./styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { showAlert } from "../../../components/snackbar/data/actions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularIndeterminate from "../../../components/circular/CircularIndeterminate";
import EmptyListing from "../../../components/emptyListing";
import Paper from "@material-ui/core/Paper";
import {
  retrievePassportQuestionnaire,
  submitPassportQuestionnaire,
  resetPassportQuestionnaire
} from "./data/action";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class QuestionnaireDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        retrievePassportQuestionnaireData: []
      }
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
      let params = {
        passport_id: this.props.appliationId
      };
      this.props.retrievePassportQuestionnaire(params);
    }
    if (
      this.props.retrievePassportQuestionnaireData &&
      this.props.retrievePassportQuestionnaireData !==
        prevProps.retrievePassportQuestionnaireData
    ) {
      this.setState({
        data: {
          ...this.state.data,
          retrievePassportQuestionnaireData: this.props
            .retrievePassportQuestionnaireData
        }
      });
    }
    if (this.props.error !== prevProps.error) {
      let messageInfo = { message: this.props.error, variant: "error" };
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.submitSuccess &&
      this.props.submitSuccess !== prevProps.submitSuccess
    ) {
      this.handleCancel();
      let messageInfo = {
        message: this.props.message + " " + this.props.transactionHash,
        variant: "success"
      };
      this.props.showAlert(messageInfo);
    }
  }

  handleChange = (event, i) => {
    if (event.target.value.length >= 10000) return;
    const retrievePassportQuestionnaireData = [
      ...this.state.data.retrievePassportQuestionnaireData
    ];
    retrievePassportQuestionnaireData[i] = {
      ...retrievePassportQuestionnaireData[i],
      [event.target.name]: event.target.value
    };
    this.setState({
      data: {
        ...this.state.data,
        retrievePassportQuestionnaireData
      }
    });
  };

  handleCancel = () => {
    this.props.resetProps();
    this.props.handleQuestionnaireDialogClose();
  };

  submitQuestionnaireHandler = () => {
    let fvo_review =
      this.state.data.retrievePassportQuestionnaireData &&
      this.state.data.retrievePassportQuestionnaireData.map((item, i) => {
        return {
          question: item.id,
          answer: item.answer,
          remarks: item.remarks
        };
      });
    let data = {
      passport_id: this.props.appliationId,
      fvo_review: fvo_review
    };
    this.props.submitPassportQuestionnaire(data);
  };

  render() {
    const { classes, retrievePassportQuestionnaireData } = this.props;
    const { data } = this.state;

    return (
      <>
        <Dialog
          fullScreen
          open={this.props.isOpen}
          onClose={this.handleCancel}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Questionnaire
              </Typography>
              <Button color="inherit" onClick={this.submitQuestionnaireHandler}>
                Done
              </Button>
            </Toolbar>
          </AppBar>
          {retrievePassportQuestionnaireData &&
          retrievePassportQuestionnaireData.length !== 0 ? (
            <Grid item xs={12} className={classes.questionnaireGridStyle}>
              {retrievePassportQuestionnaireData.map((item, i) => {
                return (
                  <Paper className={classes.paperStyle}>
                    <Grid container>
                      <Grid className={classes.gridStyle} item md={12} xs={12}>
                        <Typography variant="h6">
                          {"Q" + (i + 1) + ": " + item.question}
                        </Typography>
                      </Grid>
                    </Grid>
                    <FormControl
                      component="fieldset"
                      className={classes.radioControlStyle}
                    >
                      <FormLabel component="legend">Answer</FormLabel>
                      <RadioGroup
                        aria-label="answer"
                        name="answer"
                        value={
                          data.retrievePassportQuestionnaireData[i] &&
                          data.retrievePassportQuestionnaireData[i].answer ===
                            "true"
                            ? "true"
                            : "false"
                        }
                        className={classes.radioGroupStyle}
                        onChange={e => {
                          e.preventDefault();
                          this.handleChange(e, i);
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Grid container>
                      <Grid className={classes.gridStyle} item md={12} xs={12}>
                        <FormControl error fullWidth>
                          <TextField
                            id="outlined-multiline-description"
                            name="remarks"
                            label="Remarks"
                            multiline
                            rows="3"
                            variant="outlined"
                            value={
                              data.retrievePassportQuestionnaireData[i] &&
                              data.retrievePassportQuestionnaireData[i].remarks
                                ? data.retrievePassportQuestionnaireData[i]
                                    .remarks
                                : ""
                            }
                            onChange={e => {
                              e.preventDefault();
                              this.handleChange(e, i);
                            }}
                            style={{ backgroundColor: "#F5F5F5" }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
            </Grid>
          ) : (
            <EmptyListing emptyText="Questionnaire will appear here" />
          )}
          {this.props.isLoading ? <CircularIndeterminate /> : null}
        </Dialog>
      </>
    );
  }
}

QuestionnaireDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    isLoading: state.passportQuestionnaire.isLoading,
    error: state.passportQuestionnaire.error,
    retrievePassportQuestionnaireData:
      state.passportQuestionnaire.retrievePassportQuestionnaireData,
    submitSuccess: state.passportQuestionnaire.submitSuccess,
    message: state.passportQuestionnaire.message,
    transactionHash: state.passportQuestionnaire.transactionHash
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    retrievePassportQuestionnaire: params =>
      dispatch(retrievePassportQuestionnaire(params)),
    submitPassportQuestionnaire: data =>
      dispatch(submitPassportQuestionnaire(data)),
    resetProps: () => dispatch(resetPassportQuestionnaire())
  };
}

export const styledQuestionnaireDialog = withStyles(styles)(
  withMobileDialog()(QuestionnaireDialog)
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledQuestionnaireDialog);
