export const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    // marginTop: theme.spacing.unit * 1
    marginLeft: theme.spacing.unit * 1.5
  },
  iconTextStyle: {
    position: "relative",
    bottom: 7,
    left: 10
  },
  table: {
    width: "100%"
  },
  groupNameCell: {
    width: "60%"
  },
  groupActionCell: {
    width: "40%"
  },
  cellgrid: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    paddingBottom: theme.spacing.unit * 2
  },
  root: {
    width: "100%",
    padding: "4px",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 1
    },
    overflowX: "auto"
  },
  nameHeader: {
    width: "60%"
  },
  actionHeader: {
    width: "40%",
    textAlign: "center"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  deleteButton: {
    float: "right"
  },
  textContentArea: {
    paddingLeft: theme.spacing.unit
  },
  statusSummary: {
    marginTop: 8,
    position: "absolute",
    right: 50
  },
  data: {
    overflowWrap: "break-word"
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  dropDown: {
    marginTop: "5px"
  },
  menu: {
    width: 200
  },
  headingGrid: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  buttonsGrid: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  dateSummary: {
    marginTop: 8,
    position: "relative",
    marginRight: 8
  },
  tablePagination: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  detailsInnerGridStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  avatarStyle: {
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10,
    color: "#fff",
    backgroundColor: theme.palette.secondary.light
  },
  divStyle: {
    display: "flex",
    alignItems: "center"
  }
});
