export const styles = theme => ({
  root: {
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: 12,
      marginRight: 12
    }
  },
  hide: {
    display: "none"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: "#fff"
  },
  appBarShift: {
    width: `calc(100% - 240px)`,
    marginLeft: 240,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  tabStyle: {
    color: theme.palette.common.white
  },
  icon: {
    color: theme.palette.common.white
  },
  disabledIcon: {
    color: theme.palette.action.disabled
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  poweredByStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  poweredByLogoStyle: {
    width: theme.spacing.unit * 9,
    marginTop: -theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing.unit * 2
    }
  },
  poweredByTextStyle: {
    marginRight: "16px"
  },
  headingGrid: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headingTextStyle: {
    paddingLeft: theme.spacing.unit * 2
  }
});
