export const styles = theme => ({
  tabStyle: {
    color: theme.palette.secondary.main
  },
  indicator: {
    backgroundColor: theme.palette.primary.main
  },
  icon: {
    margin: theme.spacing.unit
  },
  activeTab: {
    color: theme.palette.primary.main
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`
  }
});
