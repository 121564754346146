export const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100vw"
  },
  textStyle: {
    color: theme.palette.grey.A200
  }
});
