import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { setHeader } from "../../components/appBar/data/actions";
import { styles } from "./styles";
import Grid from "@material-ui/core/Grid";
import { NotFound } from "../../assets/svg/notFound";

class GenericNotFound extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.setHeader({ header: "Page Not Found" });
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid className={classes.root}>
          <NotFound />
        </Grid>
      </>
    );
  }
}

GenericNotFound.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setHeader: data => dispatch(setHeader(data))
  };
}

export const styledGenericNotFound = withStyles(styles)(GenericNotFound);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledGenericNotFound);
