import requestHandler from "../../../services/api/requestHandler";

import {
  PCC_HISTORY_API,
  NOC_HISTORY_API,
  PASSPORT_HISTORY_API,
  PV_REPORT_VIEW_API,
  PCC_LOG_API,
  NOC_LOG_API,
  PASSPORT_LOG_API
} from "../../../config/apiUrls.js";

import {
  PCC_HISTORY_LIST,
  NOC_HISTORY_LIST,
  PASSPORT_HISTORY_LIST,
  PCC_HISTORY_LOG,
  NOC_HISTORY_LOG,
  PASSPORT_HISTORY_LOG,
  PV_REPORT_VIEW,
  RESET_HISTORY_LOG,
  RESET_HISTORY_PROPS
} from "./constants";

export function getNOCHistory(params) {
  return requestHandler(NOC_HISTORY_LIST, NOC_HISTORY_API, "GET", {
    params
  });
}

export function getPCCHistory(params) {
  return requestHandler(PCC_HISTORY_LIST, PCC_HISTORY_API, "GET", {
    params
  });
}

export function getPassportRequestList(params) {
  return requestHandler(PASSPORT_HISTORY_LIST, PASSPORT_HISTORY_API, "GET", {
    params
  });
}

export function getPCCLog(id) {
  return requestHandler(PCC_HISTORY_LOG, `${PCC_LOG_API}${id}/`, "GET", {});
}

export function getNOCLog(id) {
  return requestHandler(NOC_HISTORY_LOG, `${NOC_LOG_API}${id}/`, "GET", {});
}

export function getPassportLog(id) {
  return requestHandler(
    PASSPORT_HISTORY_LOG,
    `${PASSPORT_LOG_API}${id}/`,
    "GET",
    {}
  );
}

export function getPVReport(id) {
  return requestHandler(
    PV_REPORT_VIEW,
    `${PV_REPORT_VIEW_API}${id}/`,
    "GET",
    {}
  );
}

export function resetLog() {
  return { type: RESET_HISTORY_LOG };
}

export function resetProps() {
  return { type: RESET_HISTORY_PROPS };
}
