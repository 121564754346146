import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import { styles } from "./styles";
import EmptyListing from "../../../components/emptyListing";
import DateRange from "@material-ui/icons/DateRange";
import jsPDF from "jspdf";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActionsWrapped } from "../../../components/pagination/";
import Avatar from "@material-ui/core/Avatar";
import { PersonOutline, FolderOutlined } from "@material-ui/icons";
import Hidden from "@material-ui/core/Hidden";
import getPDF from "../../../data/staticApi/showPDF";
import SearchIcon from "@material-ui/icons/Search";
import Renew from "@material-ui/icons/Autorenew";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

class InboundRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
      id: null,
      page: 0,
      rowsPerPage: 10,
      searchText: ""
    };
  }

  componentDidMount() {
    let params = {
      outbound: false,
      per_page: this.state.rowsPerPage,
      page_no: this.state.page + 1
    };
    this.props.listPassportInboundRequests(params);
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      let params = {
        outbound: false,
        per_page: this.state.rowsPerPage,
        page_no: this.state.page + 1
      };
      this.props.listPassportInboundRequests(params);
    });
  };

  onViewHashCodeClick = certificateHash => {
    var doc = new jsPDF({
      orientation: "landscape"
    });
    doc.text("Hash Code\n" + certificateHash, 10, 10);
    doc.setProperties({
      title: "HashCode"
    });
    window.open(doc.output("bloburl"));
  };

  onApplicationDetailsHandler = (id, applicationNumber) => {
    this.props.onApplicationDetailsHandler(id, applicationNumber);
  };

  handleSearchChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onSearchClickHandler = () => {
    this.setState(
      {
        ...this.state,
        page: 0
      },
      () => {
        let params = {
          outbound: false,
          per_page: this.state.rowsPerPage,
          page_no: this.state.page + 1,
          search_field: this.state.searchText
        };
        this.props.listPassportInboundRequests(params);
      }
    );
  };

  keyDown = e => {
    if (e.keyCode === 13) {
      this.onSearchClickHandler();
    }
  };

  onRenewClickHandler = () => {
    this.setState(
      {
        ...this.state,
        searchText: "",
        page: 0
      },
      () => {
        let params = {
          outbound: false,
          per_page: this.state.rowsPerPage,
          page_no: this.state.page + 1
        };
        this.props.listPassportInboundRequests(params);
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { expanded, page, rowsPerPage } = this.state;
    return (
      <Grid
        className={
          this.props.userInfo.user_type === 2
            ? classes.rootWithTabs
            : classes.root
        }
      >
        {this.props.inboundListData &&
        this.props.inboundListData.length === 0 &&
        !this.props.isLoading ? (
          <EmptyListing emptyText="No pending requests" />
        ) : null}
        <Grid container>
          <Grid item md={6} className={classes.searchGrid}>
            <div className={classes.search}>
              <div className={classes.searchDiv}>
                <IconButton
                  className={classes.searchIcon}
                  aria-label="search"
                  onClick={e => {
                    e.preventDefault();
                    this.onSearchClickHandler();
                  }}
                >
                  <SearchIcon />
                </IconButton>
                <TextField
                  placeholder="Search Applications"
                  className={classes.inputRoot}
                  name="searchText"
                  value={this.state.searchText}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.keyDown}
                  InputProps={{
                    "aria-label": "search",
                    disableUnderline: true
                  }}
                />
              </div>
              <IconButton
                className={classes.searchIcon}
                aria-label="search"
                onClick={e => {
                  e.preventDefault();
                  this.onRenewClickHandler();
                }}
              >
                <Renew />
              </IconButton>
            </div>
          </Grid>
          <Grid md={6}>
            <div className={classes.tablePagination}>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                component="div"
                count={this.props.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </div>
          </Grid>
        </Grid>
        {this.props.inboundListData && this.props.inboundListData.length !== 0
          ? this.props.inboundListData.map((item, i) => {
              return (
                <ExpansionPanel
                  expanded={expanded === "panel" + i}
                  onChange={this.handleChange("panel" + i)}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid xs={12} container>
                      <Grid item className={classes.headingGrid}>
                        <div className={classes.divStyle}>
                          <Avatar className={classes.avatarStyle}>
                            <FolderOutlined />
                          </Avatar>
                          <Grid>
                            <Typography
                              variant="button"
                              className={classes.secondaryHeading}
                            >
                              {item.request_id}
                            </Typography>
                            {this.props.userInfo.user_type !== 1 ? (
                              <Typography className={classes.statusTextStyle}>
                                {item.status_text}
                              </Typography>
                            ) : null}
                          </Grid>
                        </div>
                        <Hidden smDown>
                          <Tooltip
                            title="PV Initiation Date"
                            placement="left-start"
                          >
                            <Typography
                              className={classes.dateSummary}
                              gutterBottom
                            >
                              <DateRange color="primary" />
                              <span className={classes.iconTextStyle}>
                                {item.initiation_date}
                              </span>
                            </Typography>
                          </Tooltip>
                        </Hidden>
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid xs={12} container>
                      <Grid item xs={12} className={classes.nameGridStyle}>
                        <PersonOutline color="action" />
                        <Typography className={classes.secondaryHeading}>
                          {item.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                  <Divider />
                  <ExpansionPanelActions
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <Button
                        color="primary"
                        onClick={() => {
                          this.onApplicationDetailsHandler(
                            item.id,
                            item.application_number
                          );
                        }}
                      >
                        View Application
                      </Button>
                      {item.certifcate_hash ? (
                        <Button
                          color="primary"
                          onClick={() =>
                            this.onViewHashCodeClick(item.certifcate_hash)
                          }
                        >
                          View Hash Code
                        </Button>
                      ) : null}
                    </div>
                    <div>
                      <Button
                        color="primary"
                        onClick={() => getPDF(item.pdf_url)}
                      >
                        View PDF
                      </Button>

                      {item.certifcate_url ? (
                        <Button
                          color="primary"
                          onClick={() => getPDF(item.certifcate_url)}
                        >
                          Verification Report
                        </Button>
                      ) : null}
                    </div>
                  </ExpansionPanelActions>
                </ExpansionPanel>
              );
            })
          : null}
      </Grid>
    );
  }
}

InboundRequests.propTypes = {
  classes: PropTypes.object.isRequired
};

export const styledInboundRequests = withStyles(styles)(InboundRequests);

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    inboundListData: state.managePassportRequests.inboundListData,
    totalCount: state.managePassportRequests.totalCount,
    isLoading: state.managePassportRequests.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledInboundRequests);
