export const styles = theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
      padding: theme.spacing.unit * 4
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit * 4
    },
    overflowX: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100vw"
  }
});
