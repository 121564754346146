export const styles = theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "4px",
      marginTop: theme.spacing.unit * 8,
      marginBottom: theme.spacing.unit * 4
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "4px",
      marginTop: theme.spacing.unit * 1
    },
    overflowX: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    marginTop: theme.spacing.unit * 6,
    flex: "auto",
    width: "70%"
  },
  buttonfieldGrid: {
    textAlign: "center"
  },
  heading: {
    marginTop: theme.spacing.unit * 4,
    flex: "auto"
    // marginBottom: theme.spacing.unit * 3
  },
  customFileUpload: {
    border: "1px solid #ccc",
    display: "inline-block",
    padding: "12px",
    cursor: "pointer",
    width: "100%",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    userSelect: "none",
    borderRadius: "2px",
    backgroundColor: "#FFF"
  },
  card: {
    padding: theme.spacing.unit * 4,
    [theme.breakpoints.up("md")]: {
      width: "480px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    backgroundColor: "#FAFAFA"
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit * 1.5
  },
  headingDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 2
  },
  headingText: {
    marginLeft: theme.spacing.unit * 2
  },
  headingIcon: {
    height: "32px",
    width: "32px"
  }
});
