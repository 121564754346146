const cpPermissions = [
  // "Home",
  // "PCC",
  // "NOC",
  "Passport",
  "History",
  "Reports",
  "Logout"
];

const shoPermissions = [
  // "Home",
  // "PCC",
  // "NOC",
  "Passport",
  "Passport Upload",
  "Logout"
];

const cpoPermissions = [
  // "Home",
  // "PCC",
  // "NOC",
  "Passport",
  "Logout"
];

const fvoPermissions = [
  // "Home",
  // "PCC",
  "Passport",
  "Logout"
];

const acpPermissions = [
  // "Home",
  // "PCC",
  "Logout"
];

const dcpPermissions = [
  // "Home",
  // "PCC",
  "Logout"
];

const userPermissions = [
  // "Home",
  "Track Application",
  "Apply PCC",
  "Apply NOC",
  "Logout"
];

export default {
  sho: shoPermissions,
  cpo: cpoPermissions,
  fvo: fvoPermissions,
  cp: cpPermissions,
  acp: acpPermissions,
  dcp: dcpPermissions,
  user: userPermissions
};
