export const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
});
