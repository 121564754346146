import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { outboundRequestAction } from "./data/actions";
import Typography from "@material-ui/core/Typography";
import CircularIndeterminate from "../../../components/circular/CircularIndeterminate";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
class OutboundRequestDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        remarks: ""
      }
    };
  }
  showOutboundDialog = (
    classes,
    fullScreen,
    isOpen,
    applicationNumber,
    userType
  ) => {
    switch (userType) {
      case 1:
        return (
          <Grid item sm={12} md={8} xs={12}>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                disableBackdropClick={true}
                scroll="body"
              >
                <DialogTitle id="responsive-dialog-title">
                  File Number-{applicationNumber}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        Forwarded by DSBO.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error fullWidth>
                        <TextField
                          multiline
                          id="remarks"
                          name="remarks"
                          onChange={e => {
                            e.preventDefault();
                            this.handleChange(e);
                          }}
                          value={this.state.data.remarks}
                          label="Remarks"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={4} md={4}>
                      <div className={classes.cancelButton}>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            this.handleDialogClose();
                          }}
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={8} md={8}>
                      <div className={classes.buttons}>
                        <Button
                          disabled={this.props.isLoading ? true : false}
                          variant="contained"
                          color="primary"
                          onClick={e => {
                            e.preventDefault();
                            this.onSubmitHandler(this.props.isApprove);
                          }}
                          className={classes.button}
                        >
                          Submit
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                {this.props.isLoading ? <CircularIndeterminate /> : null}
              </Dialog>
            </div>
          </Grid>
        );
      case 2:
        return (
          <Grid item sm={12} md={8} xs={12}>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                disableBackdropClick={true}
                scroll="body"
              >
                <DialogTitle id="responsive-dialog-title">
                  File Number-{applicationNumber}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        Criminal verification done by DCRB.
                      </Typography>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={4} md={4}>
                      <div className={classes.cancelButton}>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            this.handleDialogClose();
                          }}
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={8} md={8}>
                      <div className={classes.buttons}>
                        <Button
                          disabled={this.props.isLoading ? true : false}
                          variant="contained"
                          color="primary"
                          onClick={e => {
                            e.preventDefault();
                            this.onSubmitHandler();
                          }}
                          className={classes.button}
                        >
                          Forward
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                {this.props.isLoading ? <CircularIndeterminate /> : null}
              </Dialog>
            </div>
          </Grid>
        );
      default:
        return "";
    }
  };
  handleChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      }
    });
  };
  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  onSubmitHandler = isApprove => {
    if (this.props.userInfo.user_type === 2) {
      this.props.outboundRequestAction(this.props.appliationId);
    } else if (this.props.userInfo.user_type === 1) {
      const data = {
        is_crime: !isApprove,
        remarks: this.state.data.remarks
      };
      this.props.outboundRequestAction(this.props.appliationId, data);
    }
  };

  handleDialogClose = () => {
    this.props.handleOutboundDialogClose();
  };

  render() {
    const {
      classes,
      fullScreen,
      isOpen,
      applicationNumber,
      userInfo
    } = this.props;
    return (
      <Grid justify="center" container>
        {this.showOutboundDialog(
          classes,
          fullScreen,
          isOpen,
          applicationNumber,
          userInfo.user_type
        )}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    isLoading: state.passportOutboundRequestDialog.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    outboundRequestAction: (id, data) =>
      dispatch(outboundRequestAction(id, data))
  };
}

export const styledOutboundRequestDialog = withStyles(styles)(
  withMobileDialog()(OutboundRequestDialog)
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledOutboundRequestDialog);
