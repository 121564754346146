import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import StepperDialog from "./stepperDialog";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import { DatePicker } from "material-ui-pickers";
import moment from "moment";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import { setHeader, setBackURL } from "../../components/appBar/data/actions";
import { styles } from "./styles";
import EmptyListing from "../../components/emptyListing";
import {
  getPCCHistory,
  getNOCHistory,
  getPassportRequestList,
  getPCCLog,
  getNOCLog,
  getPassportLog,
  getPVReport,
  resetLog,
  resetProps
} from "./data/actions";
import { showAlert } from "../../components/snackbar/data/actions";
import {
  getPCCCertificate,
  getNOCCertificate
} from "../../screens/trackingUser/data/actions";
import CircularIndeterminate from "../../components/circular/CircularIndeterminate";
import DateRange from "@material-ui/icons/DateRange";
import Tooltip from "@material-ui/core/Tooltip";
import { push } from "connected-react-router";
import getUsertype from "../../services/getUsertype";
import appUrls from "../../config/appUrls";
import ApplicationDetailsDialog from "../pccRequests/applicationDetailsDialog";
import NOCApplicationDetailsDialog from "../nocRequests/applicationDetailsDialog";
import PassportApplicationDetailsDialog from "../passportRequests/applicationDetailsDialog";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import dropdownData from "../../constants";
import validator from "../../services/validator/";
import { find } from "lodash";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActionsWrapped } from "../../components/pagination/";
import { PersonOutline, FolderOpen, FolderOutlined } from "@material-ui/icons";
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";
import getPDF from "../../data/staticApi/showPDF";

class AdminHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportId: "",
      from: null,
      to: null,
      expanded: null,
      referenceDialogOpen: false,
      showApplicationDialog: false,
      historyType: "",
      page: 0,
      rowsPerPage: 10,
      errors: {
        historyType: ""
      },
      rules: {
        historyType: {
          required: true
        }
      }
    };
  }

  componentDidMount() {
    this.props.resetProps();
    if (getUsertype(this.props.userInfo.user_type) === "CUSTOMER") {
      this.props.navigateTo(appUrls.TRACKING_USER);
    }
    this.props.setHeader({ header: "History" });
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleReferenceDialogOpen = () => {
    this.props.resetLog();
    this.setState({
      referenceDialogOpen: !this.state.referenceDialogOpen
    });
  };

  handleDateChange = (name, date) => {
    this.setState({
      [name]: moment(date).format("YYYY-MM-DD")
    });
  };

  handleComboChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleDropDownChange = event => {
    this.props.resetProps();
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  onReferenceClick = id => {
    if (this.state.historyType === "PCC") {
      this.props.getPCCLog(id);
    } else if (this.state.historyType === "NOC") {
      this.props.getNOCLog(id);
    } else if (this.state.historyType === "Passport") {
      this.props.getPassportLog(id);
    }
    this.setState({
      referenceDialogOpen: true
    });
  };

  onViewCertificateClick = id => {
    if (id) {
      if (this.state.historyType === "PCC") {
        this.props.getPCCCertificate(id);
      } else if (this.state.historyType === "NOC") {
        this.props.getNOCCertificate(id);
      } else if (this.state.historyType === "Passport") {
        this.props.getPVReport(id);
      }
    }
  };

  onViewApplicationDetails = (id, applicationNumber) => {
    this.setState({
      showApplicationDialog: true,
      appliationId: id,
      applicationNumber: applicationNumber
    });
  };

  handleApplicationsDetailsDialogClose = () => {
    this.setState({
      showApplicationDialog: false
    });
  };

  onSearchClick = () => {
    if (this.validate(this.state.rules, this.state)) {
      if (this.state.historyType === "PCC") {
        const params = {
          application_number: this.state.reportId,
          from_date: this.state.from,
          to_date: this.state.to,
          per_page: this.state.rowsPerPage,
          page_no: this.state.page + 1
        };
        this.props.getPCCHistory(params);
      } else if (this.state.historyType === "NOC") {
        const params = {
          application_number: this.state.reportId,
          from_date: this.state.from,
          to_date: this.state.to,
          per_page: this.state.rowsPerPage,
          page_no: this.state.page + 1
        };
        this.props.getNOCHistory(params);
      } else if (this.state.historyType === "Passport") {
        const params = {
          pv_request_id: this.state.reportId,
          from_date: this.state.from,
          to_date: this.state.to,
          per_page: this.state.rowsPerPage,
          page_no: this.state.page + 1
        };
        this.props.getPassportRequestList(params);
      }
    }
  };

  handleChangePage = page => {
    this.setState({ page }, () => {
      this.onSearchClick();
    });
  };

  onClearAllClick = () => {
    this.setState({
      reportId: "",
      from: null,
      to: null,
      expanded: null,
      referenceDialogOpen: false,
      showApplicationDialog: false,
      historyType: "",
      page: 0,
      rowsPerPage: 5,
      errors: {
        historyType: ""
      }
    });
    this.props.resetProps();
  };

  componentDidUpdate(prevProps) {
    if (this.props.userError && this.props.userError !== prevProps.userError) {
      let messageInfo = {
        message: this.props.userError,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.adminError &&
      this.props.adminError !== prevProps.adminError
    ) {
      let messageInfo = {
        message: this.props.adminError,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.pvCertificateData &&
      this.props.pvCertificateData !== prevProps.pvCertificateData
    ) {
      // window.open(this.props.pvCertificateData.pdf_url);
      getPDF(this.props.pvCertificateData.pdf_url);
    }
  }

  componentWillUnmount() {
    this.props.resetProps();
  }

  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, error => error !== "");

    this.setState({ errors });
    return !hasErrors;
  };

  render() {
    const { classes, historyList } = this.props;
    const {
      expanded,
      referenceDialogOpen,
      errors,
      historyType,
      page,
      rowsPerPage
    } = this.state;
    return (
      <>
        <Grid className={classes.root} justify="center" container>
          <Grid item xs={12} md={8}>
            <Grid item xs={12}>
              {historyList.length === 0 ? (
                <EmptyListing emptyText="Application list will appear here" />
              ) : null}
            </Grid>
            <Grid item className={classes.cellgrid}>
              <Paper className={classes.paper}>
                <Grid container spacing={24}>
                  <Grid item xs={6} sm={3}>
                    <FormControl
                      fullWidth
                      error={errors.historyType ? true : false}
                      className={classes.dropDown}
                    >
                      <InputLabel htmlFor="history-type">Type*</InputLabel>
                      <Select
                        inputProps={{
                          name: "historyType",
                          id: "history-type"
                        }}
                        value={historyType ? historyType : ""}
                        onChange={this.handleDropDownChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {dropdownData.historyTypes.map(item => (
                          <MenuItem value={item.value}>{item.name}</MenuItem>
                        ))}
                      </Select>
                      {errors.historyType ? (
                        <FormHelperText>{errors.historyType}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    {this.state.historyType === "Passport" ? (
                      <TextField
                        fullWidth
                        id="report-id"
                        label="Request ID"
                        name="reportId"
                        onChange={this.handleComboChange("reportId")}
                        value={this.state.reportId}
                        margin="dense"
                      />
                    ) : (
                      <TextField
                        fullWidth
                        id="report-id"
                        label="Application ID"
                        name="reportId"
                        onChange={this.handleComboChange("reportId")}
                        value={this.state.reportId}
                        margin="dense"
                      />
                    )}
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <DatePicker
                      fullWidth
                      disableFuture
                      views={["year", "month", "day"]}
                      format="DD/MM/YYYY"
                      mask={value =>
                        value
                          ? [
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/
                            ]
                          : []
                      }
                      keyboard
                      autoOk
                      label="From"
                      name="from"
                      value={this.state.from}
                      margin="dense"
                      onChange={date => this.handleDateChange("from", date)}
                      disableOpenOnEnter
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <DatePicker
                      fullWidth
                      disableFuture
                      views={["year", "month", "day"]}
                      format="DD/MM/YYYY"
                      mask={value =>
                        value
                          ? [
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/
                            ]
                          : []
                      }
                      keyboard
                      autoOk
                      label="To"
                      name="To"
                      value={this.state.to}
                      margin="dense"
                      onChange={date => this.handleDateChange("to", date)}
                      disableOpenOnEnter
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.buttonsGrid}>
                  <Grid item xs={5} sm={4} md={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      fullWidth
                      onClick={e => {
                        e.preventDefault();
                        this.onSearchClick();
                      }}
                    >
                      <SearchIcon className={classes.leftIcon} />
                      Search
                    </Button>
                  </Grid>
                  <Grid item xs={5} sm={4} md={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      fullWidth
                      onClick={e => {
                        e.preventDefault();
                        this.onClearAllClick();
                      }}
                    >
                      <CloseIcon
                        variant="contained"
                        className={classes.leftIcon}
                      />
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {historyList && historyList.length !== 0 ? (
              <div className={classes.tablePagination}>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={this.props.totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={(event, page) => {
                    if (event && "function" === typeof this.handleChangePage) {
                      this.handleChangePage(page);
                    }
                  }}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </div>
            ) : null}
            {this.state.historyType === "PCC" &&
            historyList &&
            historyList.length !== 0 ? (
              <Grid item xs={12}>
                {historyList.map((item, i) => {
                  return (
                    <ExpansionPanel
                      expanded={expanded === "panel" + i}
                      onChange={this.handleChange("panel" + i)}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid xs={12} container>
                          <Grid item className={classes.headingGrid}>
                            <Typography className={classes.secondaryHeading}>
                              #{item.application_number}
                            </Typography>
                            <Tooltip
                              title="Date of Application"
                              placement="left-start"
                            >
                              <Typography
                                color="textSecondary"
                                className={classes.dateSummary}
                                gutterBottom
                              >
                                <DateRange color="primary" />
                                <span className={classes.iconTextStyle}>
                                  {item.applied_on}
                                </span>
                              </Typography>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid xs={12} container>
                          <Grid item md={6} smdown={12}>
                            <Typography className={classes.secondaryHeading}>
                              Name of Applicant: {item.name_of_applicant}
                            </Typography>
                          </Grid>
                          <Grid item md={6} smdown={12}>
                            <Typography className={classes.secondaryHeading}>
                              PCC Type: {item.purpose}
                            </Typography>
                          </Grid>
                          <Grid xs={12} container>
                            <Grid item xs={12}>
                              <Typography className={classes.secondaryHeading}>
                                Reason: {item.reason_for_applying}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                      <ExpansionPanelActions
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onViewApplicationDetails(
                                item.id,
                                item.application_number
                              );
                            }}
                          >
                            View Application
                          </Button>
                        </div>
                        <div>
                          <Button
                            color="primary"
                            onClick={() => this.onReferenceClick(item.id)}
                          >
                            View Log
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => this.onViewCertificateClick(item.id)}
                          >
                            View Certificate
                          </Button>
                        </div>
                      </ExpansionPanelActions>
                    </ExpansionPanel>
                  );
                })}
                <ApplicationDetailsDialog
                  isOpen={this.state.showApplicationDialog}
                  handleApplicationsDetailsDialogClose={
                    this.handleApplicationsDetailsDialogClose
                  }
                  appliationId={this.state.appliationId}
                />
              </Grid>
            ) : null}
            {this.state.historyType === "NOC" &&
            historyList &&
            historyList.length !== 0 ? (
              <Grid item xs={12}>
                {historyList.map((item, i) => {
                  return (
                    <ExpansionPanel
                      expanded={expanded === "panel" + i}
                      onChange={this.handleChange("panel" + i)}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid xs={12} container>
                          <Grid item className={classes.headingGrid}>
                            <Typography className={classes.secondaryHeading}>
                              #{item.application_number}
                            </Typography>
                            <Tooltip
                              title="Date of Application"
                              placement="left-start"
                            >
                              <Typography
                                color="textSecondary"
                                className={classes.dateSummary}
                                gutterBottom
                              >
                                <DateRange color="primary" />
                                <span className={classes.iconTextStyle}>
                                  {item.applied_on}
                                </span>
                              </Typography>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid xs={12} container>
                          <Grid item md={6} smdown={12}>
                            <Typography className={classes.secondaryHeading}>
                              Name of Applicant: {item.name_of_applicant}
                            </Typography>
                          </Grid>
                          <Grid item md={6} smdown={12}>
                            <Typography className={classes.secondaryHeading}>
                              NOC Type: {item.nocType}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                      <ExpansionPanelActions
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onViewApplicationDetails(
                                item.id,
                                item.application_number
                              );
                            }}
                          >
                            View Application
                          </Button>
                        </div>
                        <div>
                          <Button
                            color="primary"
                            onClick={() => this.onReferenceClick(item.id)}
                          >
                            View Log
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => this.onViewCertificateClick(item.id)}
                          >
                            View Certificate
                          </Button>
                        </div>
                      </ExpansionPanelActions>
                    </ExpansionPanel>
                  );
                })}
                <NOCApplicationDetailsDialog
                  isOpen={this.state.showApplicationDialog}
                  handleApplicationsDetailsDialogClose={
                    this.handleApplicationsDetailsDialogClose
                  }
                  appliationId={this.state.appliationId}
                />
              </Grid>
            ) : null}
            {this.state.historyType === "Passport" &&
            historyList &&
            historyList.length !== 0 ? (
              <Grid item xs={12}>
                {historyList.map((item, i) => {
                  return (
                    <ExpansionPanel
                      expanded={expanded === "panel" + i}
                      onChange={this.handleChange("panel" + i)}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid xs={12} container>
                          <Grid item className={classes.headingGrid}>
                            <div className={classes.divStyle}>
                              <Avatar className={classes.avatarStyle}>
                                <FolderOutlined />
                              </Avatar>
                              <Typography
                                variant="button"
                                className={classes.secondaryHeading}
                              >
                                {item.pv_request_id}
                              </Typography>
                            </div>
                            <Hidden smDown>
                              <Tooltip
                                title="Date of Application"
                                placement="left-start"
                              >
                                <Typography
                                  color="textSecondary"
                                  className={classes.dateSummary}
                                  gutterBottom
                                >
                                  <DateRange color="primary" />
                                  <span className={classes.iconTextStyle}>
                                    {item.applied_on}
                                  </span>
                                </Typography>
                              </Tooltip>
                            </Hidden>
                          </Grid>
                        </Grid>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid xs={12} container>
                          <Grid
                            item
                            md={6}
                            smdown={12}
                            className={classes.detailsInnerGridStyle}
                          >
                            <PersonOutline color="action" />
                            <Typography className={classes.secondaryHeading}>
                              {item.name_of_applicant}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            smdown={12}
                            className={classes.detailsInnerGridStyle}
                          >
                            <FolderOpen color="action" />
                            <Typography className={classes.secondaryHeading}>
                              {item.pv_sequence_no}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                      <ExpansionPanelActions
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onViewApplicationDetails(
                                item.id,
                                item.application_number
                              );
                            }}
                          >
                            View Application
                          </Button>
                        </div>
                        <div>
                          <Button
                            color="primary"
                            onClick={() => this.onReferenceClick(item.id)}
                          >
                            View Log
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => this.onViewCertificateClick(item.id)}
                          >
                            Verification Report
                          </Button>
                        </div>
                      </ExpansionPanelActions>
                    </ExpansionPanel>
                  );
                })}
                <PassportApplicationDetailsDialog
                  isOpen={this.state.showApplicationDialog}
                  handleApplicationsDetailsDialogClose={
                    this.handleApplicationsDetailsDialogClose
                  }
                  appliationId={this.state.appliationId}
                  pathname={this.props.location.pathname}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {this.props.isLoading ? <CircularIndeterminate /> : null}
            </Grid>
          </Grid>
        </Grid>
        <StepperDialog
          open={referenceDialogOpen}
          handleClose={this.handleReferenceDialogOpen}
          data={this.props.historyLog}
          isLoading={this.props.isLoading}
        />
      </>
    );
  }
}

AdminHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    historyList: state.adminHistory.historyList,
    historyLog: state.adminHistory.historyLog,
    userInfo: state.auth.info,
    userError: state.userHistory.error,
    adminError: state.adminHistory.error,
    isLoading: state.adminHistory.isLoading,
    totalCount: state.adminHistory.totalCount,
    pvCertificateData: state.adminHistory.pvCertificateData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    setHeader: data => dispatch(setHeader(data)),
    setBackURL: data => dispatch(setBackURL(data)),
    getPCCHistory: data => dispatch(getPCCHistory(data)),
    getNOCHistory: data => dispatch(getNOCHistory(data)),
    getPCCLog: id => dispatch(getPCCLog(id)),
    getNOCLog: id => dispatch(getNOCLog(id)),
    getPassportLog: id => dispatch(getPassportLog(id)),
    getPCCCertificate: data => dispatch(getPCCCertificate(data)),
    getNOCCertificate: data => dispatch(getNOCCertificate(data)),
    getPVReport: data => dispatch(getPVReport(data)),
    resetLog: () => dispatch(resetLog()),
    navigateTo: url => dispatch(push(url)),
    resetProps: () => dispatch(resetProps()),
    getPassportRequestList: data => dispatch(getPassportRequestList(data))
  };
}

export const styledAdminHistory = withStyles(styles)(AdminHistory);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledAdminHistory);
