import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import PersonalDetails from "./PersonalDetails";
import AddressDetail from "./AddressDetail";
import SignupDetails from "./SignupDetails";
import styles from "./styles";
import validator from "../../services/validator/";
import { find } from "lodash";
import appUrls from "../../config/appUrls";
import { setHeader, setBackURL } from "../../components/appBar/data/actions";
import { signUp } from "./data/actions";
import { showAlert } from "../../components/snackbar/data/actions";
import { push } from "connected-react-router";
import CircularIndeterminate from "../../components/circular/CircularIndeterminate";

const steps = ["Applicant Details", "Applicant Address", "Login Details"];

class Registration extends React.Component {
  state = {
    activeStep: 0,
    // sameasPresent: true,
    data: {
      isPermanentAddress: true,
      email: "",
      firstname: "",
      middlename: "",
      lastname: "",
      mobileNumber: "",
      nationality: "",
      landline: "",
      identificationType: "",
      gender: "",
      identificationNumber: "",
      dob: "",
      permanentHouseNo: "",
      permanentStreetName: "",
      permanentArea: "",
      permanentVillage: "",
      permanentTehsil: "",
      permanentCountry: "",
      permanentState: "",
      permanentDistrict: "",
      permanentPoliceStation: "",
      permanentPincode: "",
      presentHouseNo: "",
      presentStreetName: "",
      presentArea: "",
      presentVillage: "",
      presentTehsil: "",
      presentCountry: "",
      presentState: "",
      presentDistrict: "",
      presentPoliceStation: "",
      presentPincode: "",
      username: "",
      password: "",
      confirmPassword: ""
    },
    errors: {
      email: "",
      firstname: "",
      middlename: "",
      lastname: "",
      mobileNumber: "",
      nationality: "",
      landline: "",
      identificationType: "",
      gender: "",
      identificationNumber: "",
      dob: "",
      permanentHouseNo: "",
      permanentStreetName: "",
      permanentArea: "",
      permanentVillage: "",
      permanentTehsil: "",
      permanentCountry: "",
      permanentState: "",
      permanentDistrict: "",
      permanentPoliceStation: "",
      permanentPincode: "",
      presentHouseNo: "",
      presentStreetName: "",
      presentArea: "",
      presentVillage: "",
      presentTehsil: "",
      presentCountry: "",
      presentState: "",
      presentDistrict: "",
      presentPoliceStation: "",
      presentPincode: "",
      username: "",
      password: "",
      confirmPassword: ""
    },
    detailsRules: {
      firstname: {
        required: true
      },
      lastname: {
        required: true
      },
      email: {
        email: true
      },
      mobileNumber: {
        required: true,
        number: true,
        mobile: true
      },
      nationality: {
        required: true
      },
      gender: {
        required: true
      },
      dob: {
        required: true
      }
    },
    addressRules: {
      presentVillage: { required: true },
      presentCountry: { required: true },
      presentState: { required: true },
      presentDistrict: { required: true },
      presentPoliceStation: { required: true },
      presentPincode: { required: true },
      permanentVillage: { required: true },
      permanentCountry: { required: true },
      permanentState: { required: true },
      permanentDistrict: { required: true },
      permanentPoliceStation: { required: true },
      permanentPincode: { required: true }
    },
    presentSameAddressRules: {
      permanentVillage: { required: true },
      permanentCountry: { required: true },
      permanentState: { required: true },
      permanentDistrict: { required: true },
      permanentPoliceStation: { required: true },
      permanentPincode: { required: true }
    },
    loginRules: {
      username: {
        required: true
      },
      password: {
        required: true
      },
      confirmPassword: {
        required: true
      }
    }
  };

  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, error => error !== "");

    this.setState({ errors });
    return !hasErrors;
  };

  handleChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  handleDateChange = (name, date) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: moment(date).format("YYYY-MM-DD")
      }
    });
  };

  handlePermanantChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        permanantAddress: {
          ...this.state.data.permanantAddress,
          [event.target.name]: event.target.value
        }
      }
    });
  };

  handleSameAsPermanent = () => {
    if (this.state.data.isPermanentAddress) {
      this.setState(state => ({
        data: {
          ...state.data,
          isPermanentAddress: !state.data.isPermanentAddress,
          presentHouseNo: "",
          presentStreetName: "",
          presentArea: "",
          presentVillage: "",
          presentTehsil: "",
          presentCountry: "",
          presentState: "",
          presentDistrict: "",
          presentPoliceStation: "",
          presentPincode: ""
        }
      }));
    } else {
      this.setState(state => ({
        ...this.state,
        data: {
          ...this.state.data,
          isPermanentAddress: !state.data.isPermanentAddress
        }
      }));
    }
  };

  handleNext = () => {
    if (this.state.activeStep === 0) {
      if (this.validate(this.state.detailsRules, this.state.data)) {
        this.setState(state => ({
          ...this.state,
          activeStep: state.activeStep + 1
        }));
      }
    } else if (this.state.activeStep === 1) {
      if (this.state.data.isPermanentAddress) {
        if (
          this.validate(this.state.presentSameAddressRules, this.state.data)
        ) {
          this.setState(state => ({
            ...this.state,
            activeStep: state.activeStep + 1,
            data: {
              ...state.data,
              presentHouseNo: state.data.permanentHouseNo,
              presentStreetName: state.data.permanentStreetName,
              presentArea: state.data.permanentArea,
              presentVillage: state.data.permanentVillage,
              presentTehsil: state.data.permanentTehsil,
              presentCountry: state.data.permanentCountry,
              presentState: state.data.permanentState,
              presentDistrict: state.data.permanentDistrict,
              presentPoliceStation: state.data.permanentPoliceStation,
              presentPincode: state.data.permanentPincode
            }
          }));
        }
      } else {
        if (this.validate(this.state.addressRules, this.state.data)) {
          this.setState(state => ({
            ...this.state,
            activeStep: state.activeStep + 1
          }));
        }
      }
    } else if (this.state.activeStep === 2) {
      const permanentAddressJSON = JSON.stringify({
        permanentHouseNo: this.state.data.permanentHouseNo,
        permanentStreetName: this.state.data.permanentStreetName,
        permanentArea: this.state.data.permanentArea,
        permanentVillage: this.state.data.permanentVillage,
        permanentTehsil: this.state.data.permanentTehsil,
        permanentCountry: this.state.data.permanentCountry,
        permanentState: this.state.data.permanentState,
        permanentDistrict: this.state.data.permanentDistrict,
        permanentPoliceStation: this.state.data.permanentPoliceStation,
        permanentPincode: this.state.data.permanentPincode
      });

      const presentAddressJSON = JSON.stringify({
        presentHouseNo: this.state.data.presentHouseNo,
        presentStreetName: this.state.data.presentStreetName,
        presentArea: this.state.data.presentArea,
        presentVillage: this.state.data.presentVillage,
        presentTehsil: this.state.data.presentTehsil,
        presentCountry: this.state.data.presentCountry,
        presentState: this.state.data.presentState,
        presentDistrict: this.state.data.presentDistrict,
        presentPoliceStation: this.state.data.presentPoliceStation,
        presentPincode: this.state.data.presentPincode
      });

      const data = {
        email: this.state.data.email,
        first_name: this.state.data.firstname,
        middle_name: this.state.data.middleName
          ? this.state.data.middleName
          : "",
        last_name: this.state.data.lastname,
        mobile_no: this.state.data.mobileNumber,
        nationality: this.state.data.nationality,
        landline_no: this.state.data.landline,
        id_type: this.state.data.identificationType
          ? this.state.data.identificationType
          : "",
        gender: this.state.data.gender,
        id_number: this.state.data.identificationNumber,
        dob: this.state.data.dob,
        username: this.state.data.username,
        password: this.state.data.password,
        is_superuser: false,
        is_address_same: this.state.data.isPermanentAddress
          ? this.state.data.isPermanentAddress
          : false,
        user_type: 7,
        effective_from_date: moment()
          .format()
          .split("T")[0],
        effective_to_date: null,
        permanent_address: permanentAddressJSON,
        present_address: presentAddressJSON
      };

      if (this.state.data.password != this.state.data.confirmPassword) {
        this.setState({
          errors: {
            ...this.state.errors,
            confirmPassword: "Passwords do not match"
          }
        });
        return false;
      } else if (this.validate(this.state.loginRules, this.state.data)) {
        this.props.signUp(data);
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.saveRegistration &&
      this.props.saveRegistration !== prevProps.saveRegistration
    ) {
      let messageInfo = {
        message: "Registration completed successfully",
        variant: "success"
      };
      this.props.showAlert(messageInfo);
      this.setState(state => ({
        ...this.state,
        activeStep: 3
      }));
    }
    if (this.props.error && this.props.error !== prevProps.error) {
      let messageInfo = {
        message: this.props.error,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <PersonalDetails
            onChange={this.handleChange}
            onDateChange={this.handleDateChange}
            data={this.state.data}
            errors={this.state.errors}
          />
        );
      case 1:
        return (
          <AddressDetail
            isSamePermanentAddress={this.state.data.isPermanentAddress}
            onPresentChange={this.handleSameAsPermanent}
            onChange={this.handleChange}
            data={this.state.data}
            errors={this.state.errors}
          />
        );
      case 2:
        return (
          <SignupDetails
            onChange={this.handleChange}
            onDateChange={this.handleDateChange}
            data={this.state.data}
            errors={this.state.errors}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Register
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              {activeStep === steps.length ? (
                <>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your Registering.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your have completed your registration. Please login with
                    your credentials!
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={e => {
                        e.preventDefault();
                        this.props.navigateTo(appUrls.LOGIN);
                      }}
                      className={classes.loginButton}
                    >
                      Login
                    </Button>
                  </Typography>
                </>
              ) : (
                <>
                  {this.getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep == 0 && (
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          this.props.navigateTo(appUrls.LOGIN);
                        }}
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                    )}
                    {activeStep !== 0 && (
                      <Button
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Submit" : "Next"}
                    </Button>
                  </div>
                </>
              )}
            </>
          </Paper>
          {this.props.isLoading ? <CircularIndeterminate /> : null}
        </main>
      </>
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    isLoading: state.reducerRegistration.isLoading,
    error: state.reducerRegistration.error,
    message: state.reducerRegistration.message,
    saveRegistration: state.reducerRegistration.saveRegistration
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    signUp: data => dispatch(signUp(data)),
    setHeader: data => dispatch(setHeader(data)),
    setBackURL: data => dispatch(setBackURL(data)),
    navigateTo: url => dispatch(push(url))
  };
}

export const StyledRegistration = withStyles(styles)(Registration);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledRegistration);
