import { get as getSession } from "../../services/storage";
import { removeStore as removeSession } from "../../services/storage";
import appUrls from "../../config/appUrls";

const getPDF = pdfURL => {
  downloadFile(pdfURL, function (blob) {
    var win = window.open("_blank");
    var url = window.URL.createObjectURL(blob);
    win.location = url;
  });
};

const downloadFile = (url, success) => {
  const { exist: tokenExist, token } = getSession();
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.setRequestHeader("Authorization", "Token " + token);
  xhr.responseType = "blob";
  xhr.onreadystatechange = function () {
    if (xhr.status == 401) {
      removeSession();
      window.location.reload(appUrls.LOGIN);
    }
    if (xhr.readyState === 4 && xhr.response !== null && xhr.status == 200) {
      if (success) success(xhr.response);
    }
  };
  xhr.send(null);
};

export default getPDF;