import React from "react";

export function Upload(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      xmlSpace="preserve"
      width="24px"
      height="24px"
    >
      <g>
        <g>
          <g>
            <path
              fill="#757575"
              d="M24.446,0.573C24.079,0.203,23.502,0,22.925,0c-0.516,0-1.153,0.203-1.52,0.572l-11.4,11.481
			c-0.56,0.563-0.725,1.408-0.417,2.139c0.306,0.731,1.022,1.207,1.815,1.206l3.72-0.03l-0.054,16.288
			c0.006,1.613,1.316,2.919,2.93,2.919h9.855c1.611,0,2.922-1.306,2.928-2.919l-0.053-16.284l3.719,0.027
			c0.793,0.002,1.512-0.474,1.815-1.206c0.308-0.73,0.144-1.577-0.417-2.139L24.446,0.573z"
            />
            <path
              fill="#757575"
              d="M42.442,19.371c-1.623,0-2.947,1.315-2.947,2.938v16.392c0,0.678-0.522,1.217-1.203,1.217H7.47
			c-0.68,0-1.239-0.539-1.239-1.217V22.308c0-1.622-1.282-2.938-2.905-2.938c-1.622,0-2.905,1.315-2.905,2.938V38.7
			c0,3.918,3.131,7.11,7.05,7.11h30.821c3.92,0,7.098-3.192,7.098-7.11V22.308C45.389,20.686,44.063,19.371,42.442,19.371z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
