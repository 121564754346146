import {
  PCC_OUTBOUND_REQUEST_ACTION,
  PCC_OUTBOUND_RESET_REQUEST
} from "./constants";
import requestHandler from "../../../../services/api/requestHandler";
import { PCC_API } from "../../../../config/apiUrls.js";

export function outboundRequestAction(id, data) {
  return requestHandler(
    PCC_OUTBOUND_REQUEST_ACTION,
    `${PCC_API}${id}/`,
    "PUT",
    {
      data
    }
  );
}

export function resetProps() {
  return { type: PCC_OUTBOUND_RESET_REQUEST };
}
