export const LOGIN_API = "/user/login/";
export const PCC_HISTORY_API = "/pcc/report/";
export const NOC_HISTORY_API = "/noc/report/";
export const CERTIFICATE_VERIFICATION_API = "/pcc/verify/";
export const SIGNUP_API = "/user/signup/";
export const PCC_API = "/pcc/";
export const FETCH_USERDATA_API = "/user/info/";
export const RETRIEVE_PCC_API = "/pcc/preview/";
export const PCC_LOG_API = "/pcc/track/";
export const PASSPORT_LOG_API = "/passport/track/";
export const PCC_CERTIFICATE_VIEW_API = "/pcc/certificate/";
export const NOC_CERTIFICATE_VIEW_API = "/noc/certificate/";
export const PV_REPORT_VIEW_API = "/passport/verification_pdf/";
export const PASSPORT_HISTORY_API = "/passport/report/";
export const PASSPORT_DATA_EXPORT_API = "/passport/eseva_data/";
export const PASSPORT_API = "/passport/";
export const CCTNS_API = "/cctns/search_accused/";
export const CCTNS_ADDRESSES_API = "/cctns/other_address/";
export const CCTNS_FIR_SECTIONS_API = "/cctns/fir_section/";
export const RETRIEVE_PASSPORT_API = "/passport/preview/";
export const RETRIEVE_PASSPORT_QUESTIONNAIRE_API = "/passport/questions/";
export const SUBMIT_PASSPORT_QUESTIONNAIRE_API =
  "/passport/field_verification/";
export const NOC_API = "/noc/";
export const NOC_LOG_API = "/noc/track/";
export const CCTNS_COUNTRY_API = "/cctns/country/";
export const CCTNS_STATE_API = "/cctns/state/";
export const CCTNS_DISTRICT_API = "/cctns/district/";
export const CCTNS_STATION_API = "/cctns/police_station/";
export const MASTER_STATION_API = "/master/stations/";
export const CHANGE_PASSWORD_API = "/user/reset_password/";
export const FVO_REPORT_API = "/report/fvo_report/";
export const AGE_REPORT_API = "/report/age_report/";
