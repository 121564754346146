import {
  FETCH_USERDATA_API,
  MASTER_STATION_API
} from "../../../config/apiUrls";
import requestHandler from "../../../services/api/requestHandler";
import { MASTER_FVO_LIST, MASTER_STATION_LIST } from "./constants";

export function getMasterStationList() {
  return requestHandler(MASTER_STATION_LIST, MASTER_STATION_API, "GET", {});
}

export function getMasterFVOList(params) {
  return requestHandler(MASTER_FVO_LIST, FETCH_USERDATA_API, "GET", {
    params
  });
}
