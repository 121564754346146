import React from "react";
import { Route, Switch } from "react-router";
import AppShell from "./appShell";
import appUrls from "../config/appUrls";
import Login from "../screens/login";
import Register from "../screens/registration";
import TrackingForUser from "../screens/trackingUser";
import PCC from "../screens/pcc";
import AdminHistory from "../screens/history";
import PCCRequests from "../screens/pccRequests/pcc";
import PassportDataExport from "../screens/passportDataExport";
import NOCRequests from "../screens/nocRequests/noc";
import PassportRequests from "../screens/passportRequests/passport";
import NOC from "../screens/noc";
import PassportCCTNS from "../screens/cctns/cctnsSearch";
import Disclaimer from "../screens/disclaimer";
import GenericNotFound from "../screens/genericNotFound";
import DailyReport from "../screens/reports/dailyReport";
import ApplicationReport from "../screens/reports/applicationReport";

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path={appUrls.LOGIN} component={Login} />
      {/* <Route exact path={appUrls.REGISTER} component={Register} /> */}
      {/* <Route exact path={appUrls.DISCLAIMER} component={Disclaimer} /> */}
      <AppShell>
        <Switch>
          <Route path={appUrls.TRACKING_USER} component={TrackingForUser} />
          {/* <Route exact path={appUrls.PCC} component={PCC} /> */}
          {/* <Route path={appUrls.NOC} component={NOC} /> */}
          <Route path={appUrls.HISTORY} component={AdminHistory} />
          {/* <Route exact path={appUrls.PCC_REQUESTS} component={PCCRequests} /> */}
          <Route
            exact
            path={appUrls.PASSPORT_DATA_EXPORT}
            component={PassportDataExport}
          />
          {/* <Route exact path={appUrls.NOC_REQUESTS} component={NOCRequests} /> */}
          <Route
            exact
            path={appUrls.PASSPORT_REQUESTS}
            component={PassportRequests}
          />
          <Route exact path={appUrls.DAILY_REPORT} component={DailyReport} />
          <Route
            exact
            path={appUrls.APPLICATION_REPORT}
            component={ApplicationReport}
          />
          <Route
            path={`${appUrls.CCTNS_RESULTS}/:applicationID`}
            component={PassportCCTNS}
          />
          <Route path="*" exact={true} component={GenericNotFound} />
        </Switch>
      </AppShell>
    </Switch>
  );
};

export default AppRoutes;
