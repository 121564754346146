import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";

import dropdownData from "../../constants";

const SignupDetails = ({ onChange, data, errors }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Set Up Login Details
      </Typography>
      <Grid container spacing={24}>
        {/* <Grid container> */}
        <Grid item xs={12} sm={6}>
          <FormControl error fullWidth>
            <TextField
              id="username"
              name="username"
              label="Username"
              onChange={onChange}
              value={data.username ? data.username : ""}
              fullWidth
            />
            {errors.username ? (
              <FormHelperText>{errors.username}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Divider />
        <Grid item xs={6} sm={6} />
        <Grid item xs={12} sm={6}>
          <FormControl error fullWidth>
            <TextField
              required
              maxLength={30}
              id="password"
              type="password"
              name="password"
              onChange={onChange}
              value={data.password ? data.password : ""}
              label="Password"
              fullWidth
              placeholder="Password"
            />
            {errors.password ? (
              <FormHelperText>{errors.password}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Divider />
        <Grid item xs={6} sm={6} />
        <Grid item xs={12} sm={6}>
          <FormControl error fullWidth>
            <TextField
              required
              maxLength={30}
              id="confirmPassword"
              type="confirmPassword"
              name="confirmPassword"
              type="password"
              onChange={onChange}
              label="Confirm Password"
              fullWidth
              placeholder="Confirm Password"
            />
            {errors.confirmPassword ? (
              <FormHelperText>{errors.confirmPassword}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        {/* </Grid> */}
      </Grid>
    </>
  );
};

export default SignupDetails;
