export const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  criminalDetailsGrid: {
    paddingLeft: "0px",
    marginTop: theme.spacing.unit * 1.5,
    marginBottom: theme.spacing.unit * 1.5
  },
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  answerGridStyle: {
    backgroundColor: "#f5f5f5"
  },
  reassignGridStyle: {
    marginTop: theme.spacing.unit * 2
  },
  reassignButtonStyle: {
    display: "flex",
    justifyContent: "center"
  },
  detailsGridStyle: {
    margin: theme.spacing.unit * 1.5
  },
  avatarGridStyle: {
    alignItems: "center"
  },
  avatarStyle: {
    height: "164px",
    width: "164px",
    [theme.breakpoints.down("xs")]: {
      height: "128px",
      width: "128px"
    },
    backgroundColor: theme.palette.secondary.light,
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.24), 0 7px 10px -5px rgba(0, 0, 0,.4)"
  },
  applicantDetailsGridStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center"
    }
  },
  detailsGridStyle: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing.unit * 2
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing.unit * 2
    }
  },
  detailsInnerGridStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  addressInnerGridStyle: {
    display: "flex",
    flexDirection: "row"
  },
  detailStyle: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    // marginTop: theme.spacing.unit * 1
    marginLeft: theme.spacing.unit * 1.5
  },
  paper: {
    width: "100%",
    padding: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
});
