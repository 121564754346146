import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { setHeader } from "../../components/appBar/data/actions";
import { styles } from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import appUrls from "../../config/appUrls";
import getUsertype from "../../services/getUsertype";
import kpLogo from "../../assets/images/logo.png";
import Paper from "@material-ui/core/Paper";

class Disclaimer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.setHeader({ header: "Disclaimer" });
  }

  handleProceedClick = () => {
    if (getUsertype(this.props.info.user_type) !== "CUSTOMER") {
      this.props.navigateTo(appUrls.PASSPORT_REQUESTS);
    } else {
      this.props.navigateTo(appUrls.TRACKING_USER);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid className={classes.root}>
          <span className={classes.imageSpan}>
            <img src={kpLogo} className={classes.imageStyle} />
          </span>
          <Paper className={classes.paper}>
            <Paper className={classes.headingPaper}>
              <Typography variant="title" className={classes.heading}>
                Disclaimer
              </Typography>
            </Paper>

            <Typography
              variant="h6"
              style={{ marginTop: 40, textAlign: "center" }}
            >
              This is an Alpha preview of PoC.
            </Typography>
            <div className={classes.content}>
              <Typography variant="subheading">
                1. For PoC only as per the proposal shared.
              </Typography>
              <Typography variant="subheading">
                2. CCTNS integration with test environment.
              </Typography>
              <Typography variant="subheading">
                3. Limited number of applications only.
              </Typography>
              <Typography variant="subheading">
                4. Covers only single user log-in model.
              </Typography>
              <Typography variant="subheading">
                5. Covers only workflow for Passport verification by Police.
              </Typography>
            </div>
            <Button
              color="primary"
              className={classes.button}
              onClick={e => {
                e.preventDefault();
                this.handleProceedClick();
              }}
            >
              Click here to Proceed
            </Button>
          </Paper>
        </Grid>
      </>
    );
  }
}

Disclaimer.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    info: state.auth.info
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: url => dispatch(push(url)),
    setHeader: data => dispatch(setHeader(data))
  };
}

export const styledDisclaimer = withStyles(styles)(Disclaimer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledDisclaimer);
