import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { connect } from "react-redux";
import { styles } from "./styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { retrievePassportApplication } from "./data/action";
import { showAlert } from "../../../components/snackbar/data/actions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularIndeterminate from "../../../components/circular/CircularIndeterminate";
import EmptyListing from "../../../components/emptyListing";
import { outboundRequestAction } from "../OutboundRequestDialog/data/actions";
import Fab from "@material-ui/core/Fab";
import Avatar from "@material-ui/core/Avatar";
import {
  PersonOutline,
  PhoneAndroid,
  PersonPin,
  Event,
  VerifiedUserOutlined
} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import appUrls from "../../../config/appUrls";
import { get as getSession } from "../../../services/storage";

var getInitials = function(string) {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ApplicationDetailsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: "",
      data: {},
      errors: {}
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
      this.props.retrievePassportApplication(this.props.appliationId);
    }
    if (
      this.props.retrievePassportApplicationData &&
      prevProps.retrievePassportApplicationData !==
        this.props.retrievePassportApplicationData
    ) {
      this.getImage(this.props.retrievePassportApplicationData.applicant_image);
    }
    if (this.props.error !== prevProps.error) {
      let messageInfo = { message: this.props.error, variant: "error" };
      this.props.showAlert(messageInfo);
    }
  }

  handleChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  handleCancel = () => {
    this.resetStates();
    this.props.handleApplicationsDetailsDialogClose();
  };

  handleReAssign = user => {
    if (user === "sho") {
      const data = {
        is_reassigned: true,
        is_reassigned_to_fvo: false,
        is_reassigned_to_sho: true
      };
      this.props.outboundRequestAction(this.props.appliationId, data);
    } else if (user === "fvo") {
      const data = {
        is_reassigned: true,
        is_reassigned_to_fvo: true,
        is_reassigned_to_sho: false
      };
      this.props.outboundRequestAction(this.props.appliationId, data);
    }
    this.handleCancel();
  };

  getImage = imgURL => {
    if (imgURL) {
      this.downloadFile(imgURL, blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.setState({
            ...this.state,
            imageSrc: reader.result
          });
        };
      });
    }
  };

  downloadFile = (url, success) => {
    const { exist: tokenExist, token } = getSession();
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Authorization", "Token " + token);
    xhr.responseType = "blob";
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (success) success(xhr.response);
      }
    };
    xhr.send(null);
  };

  resetStates = () => {
    this.setState({
      imageSrc: "",
      data: {},
      errors: {}
    });
  };

  render() {
    const { classes, retrievePassportApplicationData } = this.props;

    return (
      <>
        <Dialog
          fullScreen
          open={this.props.isOpen}
          onClose={this.handleCancel}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Application Details
              </Typography>
            </Toolbar>
          </AppBar>
          {retrievePassportApplicationData ? (
            <Grid container spacing={24} className={classes.root}>
              <Paper className={classes.paper}>
                <Grid item xs={12}>
                  <Typography
                    variant="button"
                    style={{ marginTop: 8, marginBottom: 0 }}
                  >
                    Applicant Details
                  </Typography>
                </Grid>
                <Grid container className={classes.applicantDetailsGridStyle}>
                  <div container className={classes.avatarGridStyle}>
                    <Avatar
                      src={this.state.imageSrc}
                      // src={retrievePassportApplicationData.applicant_image}
                      className={classes.avatarStyle}
                    >
                      {getInitials(retrievePassportApplicationData.name)}
                    </Avatar>
                  </div>
                  <Grid
                    container
                    spacing={24}
                    sm={9}
                    className={classes.detailsGridStyle}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.detailsInnerGridStyle}
                    >
                      <PersonOutline color="action" />
                      <Typography className={classes.detailStyle}>
                        {retrievePassportApplicationData.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.detailsInnerGridStyle}
                    >
                      <PhoneAndroid color="action" />
                      <Typography className={classes.detailStyle}>
                        {retrievePassportApplicationData.phone_no
                          ? retrievePassportApplicationData.phone_no
                          : "Nil"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.detailsInnerGridStyle}
                    >
                      <Event color="action" />
                      <Typography className={classes.detailStyle}>
                        {retrievePassportApplicationData.dob}
                      </Typography>
                    </Grid>
                    {retrievePassportApplicationData.adhaar_no ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.detailsInnerGridStyle}
                      >
                        <VerifiedUserOutlined color="action" />
                        <Typography className={classes.detailStyle}>
                          {retrievePassportApplicationData.adhaar_no}
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.addressInnerGridStyle}
                    >
                      <PersonPin color="action" />
                      <Typography className={classes.detailStyle}>
                        {retrievePassportApplicationData.address}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper className={classes.paper}>
                <Grid item xs={12}>
                  <Typography
                    variant="button"
                    style={{ marginTop: 16, marginBottom: 0 }}
                  >
                    Criminal Verification Details
                  </Typography>
                </Grid>
                {retrievePassportApplicationData.criminal_history_FIR_data ? (
                  <div>
                    {retrievePassportApplicationData.fir_data &&
                    retrievePassportApplicationData.fir_data.fir_reg_num !==
                      "No criminal records found" ? (
                      <Grid
                        container
                        spacing={24}
                        className={classes.criminalDetailsGrid}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            style={{ marginTop: 8, marginBottom: 8 }}
                            color="secondary"
                          >
                            Completed. Criminal records found!
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="fir-display"
                            name="firDisplay"
                            disabled
                            value={
                              "FIR Display: " +
                              retrievePassportApplicationData.fir_data
                                .firDisplay
                            }
                            InputProps={{
                              disableUnderline: true
                            }}
                            fullWidth
                            className={classes.answerGridStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="fir-reg-num"
                            name="firRegNum"
                            disabled
                            value={
                              "FIR Reg Number: " +
                              retrievePassportApplicationData.fir_data
                                .fir_reg_num
                            }
                            InputProps={{
                              disableUnderline: true
                            }}
                            fullWidth
                            className={classes.answerGridStyle}
                          />
                        </Grid>
                        {retrievePassportApplicationData.criminal_history_FIR_data !==
                        0
                          ? retrievePassportApplicationData.criminal_history_FIR_data.map(
                              (item, i) => {
                                return (
                                  <>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        id="crime-act-name"
                                        name="crimeName"
                                        disabled
                                        value={
                                          item.crimeActName
                                            ? "Crime Act Name: " +
                                              item.crimeActName
                                            : "Crime Act Name: Nil"
                                        }
                                        InputProps={{
                                          disableUnderline: true
                                        }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        id="crime-section"
                                        name="crimeSection"
                                        disabled
                                        value={
                                          item.crimeSection
                                            ? "Crime Section: " +
                                              item.crimeSection
                                            : "Crime Section: Nil"
                                        }
                                        InputProps={{
                                          disableUnderline: true
                                        }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </>
                                );
                              }
                            )
                          : null}
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          style={{ marginTop: 16, marginBottom: 0 }}
                          color="primary"
                        >
                          Completed. No Criminal records found!
                        </Typography>
                      </Grid>
                    )}
                  </div>
                ) : (
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      style={{ marginTop: 16, marginBottom: 0 }}
                      color="primary"
                    >
                      Pending
                    </Typography>
                  </Grid>
                )}
              </Paper>
              <Paper className={classes.paper}>
                <Grid item xs={12}>
                  <Typography
                    variant="button"
                    style={{ marginTop: 16, marginBottom: 0 }}
                  >
                    Physical Verification Details
                  </Typography>
                </Grid>
                {retrievePassportApplicationData.fvo &&
                retrievePassportApplicationData.fvo !== 0 ? (
                  <>
                    {retrievePassportApplicationData.fvo.map((item, i) => {
                      return (
                        <Grid
                          container
                          spacing={24}
                          className={classes.criminalDetailsGrid}
                        >
                          <Grid item xs={12} sm={12}>
                            <Typography variant="subheading">
                              {"Question " + (i + 1) + ": " + item.question}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Answer (FVO)</Typography>
                            <TextField
                              id="question"
                              name="question"
                              disabled
                              value={item.answer}
                              fullWidth
                              InputProps={{
                                disableUnderline: true
                              }}
                              className={classes.answerGridStyle}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Answer (DSBO)</Typography>
                            <TextField
                              id="question"
                              name="question"
                              disabled
                              value={
                                retrievePassportApplicationData.sho
                                  ? retrievePassportApplicationData.sho[i]
                                      .answer
                                  : ""
                              }
                              fullWidth
                              InputProps={{
                                disableUnderline: true
                              }}
                              className={classes.answerGridStyle}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Remark (FVO)</Typography>
                            <TextField
                              id="question"
                              name="question"
                              disabled
                              value={item.remark}
                              fullWidth
                              className={classes.answerGridStyle}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Remark (DSBO)</Typography>
                            <TextField
                              id="question"
                              name="question"
                              disabled
                              value={
                                retrievePassportApplicationData.sho
                                  ? retrievePassportApplicationData.sho[i]
                                      .remark
                                  : ""
                              }
                              fullWidth
                              className={classes.answerGridStyle}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                    {(this.props.userInfo.user_type === 1 &&
                      this.props.pathname !== appUrls.HISTORY) ||
                    this.props.userInfo.user_type === 5 ||
                    this.props.userInfo.user_type === 6 ? (
                      <Grid
                        container
                        spacing={24}
                        className={classes.reassignGridStyle}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.reassignButtonStyle}
                        >
                          <Fab
                            color="primary"
                            variant="extended"
                            size="medium"
                            disabled={this.props.isLoading ? true : false}
                            onClick={e => {
                              e.preventDefault();
                              this.handleReAssign("fvo");
                            }}
                          >
                            Re-Assign FVO
                          </Fab>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.reassignButtonStyle}
                        >
                          <Fab
                            color="primary"
                            variant="extended"
                            size="medium"
                            disabled={this.props.isLoading ? true : false}
                            onClick={e => {
                              e.preventDefault();
                              this.handleReAssign("sho");
                            }}
                          >
                            Re-Assign DSBO
                          </Fab>
                        </Grid>
                      </Grid>
                    ) : null}
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      style={{ marginTop: 16, marginBottom: 0 }}
                      color="primary"
                    >
                      Pending
                    </Typography>
                  </Grid>
                )}
              </Paper>
            </Grid>
          ) : (
            <EmptyListing emptyText="Applicant details will appear here" />
          )}
          {this.props.isLoading ? <CircularIndeterminate /> : null}
        </Dialog>
      </>
    );
  }
}

ApplicationDetailsDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    retrievePassportApplicationData:
      state.passportApplicationDetails.retrievePassportApplicationData,
    error: state.passportApplicationDetails.error,
    isLoading: state.passportApplicationDetails.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    retrievePassportApplication: id =>
      dispatch(retrievePassportApplication(id)),
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    outboundRequestAction: (id, data) =>
      dispatch(outboundRequestAction(id, data))
  };
}

export const styledApplicationDetailsDialog = withStyles(styles)(
  withMobileDialog()(ApplicationDetailsDialog)
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledApplicationDetailsDialog);
