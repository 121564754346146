import requestHandler from "../../../../services/api/requestHandler";

import { RETRIEVE_PASSPORT_API } from "../../../../config/apiUrls.js";

import { PASSPORT_APPLICATION_DETAILS } from "./constants";

export function retrievePassportApplication(id) {
  return requestHandler(
    PASSPORT_APPLICATION_DETAILS,
    `${RETRIEVE_PASSPORT_API}${id}/`,
    "GET",
    {}
  );
}
