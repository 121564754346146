import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import StepperDialog from "./stepperDialog";
import { showAlert } from "../../components/snackbar/data/actions";
import moment from "moment";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import { setHeader, setBackURL } from "../../components/appBar/data/actions";
import { styles } from "./styles";
import EmptyListing from "../../components/emptyListing";
import { getNOCs, getNOCHashCode, getNOCHistory } from "./data/actions";
import { getNOCCertificate, resetLog, resetViewHash } from "./data/actions";
import jsPDF from "jspdf";
import Tooltip from "@material-ui/core/Tooltip";
import DateRange from "@material-ui/icons/DateRange";
import CircularIndeterminate from "../../components/circular/CircularIndeterminate";
import { push } from "connected-react-router";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActionsWrapped } from "../../components/pagination/";

class trackPCCApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportId: null,
      from: null,
      to: null,
      type: null,
      status: null,
      expanded: null,
      referenceDialogOpen: false,
      page: 0,
      rowsPerPage: 10
    };
  }

  componentDidMount() {
    if (this.props.userInfo.user_id) {
      let params = {
        per_page: this.state.rowsPerPage,
        page_no: this.state.page + 1
      };
      this.props.getNOCs(params);
    }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      let params = {
        per_page: this.state.rowsPerPage,
        page_no: this.state.page + 1
      };
      this.props.getNOCs(params);
    });
  };

  handleReferenceDialogOpen = () => {
    this.props.resetLog();
    this.setState({
      referenceDialogOpen: !this.state.referenceDialogOpen
    });
  };
  handleDateChange = (name, date) => {
    this.setState({
      [name]: moment(date).format("YYYY-MM-DD")
    });
  };

  handleComboChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  onReferenceClick = id => {
    this.setState({
      referenceDialogOpen: true
    });
    if (id) {
      this.props.getNOCHistory(id);
    }
  };

  onViewCertificateClick = id => {
    if (id) {
      this.props.getNOCCertificate(id);
    }
  };

  onViewHashCodeClick = id => {
    if (id) {
      this.props.getNOCHashCode(id);
    }
  };

  onClearAllClick = () => {
    this.setState({
      reportId: null,
      from: null,
      to: null,
      type: null,
      status: null
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      let messageInfo = {
        message: this.props.error,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.viewNOCHashCodeSuccess &&
      this.props.viewNOCHashCodeSuccess !== prevProps.viewNOCHashCodeSuccess
    ) {
      var doc = new jsPDF({
        orientation: "landscape"
      });
      doc.text("Hash Code\n" + this.props.nocCertificateHash, 10, 10);
      doc.setProperties({
        title: "HashCode"
      });
      window.open(doc.output("bloburl"));
      this.props.resetViewHash();
    }
  }

  render() {
    const { classes } = this.props;
    const { expanded, referenceDialogOpen, page, rowsPerPage } = this.state;
    return (
      <>
        <Grid className={classes.root}>
          {this.props.historyList && this.props.historyList.length !== 0 ? (
            <div className={classes.tablePagination}>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                count={this.props.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </div>
          ) : null}
          <Grid className={classes.cellgrid} />
          {this.props.historyList && this.props.historyList.length !== 0 ? (
            <Grid item xs={12}>
              {this.props.historyList.map((item, i) => {
                return (
                  <ExpansionPanel
                    expanded={expanded === "panel" + i}
                    onChange={this.handleChange("panel" + i)}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid xs={12} container>
                        <Grid item className={classes.headingGrid}>
                          <Typography className={classes.applicationNumber}>
                            NOC - {item.application_number}
                          </Typography>
                          {item.name_of_applicant && (
                            <Tooltip
                              title="Date of Application"
                              placement="left-start"
                            >
                              <Typography
                                color="textSecondary"
                                className={classes.dateSummary}
                                gutterBottom
                              >
                                <DateRange color="primary" />
                                <span className={classes.iconTextStyle}>
                                  {item.applied_on}
                                </span>
                              </Typography>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid xs={12} container>
                        <Grid item md={6} smDown={12}>
                          <Typography className={classes.secondaryHeading}>
                            Name of Applicant: {item.name_of_applicant}
                          </Typography>
                        </Grid>
                        <Grid item md={6} smDown={12}>
                          <Typography className={classes.secondaryHeading}>
                            NOC Type: {item.nocType}
                          </Typography>
                        </Grid>
                        <Grid xs={12} container>
                          <Grid item xs={12}>
                            <Typography className={classes.secondaryHeading}>
                              Reason: {item.reason_for_applying}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                    <ExpansionPanelActions
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <Button
                          color="secondary"
                          onClick={() => this.onViewCertificateClick(item.id)}
                        >
                          View Certificate
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => this.onViewHashCodeClick(item.id)}
                        >
                          View Hash Code
                        </Button>
                      </div>
                      <div>
                        <Button
                          color="primary"
                          onClick={() => this.onReferenceClick(item.id)}
                        >
                          View Log
                        </Button>
                      </div>
                    </ExpansionPanelActions>
                  </ExpansionPanel>
                );
              })}
            </Grid>
          ) : (
            <EmptyListing emptyText="Your application list will appear here." />
          )}
          {this.props.isLoading ? <CircularIndeterminate /> : null}
        </Grid>
        <StepperDialog
          open={referenceDialogOpen}
          handleClose={this.handleReferenceDialogOpen}
          data={this.props.historyData}
          isLoading={this.props.isLoading}
        />
      </>
    );
  }
}

History.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    historyList: state.userHistory.historyList,
    historyData: state.userHistory.historyData,
    error: state.userHistory.error,
    viewNOCHashCodeSuccess: state.userHistory.viewNOCHashCodeSuccess,
    nocCertificateHash: state.userHistory.nocCertificateHash,
    isLoading: state.userHistory.isLoading,
    totalCount: state.userHistory.totalCount
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    setHeader: data => dispatch(setHeader(data)),
    setBackURL: data => dispatch(setBackURL(data)),
    getNOCs: params => dispatch(getNOCs(params)),
    getNOCHistory: data => dispatch(getNOCHistory(data)),
    getNOCCertificate: data => dispatch(getNOCCertificate(data)),
    resetLog: () => dispatch(resetLog()),
    getNOCHashCode: data => dispatch(getNOCHashCode(data)),
    resetViewHash: () => dispatch(resetViewHash()),
    navigateTo: url => dispatch(push(url))
  };
}

export const styledTrackPCCApplication = withStyles(styles)(
  trackPCCApplication
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledTrackPCCApplication);
