import green from "@material-ui/core/colors/green";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%"
  },
  container: {
    height: "100%"
  },
  leftGrid: {
    height: "30%",
    backgroundSize: "cover",
    background: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      background: theme.palette.primary.main,
      backgroundSize: "cover",
      height: "initial",
      width: "100%"
    }
  },
  listStyle: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },
  liStyle: {
    color: "white"
  },
  featureIconStyle: {
    position: "relative",
    right: 10,
    top: 5
  },
  brandLogoStyle: {
    display: "block",
    marginLeft: " auto",
    marginRight: "auto",
    [theme.breakpoints.up("md")]: {
      width: 185,
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.down("sm")]: {
      width: 160,
      marginTop: theme.spacing.unit * 2
    }
  },
  poweredByLogoStyle: {
    width: 70
  },
  poweredByStyle: {
    display: "block",
    color: "rgb(144, 136, 136)",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing.unit * 6
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 2
    }
  },
  secondaryAction: {
    width: "100%",
    marginBottom: theme.spacing.unit * 10
  },
  formStyle: {
    width: "100%"
  },
  loginGridStyle: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  inputfieldGrid: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    textAlign: "center"
  },
  buttonfieldGrid: {
    marginTop: theme.spacing.unit * 5,
    marginBottom: theme.spacing.unit * 2,
    textAlign: "center"
  },
  textField: {
    width: "100%"
  },
  textField2: {
    width: "100%"
  },
  verifyButton: {
    marginTop: theme.spacing.unit * 2
  },
  wrapper: {
    position: "relative"
  },
  buttonSuccess: {
    marginTop: theme.spacing.unit * 2,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -4,
    marginLeft: -12
  },
  verifyPaper: {
    ...theme.mixins.gutters(),
    paddingTop: "10px",
    paddingBottom: "18px",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "10px"
  },
  loginButtonStyle: {
    width: "70%"
  },
  recaptchaGrid: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing.unit * 5
  }
});

export default styles;
