export const styles = theme => ({
  root: {
    width: "100%",
    padding: "4px",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing.unit * 10,
      marginBottom: theme.spacing.unit * 4
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 4
    },
    overflowX: "auto"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  headingGrid: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    // marginTop: theme.spacing.unit * 1
    marginTop: 4
  },
  applicationNumber: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    // marginTop: theme.spacing.unit * 1
    marginTop: 4
  },
  iconTextStyle: {
    position: "relative",
    bottom: 7,
    left: 10
  },
  table: {
    width: "100%"
  },
  groupNameCell: {
    width: "60%"
  },
  groupActionCell: {
    width: "40%"
  },
  cellgrid: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around"
  },
  nameHeader: {
    width: "60%"
  },
  actionHeader: {
    width: "40%",
    textAlign: "center"
  },
  // paper: {
  //   position: "absolute",
  //   width: theme.spacing.unit * 80,
  //   backgroundColor: theme.palette.background.paper,
  //   boxShadow: theme.shadows[10],
  //   padding: theme.spacing.unit * 4
  // },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  deleteButton: {
    float: "right"
  },
  textContentArea: {
    paddingLeft: theme.spacing.unit
  },
  statusSummary: {
    marginTop: 8,
    position: "absolute",
    right: 50
  },
  data: {
    overflowWrap: "break-word"
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  menu: {
    width: 200
  },
  dateSummary: {
    marginTop: 8,
    position: "relative",
    marginRight: 8
  },
  tablePagination: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }
});

export function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}
