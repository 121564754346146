import React from "react";

export function NotFound(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width="144px"
      height="144px"
    >
      <g>
        <g>
          <g>
            <path
              d="m331 360v122c0 8.398438-6.597656 15-15 15h-120c-8.402344 0-15-6.601562-15-15v-122c0-8.402344 6.597656-15 15-15h120c8.402344 0 15 6.597656 15 15zm0 0"
              fill="#b4d2d7"
            />
            <path
              d="m331 360v122c0 8.398438-6.597656 15-15 15h-60v-152h60c8.402344 0 15 6.597656 15 15zm0 0"
              fill="#87a0af"
            />
            <path
              d="m391 497c0 8.398438-6.597656 15-15 15h-240c-8.402344 0-15-6.601562-15-15 0-8.402344 6.597656-15 15-15h240c8.402344 0 15 6.597656 15 15zm0 0"
              fill="#e1ebf0"
            />
            <path
              d="m391 497c0 8.398438-6.597656 15-15 15h-120v-30h120c8.402344 0 15 6.597656 15 15zm0 0"
              fill="#b4d2d7"
            />
            <path
              d="m512 255v120c0 8.398438-6.597656 15-15 15h-482c-8.402344 0-15-6.601562-15-15v-120c3.035156-.167969 273.003906-15.039062 256-14.101562zm0 0"
              fill="#e1ebf0"
            />
            <path
              d="m512 255v120c0 8.398438-6.597656 15-15 15h-241v-149.101562zm0 0"
              fill="#b4d2d7"
            />
            <path
              d="m497 0h-482c-8.402344 0-15 6.597656-15 15v240h512v-240c0-8.402344-6.597656-15-15-15zm0 0"
              fill="#07485e"
            />
            <path
              d="m497 0h-241v255h256v-240c0-8.402344-6.597656-15-15-15zm0 0"
              fill="#03232e"
            />
            <path
              d="m301 315c0 8.398438-6.597656 15-15 15h-60c-8.402344 0-15-6.601562-15-15 0-8.402344 6.597656-15 15-15h60c8.402344 0 15 6.597656 15 15zm0 0"
              fill="#b4d2d7"
            />
            <path
              d="m301 315c0 8.398438-6.597656 15-15 15h-30v-30h30c8.402344 0 15 6.597656 15 15zm0 0"
              fill="#87a0af"
            />
            <path
              d="m166 90c-8.289062 0-15 6.710938-15 15v15h-30v-45c0-8.289062-6.710938-15-15-15s-15 6.710938-15 15v60c0 8.289062 6.710938 15 15 15h45v45c0 8.289062 6.710938 15 15 15s15-6.710938 15-15v-90c0-8.289062-6.710938-15-15-15zm0 0"
              fill="#e1ebf0"
            />
            <path
              d="m421 105v90c0 8.398438-6.597656 15-15 15s-15-6.601562-15-15v-45h-45c-8.402344 0-15-6.601562-15-15v-60c0-8.402344 6.597656-15 15-15s15 6.597656 15 15v45h30v-15c0-8.402344 6.597656-15 15-15s15 6.597656 15 15zm0 0"
              fill="#b4d2d7"
            />
            <path
              d="m286 60h-60c-8.402344 0-15 6.597656-15 15v120c0 8.398438 6.597656 15 15 15h60c8.402344 0 15-6.601562 15-15v-120c0-8.402344-6.597656-15-15-15zm-15 120h-30v-90h30zm0 0"
              fill="#e1ebf0"
            />
            <path
              d="m301 75v120c0 8.398438-6.597656 15-15 15h-30v-30h15v-90h-15v-30h30c8.402344 0 15 6.597656 15 15zm0 0"
              fill="#b4d2d7"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
