import {
  NOC_INBOUND_REQUEST_ACTION,
  NOC_INBOUND_RESET_REQUEST
} from "./constants";
import requestHandler from "../../../../services/api/requestHandler";
import { NOC_API } from "../../../../config/apiUrls.js";

export function inboundRequestAction(id, data) {
  return requestHandler(NOC_INBOUND_REQUEST_ACTION, `${NOC_API}${id}/`, "PUT", {
    data
  });
}

export function resetProps() {
  return { type: NOC_INBOUND_RESET_REQUEST };
}
