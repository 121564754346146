import requestHandler from "../../../../services/api/requestHandler";

import { AGE_REPORT_API } from "../../../../config/apiUrls.js";

import { AGE_REPORT, RESET_AGE_REPORT_PROPS } from "./constants";

export function getAgeReport(params) {
  return requestHandler(AGE_REPORT, AGE_REPORT_API, "GET", {
    params
  });
}

export function resetAgeReportProps() {
  return { type: RESET_AGE_REPORT_PROPS };
}
