import requestHandler from "../../../services/api/requestHandler";

import { FETCH_USERDATA_API, NOC_API } from "../../../config/apiUrls";

import { APPLY_NOC, FETCH_USERDATA } from "./constants";

export function applyPCC(data) {
  return requestHandler(APPLY_NOC, NOC_API, "POST", { data });
}

export function fetchUserData(id) {
  return requestHandler(
    FETCH_USERDATA,
    `${FETCH_USERDATA_API}${id}/`,
    "GET",
    {}
  );
}
