import requestHandler from "../../../../services/api/requestHandler";

import { FVO_REPORT_API } from "../../../../config/apiUrls.js";

import { RESET_DAILY_REPORT_PROPS, DAILY_REPORT } from "./constants";

export function getDailyReport(params) {
  return requestHandler(DAILY_REPORT, FVO_REPORT_API, "GET", {
    params
  });
}

export function resetDailyReportProps() {
  return { type: RESET_DAILY_REPORT_PROPS };
}
