import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { find } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styles from "./styles";
import validator from "../../services/validator/";
import CircularIndeterminate from "../../components/circular/CircularIndeterminate";
import { submitLogin } from "../../data/session/actions";
import { showAlert } from "../../components/snackbar/data/actions";
import { resetLogin } from "../../data/session/actions";
import KpLogo from "../../assets/images/logo.png";
import Fab from "@material-ui/core/Fab";
import UltsLogo from "../../assets/images/ults-logo.png";
import appUrls from "../../config/appUrls";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import { submitHashCode, resetProps } from "./data/actions";
import getUsertype from "../../services/getUsertype";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import CheckIcon from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import { Paper, Hidden } from "@material-ui/core";
import { Lock, Person } from "@material-ui/icons";
import Reaptcha from "reaptcha";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      loading: false,
      success: false,
      isVerified: false,
      data: {
        username: "",
        password: "",
        hashcode: ""
      },
      errors: {
        username: "",
        password: "",
        hashcode: ""
      },
      rules: {
        username: {
          required: true
        },
        password: {
          required: true
        }
      },
      rules2: {
        hashcode: {
          required: true
        }
      }
    };
  }

  componentDidMount() {
    if (this.props.loginSuccess) {
      // this.props.navigateTo(urls.DISCLAIMER);
      if (getUsertype(this.props.info.user_type) !== "CUSTOMER") {
        this.props.navigateTo(appUrls.PASSPORT_REQUESTS);
      } else {
        this.props.navigateTo(appUrls.TRACKING_USER);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.loginSuccess &&
      prevProps.loginSuccess !== this.props.loginSuccess
    ) {
      // this.props.navigateTo(urls.DISCLAIMER);
      if (getUsertype(this.props.info.user_type) !== "CUSTOMER") {
        this.props.navigateTo(appUrls.PASSPORT_REQUESTS);
      } else {
        this.props.navigateTo(appUrls.TRACKING_USER);
      }
    }

    if (this.props.error !== prevProps.error) {
      let messageInfo = { message: this.props.error, variant: "error" };
      this.props.showAlert(messageInfo);
    }

    if (this.props.certificateError !== prevProps.certificateError) {
      let messageInfo = {
        message: this.props.certificateError,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
      this.setState({
        ...this.state,
        loading: false,
        success: false
      });
    }

    if (
      this.props.verificationResponse.certificate_verified &&
      prevProps.verificationResponse.certificate_verified !==
        this.props.verificationResponse.certificate_verified
    ) {
      this.setState({
        ...this.state,
        loading: false,
        success: true
      });
    }

    if (
      !this.props.verificationResponse.certificate_verified &&
      prevProps.verificationResponse.certificate_verified !==
        this.props.verificationResponse.certificate_verified
    ) {
      this.setState({
        ...this.state,
        loading: false,
        success: false
      });
    }
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleChange = event => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [event.target.name]: event.target.value },
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  handleHashCodeChange = event => {
    this.props.resetProps();
    this.setState({
      ...this.state,
      data: { ...this.state.data, [event.target.name]: event.target.value },
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  handleRefresh = event => {
    this.props.resetProps();
    this.setState({
      ...this.state,
      loading: false,
      success: false,
      data: {
        ...this.state.data,
        hashcode: ""
      },
      errors: {
        ...this.state.errors,
        hashcode: ""
      }
    });
  };

  verifyCallback = response => {
    if (response) {
      this.setState({
        ...this.state,
        isVerified: true
      });
    }
  };

  handleSubscribe = event => {
    event.preventDefault();
    const { rules, data } = this.state;
    if (this.validate(rules, data)) {
      if (this.state.isVerified) {
        this.onSubmit();
      } else {
        let messageInfo = {
          message: "Please verify that you are a human!",
          variant: "error"
        };
        this.props.showAlert(messageInfo);
      }
    }
  };

  onSubmit = () => {
    const params = {
      username: this.state.data.username,
      password: this.state.data.password
    };
    this.props.submitLogin(params);
  };

  onSignUp = event => {
    this.props.navigateTo(appUrls.REGISTER);
  };

  onVerify = event => {
    event.preventDefault();
    const params = {
      hash_value: this.state.data.hashcode
    };
    const { rules2, data } = this.state;
    if (this.validate(rules2, data)) {
      if (!this.state.loading) {
        this.setState({
          ...this.state,
          success: false,
          loading: true
        });
      }
      this.props.submitHashCode(params);
    }
  };

  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, error => error !== "");
    this.setState({
      ...this.state,
      errors
    });
    return !hasErrors;
  };

  render() {
    const { loading, success, data } = this.state;
    const { classes } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: success && data.hashcode,
      [classes.verifyButton]: !success
    });
    return (
      <div className={classes.root}>
        <Grid container className={classes.container}>
          <Hidden xsDown>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.leftGrid}
              item
              xs={12}
              md={6}
            >
              {/* <div className={classes.featuresStyle}>
                <Paper className={classes.verifyPaper}>
                  <Typography color="inherit" variant="h6">
                    <TextField
                      id="hashcode"
                      name="hashcode"
                      label="Enter Hash Code"
                      type="text"
                      value={this.state.data.hashcode}
                      error={this.state.errors.hashcode ? true : false}
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      onChange={e => this.handleHashCodeChange(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Reset"
                              onClick={this.handleRefresh}
                            >
                              <Close />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Typography>
                  <div className={classes.wrapper}>
                    <Grid>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        className={buttonClassname}
                        disabled={loading}
                        fullWidth
                        onClick={e => {
                          if (!success) {
                            this.onVerify(e);
                          }
                        }}
                      >
                        {success ? <CheckIcon /> : ""}
                        {this.props.verificationResponse.certificate_verified
                          ? "VERIFIED"
                          : "VERIFY CERTIFICATE"}
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}

                      {this.props.verificationResponse.certificate_verified && (
                        <Button
                          color="primary"
                          fullWidth
                          href={this.props.verificationResponse.pdf_url}
                          className={classes.verifyButton}
                        >
                          DOWNLOAD
                        </Button>
                      )}
                    </Grid>
                  </div>
                </Paper>
              </div> */}

              <div className={classes.featuresStyle}>
                <Typography color="inherit" variant="h6">
                  <ul className={classes.listStyle}>
                    <li className={classes.liStyle}>Powered by Blockchain.</li>
                  </ul>
                </Typography>
              </div>
            </Grid>
          </Hidden>
          <Grid container justify="center" alignItems="center" xs={12} md={6}>
            <Grid item xs={10} md={6} className={classes.loginGridStyle}>
              <img
                className={classes.brandLogoStyle}
                src={KpLogo}
                alt="Kerala Police Logo"
              />

              <form
                className={classes.formStyle}
                onSubmit={e => this.handleSubscribe(e)}
              >
                <Grid className={classes.inputfieldGrid}>
                  <TextField
                    label="Username"
                    value={this.state.data.username}
                    error={this.state.errors.username ? true : false}
                    className={classes.textField}
                    name="username"
                    type="text"
                    margin="normal"
                    onChange={e => this.handleChange(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person color="primary" />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid className={classes.inputfieldGrid}>
                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="adornment-password">
                      Password
                    </InputLabel>
                    <Input
                      id="adornment-password"
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.data.password}
                      error={this.state.errors.password ? true : false}
                      name="password"
                      onChange={e => this.handleChange(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? (
                              <Visibility color="primary" />
                            ) : (
                              <VisibilityOff color="primary" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid className={classes.recaptchaGrid}>
                  <Reaptcha
                    sitekey="6LeEBb8UAAAAAKm5FX3C_gWn841dtgWNX4pcjaja"
                    onVerify={this.verifyCallback}
                  />
                </Grid>
                <Grid className={classes.buttonfieldGrid}>
                  <Fab
                    disabled={this.props.isLoading ? true : false}
                    type="submit"
                    variant="extended"
                    color="primary"
                    className={classes.loginButtonStyle}
                  >
                    Log In
                  </Fab>
                </Grid>
                {/* <Grid className={classes.inputfieldGrid}>
                  <Typography variant="body1" align="center" color="inherit">
                    Or
                  </Typography>
                </Grid>
                <Grid className={classes.inputfieldGrid}>
                  <Button
                    size="medium"
                    color="secondary"
                    style={{ textTransform: "initial" }}
                    onClick={e => this.onSignUp(e)}
                  >
                    Dont have an account? SIGN UP
                  </Button>
                </Grid> */}
              </form>
              <span className={classes.poweredByStyle}>
                <Typography variant="body1" color="inherit">
                  Powered By
                </Typography>
                <img
                  className={classes.poweredByLogoStyle}
                  src={UltsLogo}
                  alt="UL Technology Solutions logo"
                />
              </span>
            </Grid>
          </Grid>
        </Grid>
        {this.props.isLoading ? <CircularIndeterminate /> : null}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isLoading: state.auth.isLoading,
    data: state.auth.loginData,
    error: state.auth.loginError,
    certificateError: state.certificateVerification.error,
    loginSuccess: state.auth.isLoggedIn,
    info: state.auth.info,
    verificationResponse: state.certificateVerification.verificationResponse
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitLogin: data => dispatch(submitLogin(data)),
    navigateTo: url => dispatch(push(url)),
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    resetLogin: () => dispatch(resetLogin()),
    submitHashCode: data => dispatch(submitHashCode(data)),
    resetProps: () => dispatch(resetProps())
  };
}

export const styledLogin = withStyles(styles)(Login);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledLogin);
