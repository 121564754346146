export const styles = theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "4px",
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 4
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "4px",
      marginTop: theme.spacing.unit * 1
    },
    overflowX: "auto"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  detailsHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 1
  },
  iconTextStyle: {
    position: "relative",
    bottom: 7,
    left: 10
  },
  headingGrid: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  dateSummary: {
    marginTop: 8,
    position: "relative",
    marginRight: 8
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4
  },
  passportDetails: {
    padding: theme.spacing.unit * 3
  },
  searchBar: {
    marginBottom: theme.spacing.unit * 4,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing.unit
    }
  },
  addressDetails: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  detailsGridStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  detailStyle: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    // marginTop: theme.spacing.unit * 1
    marginLeft: theme.spacing.unit * 1.5
  },
  dropDown: {
    marginTop: "5px"
  }
});
