import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "../data/session/reducer";
import appShell from "../containers/appShell/data/reducer";
import appbar from "../components/appBar/data/reducer";
import snackbar from "../components/snackbar/data/reducer";
import adminHistory from "../screens/history/data/reducer";
import userHistory from "../screens/trackingUser/data/reducer";
import certificateVerification from "../screens/login/data/reducer";
import pcc from "../screens/pcc/data/reducer";
import noc from "../screens/noc/data/reducer";
import reducerRegistration from "../screens/registration/data/reducer";
import tabs from "../components/appbarTabs/data/reducer";
import pccInboundRequestDialog from "../screens/pccRequests/InboundRequestDialog/data/reducer";
import pccOutboundRequestDialog from "../screens/pccRequests/OutboundRequestDialog/data/reducer";
import nocInboundRequestDialog from "../screens/nocRequests/InboundRequestDialog/data/reducer";
import nocOutboundRequestDialog from "../screens/nocRequests/OutboundRequestDialog/data/reducer";
import passportOutboundRequestDialog from "../screens/passportRequests/OutboundRequestDialog/data/reducer";
import managePCCRequests from "../screens/pccRequests/pcc/data/reducer";
import manageNOCRequests from "../screens/nocRequests/noc/data/reducer";
import managePassportRequests from "../screens/passportRequests/passport/data/reducer";
import pccApplicationDetails from "../screens/pccRequests/applicationDetailsDialog/data/reducer";
import nocApplicationDetails from "../screens/nocRequests/applicationDetailsDialog/data/reducer";
import passportApplicationDetails from "../screens/passportRequests/applicationDetailsDialog/data/reducer";
import passportQuestionnaire from "../screens/passportRequests/questionnaireDialog/data/reducer";
import cctnsPassport from "../screens/cctns/cctnsSearch/data/reducer";
import passportDataExport from "../screens/passportDataExport/data/reducer";
import changePasswordDialog from "../components/Dialogs/changePassword/data/reducer";
import dailyReport from "../screens/reports/dailyReport/data/reducer";
import ageReport from "../screens/reports/applicationReport/data/reducer";
import masterData from "../data/staticApi/master/reducer";

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    appbar,
    snackbar,
    appShell,
    adminHistory,
    userHistory,
    pcc,
    certificateVerification,
    reducerRegistration,
    tabs,
    pccApplicationDetails,
    passportDataExport,
    pccInboundRequestDialog,
    pccOutboundRequestDialog,
    nocInboundRequestDialog,
    nocOutboundRequestDialog,
    passportOutboundRequestDialog,
    managePCCRequests,
    manageNOCRequests,
    managePassportRequests,
    nocApplicationDetails,
    passportApplicationDetails,
    passportQuestionnaire,
    noc,
    cctnsPassport,
    changePasswordDialog,
    dailyReport,
    ageReport,
    masterData
  });
