import { handle } from "redux-pack";

import {
  RETRIEVE_CCTNS_RESULTS,
  FORWARD_CCTNS_RESULTS,
  RETRIEVE_PASSPORT_DETAILS,
  PASSPORT_FIR_SECTIONS_DETAILS,
  PASSPORT_ADDRESSES_DETAILS,
  RESET_CCTNS_RESULTS_PROPS,
  RETRIEVE_CCTNS_COUNTRY,
  RETRIEVE_CCTNS_STATE,
  RETRIEVE_CCTNS_DISTRICT,
  RETRIEVE_CCTNS_STATION
} from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  message: "",
  transactionHash: "",
  retrievePassportData: null,
  retrieveCCTNSResultsData: [],
  forwardSuccess: false,
  forwardMessage: "",
  searchDone: false,
  retrieveAddressesData: [],
  retrieveFIRSectionsData: [],
  countryList: [],
  stateList: [],
  districtList: [],
  stationList: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RETRIEVE_CCTNS_COUNTRY:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            countryList: [],
            stateList: [],
            districtList: [],
            stationList: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => ({
          ...prevState,
          countryList: payload.data.results
        })
      });
    case RETRIEVE_CCTNS_STATE:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            stateList: [],
            districtList: [],
            stationList: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => ({
          ...prevState,
          stateList: payload.data.results
        })
      });
    case RETRIEVE_CCTNS_DISTRICT:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            districtList: [],
            stationList: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => ({
          ...prevState,
          districtList: payload.data.results
        })
      });
    case RETRIEVE_CCTNS_STATION:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            stationList: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => ({
          ...prevState,
          stationList: payload.data.results
        })
      });

    case RETRIEVE_CCTNS_RESULTS:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null,
            searchDone: false
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            message: payload.data.message,
            retrieveCCTNSResultsData: payload.data,
            searchDone: true
          };
        }
      });
    case FORWARD_CCTNS_RESULTS:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time",
          forwardSuccess: false
        }),
        success: prevState => {
          return {
            ...prevState,
            forwardMessage: payload.data.message,
            transactionHash: payload.data.transaction_hash,
            forwardSuccess: true
          };
        }
      });
    case RETRIEVE_PASSPORT_DETAILS:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            retrievePassportData: payload.data
          };
        }
      });
    case PASSPORT_FIR_SECTIONS_DETAILS:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            message: payload.data.message,
            retrieveFIRSectionsData: payload.data
          };
        }
      });
    case PASSPORT_ADDRESSES_DETAILS:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            message: payload.data.message,
            retrieveAddressesData: payload.data
          };
        }
      });
    case RESET_CCTNS_RESULTS_PROPS:
      return {
        ...state,
        isLoading: false,
        error: null,
        message: "",
        retrieveCCTNSResultsData: [],
        forwardSuccess: false,
        forwardMessage: "",
        searchDone: false,
        retrieveAddressesData: "",
        retrieveFIRSectionsData: ""
      };
    default:
      return state;
  }
};
