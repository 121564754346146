const styles = theme => ({
  img: {
    height: "100px",
    borderRadius: "0px 0px 15px 0px"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  master: {
    position: "relative",
    alignContent: "left",
    marginTop: "21px"
  },
  avatar: {
    width: "42px",
    height: "40px",
    margin: "8px"
  },
  nameText: {
    color: "rgba(255, 255, 255, 255)",
    position: "relative",
    top: "9px"
  },
  name: {
    color: "rgba(255, 255, 255, 255)",
    position: "relative",
    top: "9px"
  },
  fullName: {
    color: "rgba(255, 255, 255, 255)",
    position: "relative",
    top: "12px"
  },
  chevron: {
    float: "right",
    marginRight: 25
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#FAFAFA"
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: "#FAFAFA"
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: 0,
    backgroundColor: "#FAFAFA"
  },
  drawerGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  signedIdGrid: {
    marginBottom: theme.spacing.unit * 1
  },
  appName: {
    marginLeft: theme.spacing.unit * 2,
    color: "#C62828"
  },
  nestedReportStyle: {
    paddingLeft: theme.spacing.unit * 4
  }
});
export default styles;
