import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import dropdownData from "../../constants";

const AddressForm = ({
  isSamePermanentAddress,
  onPresentChange,
  onChange,
  data,
  errors
}) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Applicant address
      </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography
            style={{ marginTop: 15, marginBottom: 0 }}
            variant="button"
            color="primary"
          >
            Permanent address
          </Typography>
        </Grid>

        <Address
          show
          onChange={onChange}
          data={data}
          errors={errors}
          isPermanent={true}
        />
        <Grid item xs={12}>
          <Typography
            style={{ marginTop: 15 }}
            variant="button"
            color="primary"
          >
            Present address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={onPresentChange}
                color="secondary"
                name="saveAddress"
                checked={isSamePermanentAddress}
              />
            }
            label="Same as permanant address"
          />
        </Grid>
        <Address
          show={!isSamePermanentAddress}
          onChange={onChange}
          data={data}
          errors={errors}
          isPermanent={false}
        />
      </Grid>
    </>
  );
};

export default AddressForm;

const Address = ({ show, onChange, data, errors, isPermanent }) => {
  return show ? (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl error fullWidth>
          <TextField
            required
            id="houseno"
            name={isPermanent ? "permanentHouseNo" : "presentHouseNo"}
            value={isPermanent ? data.permanentHouseNo : data.presentHouseNo}
            onChange={onChange}
            label="House Number/House Name"
            fullWidth
            autoComplete="houseno"
          />
          {isPermanent ? (
            errors.permanentHouseNo ? (
              <FormHelperText>{errors.permanentHouseNo}</FormHelperText>
            ) : (
              ""
            )
          ) : errors.presentHouseNo ? (
            <FormHelperText>{errors.presentHouseNo}</FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="streetname"
          name={isPermanent ? "permanentStreetName" : "presentStreetName"}
          value={
            isPermanent ? data.permanentStreetName : data.presentStreetName
          }
          onChange={onChange}
          label="Street Name"
          fullWidth
          autoComplete="streetname"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="area"
          name={isPermanent ? "permanentArea" : "presentArea"}
          value={isPermanent ? data.permanentArea : data.presentArea}
          onChange={onChange}
          label="Colony/Local/Area"
          fullWidth
          autoComplete="area"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl error fullWidth>
          <TextField
            required
            id="village"
            name={isPermanent ? "permanentVillage" : "presentVillage"}
            value={isPermanent ? data.permanentVillage : data.presentVillage}
            onChange={onChange}
            label="Village/Town/City"
            fullWidth
            autoComplete="village"
          />
          {isPermanent ? (
            errors.permanentVillage ? (
              <FormHelperText>{errors.permanentVillage}</FormHelperText>
            ) : (
              ""
            )
          ) : errors.presentVillage ? (
            <FormHelperText>{errors.presentVillage}</FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="tehsil"
          name={isPermanent ? "permanentTehsil" : "presentTehsil"}
          value={isPermanent ? data.permanentTehsil : data.presentTehsil}
          onChange={onChange}
          label="Tehsil/Town/Block"
          fullWidth
          autoComplete="tehsil"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          error={isPermanent ? errors.permanentCountry : errors.presentCountry}
          fullWidth
        >
          <InputLabel htmlFor="country">Country*</InputLabel>
          <Select
            inputProps={{
              name: isPermanent ? "permanentCountry" : "presentCountry",
              id: "country"
            }}
            value={isPermanent ? data.permanentCountry : data.presentCountry}
            onChange={onChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dropdownData.countries.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {isPermanent ? (
            errors.permanentCountry ? (
              <FormHelperText>{errors.permanentCountry}</FormHelperText>
            ) : (
              ""
            )
          ) : errors.presentCountry ? (
            <FormHelperText>{errors.presentCountry}</FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl error fullWidth>
          <TextField
            required
            id="state"
            name={isPermanent ? "permanentState" : "presentState"}
            value={isPermanent ? data.permanentState : data.presentState}
            onChange={onChange}
            label="State"
            fullWidth
            autoComplete="state"
          />
          {isPermanent ? (
            errors.permanentState ? (
              <FormHelperText>{errors.permanentState}</FormHelperText>
            ) : (
              ""
            )
          ) : errors.presentState ? (
            <FormHelperText>{errors.presentState}</FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl error fullWidth>
          <TextField
            required
            id="district"
            name={isPermanent ? "permanentDistrict" : "presentDistrict"}
            value={isPermanent ? data.permanentDistrict : data.presentDistrict}
            onChange={onChange}
            label="District"
            fullWidth
            autoComplete="district"
          />
          {isPermanent ? (
            errors.permanentDistrict ? (
              <FormHelperText>{errors.permanentDistrict}</FormHelperText>
            ) : (
              ""
            )
          ) : errors.presentDistrict ? (
            <FormHelperText>{errors.presentDistrict}</FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl error fullWidth>
          <TextField
            required
            id="police-station"
            name={
              isPermanent ? "permanentPoliceStation" : "presentPoliceStation"
            }
            value={
              isPermanent
                ? data.permanentPoliceStation
                : data.presentPoliceStation
            }
            onChange={onChange}
            label="Police Station"
            fullWidth
            autoComplete="police-station"
          />
          {isPermanent ? (
            errors.permanentPoliceStation ? (
              <FormHelperText>{errors.permanentPoliceStation}</FormHelperText>
            ) : (
              ""
            )
          ) : errors.presentPoliceStation ? (
            <FormHelperText>{errors.presentPoliceStation}</FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl error fullWidth>
          <TextField
            required
            id="pincode"
            name={isPermanent ? "permanentPincode" : "presentPincode"}
            value={isPermanent ? data.permanentPincode : data.presentPincode}
            onChange={onChange}
            label="Pincode"
            fullWidth
            autoComplete="pincode"
          />
          {isPermanent ? (
            errors.permanentPincode ? (
              <FormHelperText>{errors.permanentPincode}</FormHelperText>
            ) : (
              ""
            )
          ) : errors.presentPincode ? (
            <FormHelperText>{errors.presentPincode}</FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </Grid>
    </>
  ) : (
    ""
  );
};
