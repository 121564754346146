import React from "react";
import { Provider } from "react-redux";
import store, { history } from "../store/configureStore";
import { ConnectedRouter } from "connected-react-router";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import AppRoutes from "./AppRoutes";
import theme from "../theme";
import AlertSnackbar from "../components/snackbar";

const AppContainer = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CssBaseline />
            <AppRoutes />
            <AlertSnackbar />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default AppContainer;
