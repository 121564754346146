import requestHandler from "../../../../services/api/requestHandler";

import {
  RETRIEVE_PASSPORT_QUESTIONNAIRE_API,
  SUBMIT_PASSPORT_QUESTIONNAIRE_API
} from "../../../../config/apiUrls.js";

import {
  PASSPORT_QUESTIONNAIRE,
  SUBMIT_PASSPORT_QUESTIONNAIRE,
  RESET_PASSPORT_QUESTIONNAIRE
} from "./constants";

export function retrievePassportQuestionnaire(params) {
  return requestHandler(
    PASSPORT_QUESTIONNAIRE,
    RETRIEVE_PASSPORT_QUESTIONNAIRE_API,
    "GET",
    { params }
  );
}

export function submitPassportQuestionnaire(data) {
  return requestHandler(
    SUBMIT_PASSPORT_QUESTIONNAIRE,
    SUBMIT_PASSPORT_QUESTIONNAIRE_API,
    "POST",
    { data }
  );
}

export function resetPassportQuestionnaire() {
  return { type: RESET_PASSPORT_QUESTIONNAIRE };
}
