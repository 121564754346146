import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { push } from "connected-react-router";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Assessment from "@material-ui/icons/AssessmentOutlined";
import Paper from "@material-ui/core/Paper";
import { setHeader } from "../../../components/appBar/data/actions";
import { styles } from "./styles";
import EmptyListing from "../../../components/emptyListing";
import { showAlert } from "../../../components/snackbar/data/actions";
import CircularIndeterminate from "../../../components/circular/CircularIndeterminate";
import getUsertype from "../../../services/getUsertype";
import appUrls from "../../../config/appUrls";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import validator from "../../../services/validator/";
import { find } from "lodash";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActionsWrapped } from "../../../components/pagination/";
import Hidden from "@material-ui/core/Hidden";
import {
  getMasterFVOList,
  getMasterStationList
} from "../../../data/staticApi/master/actions";
import { resetDailyReportProps, getDailyReport } from "./data/actions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

class DailyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      searchData: {
        policeStation: "",
        officer: ""
      },
      errors: {},
      rules: {}
    };
  }

  componentDidMount() {
    if (getUsertype(this.props.userInfo.user_type) === "DYSP") {
      this.props.getMasterStationList();
      this.props.setHeader({ header: "Daily Report" });
    } else if (getUsertype(this.props.userInfo.user_type) === "CUSTOMER") {
      this.props.navigateTo(appUrls.TRACKING_USER);
    } else {
      this.props.navigateTo("/not-found");
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      let messageInfo = {
        message: this.props.error,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
  }

  componentWillUnmount() {
    this.props.resetProps();
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      let params = {
        per_page: this.state.rowsPerPage,
        page_no: this.state.page + 1,
        police_station: this.state.searchData.policeStation,
        fvo: this.state.searchData.officer
      };
      this.props.getDailyReport(params);
    });
  };

  onSearchClick = () => {
    if (this.validate(this.state.rules, this.state)) {
      let params = {
        per_page: this.state.rowsPerPage,
        page_no: this.state.page + 1,
        police_station: this.state.searchData.policeStation,
        fvo: this.state.searchData.officer
      };
      this.props.getDailyReport(params);
    }
  };

  onClearAllClick = () => {
    this.props.resetProps();
    this.setState({
      page: 0,
      rowsPerPage: 10,
      searchData: {
        country: "",
        state: "",
        district: "",
        policeStation: "",
        officer: ""
      },
      errors: {},
      rules: {}
    });
  };

  stationDropDownChange = event => {
    this.setState(
      {
        ...this.state,
        searchData: {
          ...this.state.searchData,
          policeStation: event.target.value,
          officer: ""
        }
      },
      () => {
        if (event.target.value !== "") {
          let params = {
            station_name: this.state.searchData.policeStation
          };
          this.props.getMasterFVOList(params);
        }
      }
    );
  };

  fvoDropDownChange = event => {
    this.setState({
      ...this.state,
      searchData: {
        ...this.state.searchData,
        officer: event.target.value
      }
    });
  };

  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, error => error !== "");
    this.setState({ errors });
    return !hasErrors;
  };

  render() {
    const { classes, dailyReportData } = this.props;
    const { page, rowsPerPage } = this.state;
    return (
      <>
        <Grid className={classes.root}>
          <Grid justify="center" container>
            <Grid item xs={12} md={8}>
              <Grid item xs={12}>
                {!dailyReportData ? (
                  <EmptyListing emptyText="Click Generate to view Daily Report" />
                ) : null}
              </Grid>
              <Grid item className={classes.cellgrid}>
                <Paper className={classes.paper}>
                  <Grid container spacing={24}>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.dropDown}>
                        <InputLabel htmlFor="police-station">
                          Police Station
                        </InputLabel>
                        <Select
                          inputProps={{
                            name: "station",
                            id: ""
                          }}
                          value={this.state.searchData.policeStation}
                          onChange={this.stationDropDownChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {this.props.stationData.map(station => (
                            <MenuItem value={station.name}>
                              {station.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.dropDown}>
                        <InputLabel htmlFor="officer">Officer</InputLabel>
                        <Select
                          inputProps={{
                            name: "officer",
                            id: ""
                          }}
                          value={this.state.searchData.officer}
                          onChange={this.fvoDropDownChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {this.props.fvoData.map(fvo => (
                            <MenuItem value={fvo.id}>{fvo.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.buttonsGrid}>
                    <Grid item xs={5} sm={4} md={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        fullWidth
                        onClick={e => {
                          e.preventDefault();
                          this.onSearchClick();
                        }}
                      >
                        <Assessment className={classes.leftIcon} />
                        Generate
                      </Button>
                    </Grid>
                    <Grid item xs={5} sm={4} md={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        fullWidth
                        onClick={e => {
                          e.preventDefault();
                          this.onClearAllClick();
                        }}
                      >
                        <CloseIcon
                          variant="contained"
                          className={classes.leftIcon}
                        />
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          {dailyReportData ? (
            <Paper>
              <Table size="sm">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Sl No.</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="center">Count</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0} className={classes.tableRow}>
                    <StyledTableCell key={0} align="center">
                      1
                    </StyledTableCell>
                    <StyledTableCell key={1} align="left">
                      Opening Balance
                    </StyledTableCell>
                    <StyledTableCell key={2} align="center">
                      {dailyReportData.opening_balance}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow key={1} className={classes.tableRow}>
                    <StyledTableCell key={0} align="center">
                      2
                    </StyledTableCell>
                    <StyledTableCell key={1} align="left">
                      Applications Uploaded
                    </StyledTableCell>
                    <StyledTableCell key={2} align="center">
                      {dailyReportData.applications}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow key={2} className={classes.tableRow}>
                    <StyledTableCell key={0} align="center">
                      3
                    </StyledTableCell>
                    <StyledTableCell key={1} align="left">
                      Application Cleared
                    </StyledTableCell>
                    <StyledTableCell key={2} align="center">
                      {dailyReportData.applications_cleared}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow key={3} className={classes.tableRow}>
                    <StyledTableCell key={0} align="center">
                      4
                    </StyledTableCell>
                    <StyledTableCell key={1} align="left">
                      Application Pending
                    </StyledTableCell>
                    <StyledTableCell key={2} align="center">
                      {dailyReportData.applications_pending}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          ) : null}
          <Grid item xs={12}>
            {this.props.isLoading ? <CircularIndeterminate /> : null}
          </Grid>
        </Grid>
      </>
    );
  }
}

DailyReport.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    stationData: state.masterData.stationListData,
    fvoData: state.masterData.fvoListData,
    dailyReportData: state.dailyReport.dailyReportData,
    error: state.dailyReport.error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    setHeader: data => dispatch(setHeader(data)),
    navigateTo: url => dispatch(push(url)),
    getMasterStationList: () => dispatch(getMasterStationList()),
    getMasterFVOList: params => dispatch(getMasterFVOList(params)),
    resetProps: () => dispatch(resetDailyReportProps()),
    getDailyReport: params => dispatch(getDailyReport(params))
  };
}

export const styledDailyReport = withStyles(styles)(DailyReport);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledDailyReport);
