export const RETRIEVE_CCTNS_RESULTS = "RETRIEVE_CCTNS_RESULTS";
export const RETRIEVE_PASSPORT_DETAILS = "RETRIEVE_PASSPORT_DETAILS";
export const FORWARD_CCTNS_RESULTS = "FORWARD_CCTNS_RESULTS";
export const RESET_CCTNS_RESULTS_PROPS = "RESET_CCTNS_RESULTS_PROPS";
export const PASSPORT_FIR_SECTIONS_DETAILS = "PASSPORT_FIR_SECTIONS_DETAILS";
export const PASSPORT_ADDRESSES_DETAILS = "PASSPORT_ADDRESSES_DETAILS";
export const RETRIEVE_CCTNS_COUNTRY = "RETRIEVE_CCTNS_COUNTRY";
export const RETRIEVE_CCTNS_STATE = "RETRIEVE_CCTNS_STATE";
export const RETRIEVE_CCTNS_DISTRICT = "RETRIEVE_CCTNS_DISTRICT";
export const RETRIEVE_CCTNS_STATION = "RETRIEVE_CCTNS_STATION";
