import React from "react";

export function Logout(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="-41 -21 682 682"
      xmlSpace="preserve"
      width="24px"
      height="24px"
    >
      <g>
        <g>
          <g>
            <path
              fill="#757575"
              d="m458.90625 412.792969c-6.097656-3.257813-9.90625-9.609375-9.90625-16.53125v-18.75h-131.25c-10.359375 0-18.75-8.386719-18.75-18.75v-75c0-10.363281 8.390625-18.75 18.75-18.75h131.25v-18.75c0-6.925781 3.808594-13.277344 9.90625-16.535157 6.082031-3.242187 13.496094-2.875 19.25.933594l112.5 75c5.21875 3.476563 8.347656 9.335938 8.347656 15.601563 0 6.257812-3.128906 12.117187-8.347656 15.597656l-112.5 75c-5.816406 3.878906-13.234375 4.160156-19.25.933594zm0 0"
            />
            <path
              fill="#757575"
              d="m411.5 18.761719v208.75h-93.75c-6.746094 0-12.75 1.121093-18.75 3.371093v-99.621093c0-21.003907-11.625-40.125-31.121094-50.25l-161.628906-81.0000002h286.5c10.503906 0 18.75 8.2460932 18.75 18.7500002zm0 0"
            />
            <path
              fill="#757575"
              d="m317.75 415.011719h93.75v131.246093c0 10.503907-8.246094 18.75-18.75 18.75h-93.75v-153.371093c6 2.25 12.003906 3.375 18.75 3.375zm0 0"
            />
            <path
              fill="#757575"
              d="m251.003906 114.386719-225.003906-112.5c-5.621094-2.628907-12.75-2.628907-18 .75-5.621094 3.746093-9 9.746093-9 16.125v527.496093c0 8.253907 5.25 15.378907 12.753906 17.628907l225 75c5.523438 2.210937 12.59375.960937 16.871094-2.25 4.878906-3.75 7.878906-9.375 7.878906-15.371094v-490.003906c-.003906-7.125-4.125-13.503907-10.5-16.875zm-64.503906 244.375c0 10.496093-8.246094 18.75-18.75 18.75-10.496094 0-18.75-8.253907-18.75-18.75v-75c0-10.5 8.253906-18.75 18.75-18.75 10.503906 0 18.75 8.25 18.75 18.75zm0 0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
