const LOGIN = "/login";
const DASHBOARD = "/dashboard";
const REGISTER = "/register";
const HOME = "/home";
const PCC = "/apply-pcc";
const TRACKING_USER = "/track-applications";
const NOC = "/apply-noc";
const HISTORY = "/applications-history";
const PCC_REQUESTS = "/pcc-requests";
const NOC_REQUESTS = "/noc-requests";
const PASSPORT_REQUESTS = "/passport-requests";
const CCTNS_RESULTS = "/cctns";
const PASSPORT_DATA_EXPORT = "/passport-data-export";
const DISCLAIMER = "/disclaimer";
const DAILY_REPORT = "/daily-report";
const APPLICATION_REPORT = "/application-age-report";

export default {
  LOGIN,
  DASHBOARD,
  REGISTER,
  HOME,
  PCC,
  NOC,
  HISTORY,
  TRACKING_USER,
  NOC,
  PCC_REQUESTS,
  PASSPORT_REQUESTS,
  PASSPORT_DATA_EXPORT,
  NOC_REQUESTS,
  PASSPORT_REQUESTS,
  CCTNS_RESULTS,
  DISCLAIMER,
  DAILY_REPORT,
  APPLICATION_REPORT
};
