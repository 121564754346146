import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { outboundRequestAction, resetProps } from "./data/actions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { find } from "lodash";
import validator from "../../../services/validator";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachFile from "@material-ui/icons/AttachFile";
import CircularIndeterminate from "../../../components/circular/CircularIndeterminate";
import dropdownData from "../../../constants";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

class OutboundRequestDialog extends Component {
  state = {
    commissioner: {
      dsboOfficer: "",
      dcrbOfficer: "",
      applicationId: ""
    },
    dcrbcpo: {
      crime: "",
      jurisdiction: "",
      law: ""
    },
    dsbofvo: {
      chosen_file: "",
      chosen_file_name: "",
      remarks: ""
    },
    errors: {
      crime: "",
      jurisdiction: "",
      law: "",
      remarks: "",
      chosen_file: "",
      chosen_file_name: "",
      dsboOfficer: "",
      dcrbOfficer: ""
    },
    dcrbcpoRules: {
      crime: {
        required: true
      },
      jurisdiction: {
        required: true
      },
      law: {
        required: true
      }
    },
    dsboRules: {
      chosen_file: {
        required: true
      },
      chosen_file_name: {
        required: true
      },
      remarks: {
        required: true
      }
    },
    commissionerRules: {
      dsboOfficer: {
        required: true
      },
      dcrbOfficer: {
        required: true
      }
    }
  };

  showOutboundDialog = (
    classes,
    fullScreen,
    isOpen,
    applicationNumber,
    userType
  ) => {
    switch (userType) {
      case 1:
        return (
          <Grid item sm={12} md={8} xs={12}>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={this.props.isOpen}
                disableBackdropClick="true"
                scroll="body"
              >
                <DialogTitle id="responsive-dialog-title">
                  PCC-{this.props.applicationNumber}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          style={{ marginTop: 16, marginBottom: 0 }}
                          variant="button"
                          color="primary"
                        >
                          Criminal Verification
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          error={this.state.errors.dcrbOfficer ? true : false}
                          fullWidth
                        >
                          <InputLabel htmlFor="dcrb-officer">
                            DCRB Officer
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: "dcrbOfficer",
                              id: "dcrb-officer"
                            }}
                            onChange={e => {
                              e.preventDefault();
                              this.handleCommissionerChange(e);
                            }}
                            value={this.state.commissioner.dcrbOfficer}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {dropdownData.commisionerDcrbTypes.map(item => (
                              <MenuItem value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {this.state.errors.dcrbOfficer}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          style={{ marginTop: 16, marginBottom: 0 }}
                          variant="button"
                          color="primary"
                        >
                          Physical Verification
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          error={this.state.errors.dsboOfficer ? true : false}
                          fullWidth
                        >
                          <InputLabel htmlFor="dsbo-officer">
                            DSBO Officer
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: "dsboOfficer",
                              id: "dsbo-officer"
                            }}
                            onChange={e => {
                              e.preventDefault();
                              this.handleCommissionerChange(e);
                            }}
                            value={this.state.commissioner.dsboOfficer}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {this.props.userInfo.user_type === 1
                              ? dropdownData.commisionerDsboTypes.map(item => (
                                  <MenuItem value={item.value}>
                                    {item.name}
                                  </MenuItem>
                                ))
                              : dropdownData.shoDsboTypes.map(item => (
                                  <MenuItem value={item.value}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                          </Select>
                          <FormHelperText>
                            {this.state.errors.dsboOfficer}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      className={classes.footerButtons}
                      item
                      xs={12}
                      md={12}
                    >
                      <div className={classes.buttons}>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            this.handleOutboundDialogClose(e);
                          }}
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={this.props.isLoading ? true : false}
                          variant="contained"
                          color="primary"
                          onClick={e => {
                            e.preventDefault();
                            this.onSubmitHandler();
                          }}
                          className={classes.button}
                        >
                          Assign
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                {this.props.isLoading ? <CircularIndeterminate /> : null}
              </Dialog>
            </div>
          </Grid>
        );
      case 2:
        return (
          <Grid item sm={12} md={8} xs={12}>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                disableBackdropClick="true"
                scroll="body"
              >
                <DialogTitle id="responsive-dialog-title">
                  PCC-{applicationNumber}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        Criminal verification done by DCRB CPO.
                      </Typography>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={4} md={4}>
                      <div className={classes.cancelButton}>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            this.handleOutboundDialogClose();
                          }}
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={8} md={8}>
                      <div className={classes.buttons}>
                        <Button
                          disabled={this.props.isLoading ? true : false}
                          variant="contained"
                          color="primary"
                          onClick={e => {
                            e.preventDefault();
                            this.onSubmitHandler();

                            // this.handleOutboundDialogClose();
                          }}
                          className={classes.button}
                        >
                          Submit
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                {this.props.isLoading ? <CircularIndeterminate /> : null}
              </Dialog>
            </div>
          </Grid>
        );
      case 3:
        return (
          <Grid item sm={12} md={8} xs={12}>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                disableBackdropClick="true"
                scroll="body"
              >
                <DialogTitle id="responsive-dialog-title">
                  PCC-{applicationNumber}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <Typography
                        style={{ marginTop: 16, marginBottom: 0 }}
                        variant="button"
                        color="primary"
                      >
                        Criminal Verification
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          id="crime"
                          name="crime"
                          value={this.state.dcrbcpo.crime}
                          onChange={e => {
                            e.preventDefault();
                            this.handleDCRBChange(e);
                          }}
                          label="Crime"
                          fullWidth
                        />
                        {this.state.errors.crime ? (
                          <FormHelperText>
                            {this.state.errors.crime}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          id="jurisdiction"
                          name="jurisdiction"
                          value={this.state.dcrbcpo.jurisdiction}
                          onChange={e => {
                            e.preventDefault();
                            this.handleDCRBChange(e);
                          }}
                          label="Jurisdiction"
                          fullWidth
                        />
                        {this.state.errors.jurisdiction ? (
                          <FormHelperText>
                            {this.state.errors.jurisdiction}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          id="law"
                          name="law"
                          value={this.state.dcrbcpo.law}
                          onChange={e => {
                            e.preventDefault();
                            this.handleDCRBChange(e);
                          }}
                          label="Law"
                          fullWidth
                        />
                        {this.state.errors.law ? (
                          <FormHelperText>
                            {this.state.errors.law}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={4} md={4}>
                      <div className={classes.cancelButton}>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            this.handleOutboundDialogClose();
                          }}
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={8} md={8}>
                      <div className={classes.buttons}>
                        <Button
                          disabled={this.props.isLoading ? true : false}
                          variant="contained"
                          color="primary"
                          onClick={e => {
                            e.preventDefault();
                            this.onSubmitHandler();
                          }}
                          className={classes.button}
                        >
                          Submit
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                {this.props.isLoading ? <CircularIndeterminate /> : null}
              </Dialog>
            </div>
          </Grid>
        );
      case 4:
        return (
          <Grid item sm={12} md={8} xs={12}>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                disableBackdropClick="true"
                scroll="body"
              >
                <DialogTitle id="responsive-dialog-title">
                  PCC-{applicationNumber}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={24}>
                    <Grid item xs={6}>
                      <Typography
                        variant="button"
                        style={{
                          marginTop: 10,
                          marginBottom: 0
                        }}
                      >
                        Upload Verification Image
                      </Typography>
                    </Grid>
                    {this.state.dsbofvo.chosen_file ? (
                      <Grid item xs={6} style={{ textAlign: "end" }}>
                        <Button color="primary">
                          <CheckCircle />
                        </Button>
                        <Button
                          component="span"
                          color="primary"
                          aria-label="upload"
                          onClick={e => {
                            e.preventDefault();
                            this.removeFile(e);
                          }}
                        >
                          <DeleteIcon />
                          Remove
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item xs={6} style={{ textAlign: "end" }}>
                        <input
                          accept="image/*"
                          id="upload-user-image-fab"
                          style={{ display: "none" }}
                          multiple
                          type="file"
                          onChange={e => {
                            e.preventDefault();
                            this.onFileLoad(e);
                          }}
                        />
                        <label htmlFor="upload-user-image-fab">
                          <Button
                            component="span"
                            color="primary"
                            aria-label="upload"
                          >
                            <AttachFile />
                            Attach
                          </Button>
                        </label>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          multiline
                          id="remarks"
                          name="remarks"
                          onChange={e => {
                            e.preventDefault();
                            this.handleDSBOChange(e);
                          }}
                          value={this.state.dsbofvo.remarks}
                          label="Remarks"
                          fullWidth
                        />
                        {this.state.errors.remarks ? (
                          <FormHelperText>
                            {this.state.errors.remarks}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={4} md={4}>
                      <div className={classes.cancelButton}>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            this.handleOutboundDialogClose();
                          }}
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={8} md={8}>
                      <div className={classes.buttons}>
                        <Button
                          disabled={this.props.isLoading ? true : false}
                          variant="contained"
                          color="primary"
                          onClick={e => {
                            e.preventDefault();
                            this.onSubmitHandler();
                          }}
                          className={classes.button}
                        >
                          Submit
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                {this.props.isLoading ? <CircularIndeterminate /> : null}
              </Dialog>
            </div>
          </Grid>
        );
      case 5:
      case 6:
        return (
          <Grid item sm={12} md={8} xs={12}>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                disableBackdropClick="true"
                scroll="body"
              >
                <DialogTitle id="responsive-dialog-title">
                  PCC-{applicationNumber}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={24}>
                    <Grid item xs={6}>
                      <Typography
                        variant="button"
                        style={{
                          marginTop: 10,
                          marginBottom: 0
                        }}
                      >
                        Upload Verification Image
                      </Typography>
                    </Grid>
                    {this.state.dsbofvo.chosen_file ? (
                      <Grid item xs={6} style={{ textAlign: "end" }}>
                        <Button color="primary">
                          <CheckCircle />
                        </Button>
                        <Button
                          component="span"
                          color="primary"
                          aria-label="upload"
                          onClick={e => {
                            e.preventDefault();
                            this.removeFile(e);
                          }}
                        >
                          <DeleteIcon />
                          Remove
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item xs={6} style={{ textAlign: "end" }}>
                        <input
                          accept="image/*"
                          id="upload-user-image-fab"
                          style={{ display: "none" }}
                          multiple
                          type="file"
                          onChange={e => {
                            e.preventDefault();
                            this.onFileLoad(e);
                          }}
                        />
                        <label htmlFor="upload-user-image-fab">
                          <Button
                            component="span"
                            color="primary"
                            aria-label="upload"
                          >
                            <AttachFile />
                            Attach
                          </Button>
                        </label>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormControl error fullWidth>
                        <TextField
                          required
                          multiline
                          id="remarks"
                          name="remarks"
                          onChange={e => {
                            e.preventDefault();
                            this.handleDSBOChange(e);
                          }}
                          value={this.state.dsbofvo.remarks}
                          label="Remarks"
                          fullWidth
                        />
                        {this.state.errors.remarks ? (
                          <FormHelperText>
                            {this.state.errors.remarks}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={4} md={4}>
                      <div className={classes.cancelButton}>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            this.handleOutboundDialogClose();
                          }}
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                    <Grid className={classes.footerButtons} item xs={8} md={8}>
                      <div className={classes.buttons}>
                        <Button
                          disabled={this.props.isLoading ? true : false}
                          variant="contained"
                          color="primary"
                          onClick={e => {
                            e.preventDefault();
                            this.onSubmitHandler();
                          }}
                          className={classes.button}
                        >
                          Submit
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                {this.props.isLoading ? <CircularIndeterminate /> : null}
              </Dialog>
            </div>
          </Grid>
        );
      default:
        return "";
    }
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  resetStates = () => {
    this.setState({
      dcrbcpo: {
        crime: "",
        jurisdiction: "",
        law: ""
      },
      dsbofvo: {
        chosen_file: "",
        chosen_file_name: "",
        remarks: ""
      },
      errors: {
        crime: "",
        jurisdiction: "",
        law: "",
        remarks: "",
        chosen_file: "",
        chosen_file_name: ""
      }
    });
  };

  onFileLoad = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.setState({
        dsbofvo: {
          ...this.state.dsbofvo,
          chosen_file: file,
          chosen_file_name: file.name
        }
      });
    }
  };

  removeFile = e => {
    this.setState({
      ...this.state,
      dsbofvo: {
        ...this.state.dsbofvo,
        chosen_file: "",
        chosen_file_name: ""
      }
    });
  };

  validateImageField() {
    if (this.state.dsbofvo.chosen_file === "") {
      let messageInfo = {
        message: "Please select a valid image file!",
        variant: "warning"
      };
      this.props.showAlert(messageInfo);
      return false;
    }
    const { chosen_file: file } = this.state.dsbofvo;

    const regex = new RegExp("^(image)");
    if (!file) return true;
    if (regex.test(file.type)) {
      if (file.size < 2097152) {
        // image should be grater than 2MB
        return true;
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            chosen_file_name: "Image should be less than 2MB"
          }
        });
        return false;
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          chosen_file_name: "Invalid file type"
        }
      });
      return false;
    }
  }

  handleDCRBChange = event => {
    this.setState({
      dcrbcpo: {
        ...this.state.dcrbcpo,
        [event.target.name]: event.target.value
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  handleCommissionerChange = event => {
    this.setState({
      commissioner: {
        ...this.state.commissioner,
        [event.target.name]: event.target.value
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  handleDSBOChange = event => {
    this.setState({
      dsbofvo: {
        ...this.state.dsbofvo,
        [event.target.name]: event.target.value
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  onSubmitHandler = () => {
    if (this.props.userInfo.user_type === 1) {
      const data = {
        is_assigned_to_dcrbcpo: true,
        is_assigned_to_dsbosho: true,
        is_assigned_to_dsbofvo: false,
        is_varified: false
      };
      if (this.validate(this.state.rules, this.state.data)) {
        this.props.outboundRequestAction(this.props.appliationId, data);
      }
    } else if (this.props.userInfo.user_type === 2) {
      const data = {
        is_assigned_to_dcrbcpo: false,
        is_assigned_to_dsbosho: false,
        is_assigned_to_dsbofvo: true,
        is_varified: false
      };
      this.props.outboundRequestAction(this.props.appliationId, data);
    } else if (this.props.userInfo.user_type === 3) {
      const data = {
        is_varified: false,
        crime: this.state.dcrbcpo.crime,
        jurisdiction: this.state.dcrbcpo.jurisdiction,
        law: this.state.dcrbcpo.law
      };
      if (
        this.state.dcrbcpo.crime !== "" ||
        this.state.dcrbcpo.jurisdiction !== "" ||
        this.state.dcrbcpo.law !== ""
      ) {
        if (this.validate(this.state.dcrbcpoRules, this.state.dcrbcpo)) {
          this.props.outboundRequestAction(this.props.appliationId, data);
        }
      } else {
        this.props.outboundRequestAction(this.props.appliationId, data);
      }
    } else if (this.props.userInfo.user_type === 4) {
      if (this.validateImageField()) {
        const data = new FormData();
        const regex = new RegExp("^(image)");
        if (
          this.state.dsbofvo.chosen_file &&
          regex.test(this.state.dsbofvo.chosen_file.type)
        ) {
          data.append("chosen_image", this.state.dsbofvo.chosen_file);
          data.append("is_varified", true);
          data.append("remarks", this.state.dsbofvo.remarks);
          if (this.validate(this.state.dsboRules, this.state.dsbofvo)) {
            this.props.outboundRequestAction(this.props.appliationId, data);
          }
        }
      }
    }
    if (
      this.props.userInfo.user_type === 5 ||
      this.props.userInfo.user_type === 6
    ) {
      if (!this.props.isApprove) {
        if (this.validateImageField()) {
          const data = new FormData();
          const regex = new RegExp("^(image)");
          if (
            this.state.dsbofvo.chosen_file &&
            regex.test(this.state.dsbofvo.chosen_file.type)
          ) {
            data.append("chosen_image", this.state.dsbofvo.chosen_file);
            data.append("is_varified", true);
            data.append("is_approved", this.props.isApprove);
            data.append("remarks", this.state.dsbofvo.remarks);
            if (this.validate(this.state.dsboRules, this.state.dsbofvo)) {
              this.props.outboundRequestAction(this.props.appliationId, data);
            }
          }
        }
      } else {
        const data = new FormData();
        data.append("chosen_image", this.state.dsbofvo.chosen_file);
        data.append("is_varified", true);
        data.append("is_approved", this.props.isApprove);
        data.append("remarks", this.state.dsbofvo.remarks);
        this.props.outboundRequestAction(this.props.appliationId, data);
      }
    }
  };

  handleOutboundDialogClose = () => {
    this.resetStates();
    this.props.handleOutboundDialogClose();
  };

  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, error => error !== "");

    this.setState({ errors });
    return !hasErrors;
  };

  render() {
    const {
      classes,
      fullScreen,
      isOpen,
      applicationNumber,
      userInfo
    } = this.props;
    return (
      <Grid justify="center" container>
        {this.showOutboundDialog(
          classes,
          fullScreen,
          isOpen,
          applicationNumber,
          userInfo.user_type
        )}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    isLoading: state.pccOutboundRequestDialog.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    outboundRequestAction: (id, data) =>
      dispatch(outboundRequestAction(id, data))
  };
}

export const styledOutboundRequestDialog = withStyles(styles)(
  withMobileDialog()(OutboundRequestDialog)
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledOutboundRequestDialog);
