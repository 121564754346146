import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { setTabs, resetTabs } from "../../components/appbarTabs/data/actions";
import { setHeader } from "../../components/appBar/data/actions";
import TrackPCCApplications from "./trackPCCApplications";
import TrackNOCApplications from "./trackNOCApplications";
import getUsertype from "../../services/getUsertype";
import appUrls from "../../config/appUrls";
import { resetProps } from "./data/actions";

class TrackApplications extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.setHeader({ header: "Track Applications" });

    if (getUsertype(this.props.userType) !== "CUSTOMER") {
      this.props.navigateTo(appUrls.PCC_REQUESTS);
    }

    let tabs = [{ name: "PCC" }, { name: "NOC" }];
    this.props.setTabs({ tabs });
  }

  componentWillUnmount() {
    this.props.resetTabs();
    this.props.resetProps();
  }

  render() {
    const { currentTab } = this.props;
    return (
      <Fragment>
        <Grid>
          {currentTab === 0 && <TrackPCCApplications />}
          {currentTab === 1 && <TrackNOCApplications />}
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentTab: state.tabs.activeTab,
    userType: state.auth.info.user_type
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTabs: tabs => dispatch(setTabs(tabs)),
    resetTabs: () => dispatch(resetTabs()),
    setHeader: data => dispatch(setHeader(data)),
    resetProps: () => dispatch(resetProps())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackApplications);
