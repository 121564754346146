import requestHandler from "../../../services/api/requestHandler";

import { PASSPORT_DATA_EXPORT_API } from "../../../config/apiUrls.js";

import { PASSPORT_DATA_EXPORT, RESET_PROP } from "./constants";

export function exportData(data) {
  return requestHandler(
    PASSPORT_DATA_EXPORT,
    PASSPORT_DATA_EXPORT_API,
    "POST",
    { data }
  );
}

export function resetProps() {
  return { type: RESET_PROP };
}
