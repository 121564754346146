export const PCC_USER_TRACKING_LOG = "PCC_USER_TRACKING_LOG";
export const NOC_USER_TRACKING_LOG = "NOC_USER_TRACKING_LOG";
export const PCC_USER_TRACKING_LIST = "PCC_USER_TRACKING_LIST";
export const NOC_USER_TRACKING_LIST = "NOC_USER_TRACKING_LIST";
export const PCC_CERTIFICATE_VIEW = "PCC_CERTIFICATE_VIEW";
export const NOC_CERTIFICATE_VIEW = "NOC_CERTIFICATE_VIEW";
export const RESET_USER_TRACKING_LOG = "RESET_USER_TRACKING_LOG";
export const PCC_HASHCODE_VIEW = "PCC_HASHCODE_VIEW";
export const NOC_HASHCODE_VIEW = "NOC_HASHCODE_VIEW";
export const RESET_USER_TRACKING_VIEW_HASH = "RESET_USER_TRACKING_VIEW_HASH";
export const RESET_USER_TRACKING_PROPS = "RESET_USER_TRACKING_PROPS";
