import requestHandler from "../../../../services/api/requestHandler";

import { RETRIEVE_PCC_API, NOC_API } from "../../../../config/apiUrls.js";

import {
  LIST_NOC_INBOUND_REQUESTS,
  LIST_NOC_OUTBOUND_REQUEST,
  RETRIEVE_NOC,
  RESET_NOC_REQUESTS_PROPS
} from "./constants";

export function listNOCInboundRequests(params) {
  return requestHandler(LIST_NOC_INBOUND_REQUESTS, NOC_API, "GET", {
    params
  });
}

export function listNOCOutboundRequests(params) {
  return requestHandler(LIST_NOC_OUTBOUND_REQUEST, NOC_API, "GET", { params });
}

export function retrieveNOCRequest(id) {
  return requestHandler(RETRIEVE_NOC, `${RETRIEVE_PCC_API}${id}/`, "GET", {});
}

export function resetProps() {
  return { type: RESET_NOC_REQUESTS_PROPS };
}
