export const styles = theme => ({
  root: {
    width: "100%",
    padding: "4px",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 1
    },
    overflowX: "auto"
  },
  cellgrid: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    paddingBottom: theme.spacing.unit * 2
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  dropDown: {
    marginTop: "5px"
  },
  tablePagination: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  buttonsGrid: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
});
