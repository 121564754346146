import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import { styles } from "./styles";
import EmptyListing from "../../../components/emptyListing";
import {
  retrievePassportDetails,
  retrieveCCTNSResults,
  retrieveAddresses,
  retrieveFIRSections,
  forwardCCTNSResults,
  resetProps,
  retrieveCountryData,
  retrieveStateData,
  retrieveDistrictData,
  retrieveStationData
} from "./data/actions";
import { showAlert } from "../../../components/snackbar/data/actions";
import {
  setHeader,
  setBackURL,
  resetAppbar
} from "../../../components/appBar/data/actions";
import { push } from "connected-react-router";
import appUrls from "../../../config/appUrls";
import Checkbox from "@material-ui/core/Checkbox";
import CircularIndeterminate from "../../../components/circular/CircularIndeterminate";
import Fab from "@material-ui/core/Fab";
import Send from "@material-ui/icons/Send";
import AlertDialog from "../../../components/Dialogs";
import TextField from "@material-ui/core/TextField";
import { Person, PersonPin } from "@material-ui/icons";

class CCTNSResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      isNothingSelected: true,
      isOpen: false,
      accusedOtherAddresses: [],
      FIRActSection: [],
      searchData: {
        firstName: "",
        lastName: "",
        relativeName: "",
        uid: "",
        policeStation: "",
        houseNumber: "",
        streetName: "",
        colonyLocalArea: "",
        villageTownCity: "",
        tehsilBlockMandal: "",
        country: "",
        state: "",
        district: "",
        pincode: ""
      }
    };
  }
  componentDidMount() {
    if (this.props.userInfo.user_type === 3) {
      this.props.setHeader({ header: "CCTNS Results" });
      this.props.setBackURL({ url: appUrls.PASSPORT_REQUESTS });
      this.props.retrievePassportDetails(this.props.match.params.applicationID);
      this.props.retrieveCountryData();
    } else if (this.props.userInfo.user_type === 7) {
      this.props.navigateTo(appUrls.TRACKING);
    } else {
      this.props.navigateTo("/not-found");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && this.props.error !== prevProps.error) {
      let messageInfo = {
        message: this.props.error,
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
    if (
      this.props.forwardSuccess &&
      this.props.forwardSuccess !== prevProps.forwardSuccess
    ) {
      let messageInfo = {
        message: this.props.forwardMessage + " " + this.props.transactionHash,
        variant: "success"
      };
      this.props.showAlert(messageInfo);
      this.props.navigateTo(appUrls.PASSPORT_REQUESTS);
    }
    if (
      this.props.retrieveAddressesData &&
      this.props.retrieveAddressesData !== prevProps.retrieveAddressesData
    ) {
      if (this.props.retrieveAddressesData.AccusedAddress.length === 0) {
        let messageInfo = {
          message: "No records found",
          variant: "error"
        };
        this.props.showAlert(messageInfo);
      }
      let values = [...this.state.accusedOtherAddresses];
      values.push(this.props.retrieveAddressesData);
      this.setState({
        accusedOtherAddresses: values
      });
    }
    if (
      this.props.retrieveFIRSectionsData &&
      this.props.retrieveFIRSectionsData !== prevProps.retrieveFIRSectionsData
    ) {
      if (this.props.retrieveFIRSectionsData.FIRActSectionDetail.length === 0) {
        let messageInfo = {
          message: "No records found",
          variant: "error"
        };
        this.props.showAlert(messageInfo);
      }
      let values = [...this.state.FIRActSection];
      values.push(this.props.retrieveFIRSectionsData);
      this.setState({
        FIRActSection: values
      });
    }

    if (
      this.props.retrieveCCTNSResultsData &&
      this.props.retrieveCCTNSResultsData.Accused &&
      this.props.retrieveCCTNSResultsData.Accused !==
      prevProps.retrieveCCTNSResultsData.Accused &&
      this.props.retrieveCCTNSResultsData.Accused.length === 0
    ) {
      let messageInfo = {
        message: "No records found",
        variant: "error"
      };
      this.props.showAlert(messageInfo);
    }
  }

  componentWillUnmount() {
    this.props.resetProps();
    this.props.resetAppbar();
  }

  handleChange = event => {
    this.setState({
      searchData: {
        ...this.state.searchData,
        [event.target.name]: event.target.value
      }
    });
  };

  handleCheckChange = (i, item, isChecked) => {
    if (isChecked) {
      let values = [...this.state.values];
      let accusedOtherAddresses = [];
      for (let i = 0; i < this.state.accusedOtherAddresses.length; i++) {
        if (
          this.state.accusedOtherAddresses[i].accused_srno === item.accused_srno
        ) {
          accusedOtherAddresses = this.state.accusedOtherAddresses[i]
            .AccusedAddress;
          break;
        }
      }
      let FIRActSection = [];
      for (let i = 0; i < this.state.FIRActSection.length; i++) {
        if (this.state.FIRActSection[i].FIR_REG_NUM === item.FIR_REG_NUM) {
          FIRActSection = this.state.FIRActSection[i].FIRActSectionDetail;
          break;
        }
      }
      if (accusedOtherAddresses.length === 0) {
        accusedOtherAddresses.push({
          addressType: "Present Address",
          houseNo: item.presentHouseNo,
          streetName: item.presentStreetName,
          colonyLocalArea: item.presentColonyLocalArea,
          villageTownCity: item.presentVillageTownCity,
          tehsilBlockMandal: item.presentTehsilBlockMandal,
          country: item.presentCountry,
          state: item.presentState,
          district: item.presentDistrict,
          pinCode: item.presentPinCode,
          policeStation: item.presentPoliceStation,
          isCurrent: "true"
        });
      } else {
        accusedOtherAddresses.push({
          addressType: "Present Address",
          houseNo: item.presentHouseNo,
          streetName: item.presentStreetName,
          colonyLocalArea: item.presentColonyLocalArea,
          villageTownCity: item.presentVillageTownCity,
          tehsilBlockMandal: item.presentTehsilBlockMandal,
          country: item.presentCountry,
          state: item.presentState,
          district: item.presentDistrict,
          pinCode: item.presentPinCode,
          policeStation: item.presentPoliceStation,
          isCurrent: "true"
        });
      }
      let criminalHistoryBean = {
        firstName: item.firstName,
        middleName: item.middleName,
        lastName: item.lastName,
        relativeName: item.relativeName,
        fir_reg_num: item.FIR_REG_NUM,
        fir_district_cd: item.fir_district_cd,
        fir_ps_cd: item.fir_ps_cd,
        firDisplay: item.firDisplay,
        accused_srno: item.accused_srno,
        address_srno: item.address_srno,
        listCriminalHistoryAddressBean: accusedOtherAddresses,
        listCriminalHistoryFIRBean: FIRActSection,
        isSelected: "true"
      };
      values.push(criminalHistoryBean);
      this.setState({ values }, () => {
        if (this.state.values.length === 1) {
          this.setState({
            isNothingSelected: false
          });
        }
      });
    } else {
      let values = [...this.state.values];
      for (let i = 0; i < values.length; i++) {
        if (values[i].accused_srno === item.accused_srno) {
          values.splice(i, 1);
          i--;
        }
      }
      this.setState({ values }, () => {
        if (this.state.values.length === 0) {
          this.setState({
            isNothingSelected: true
          });
        }
      });
    }
  };

  onForwardHandler = () => {
    var data = {
      listCriminalHistoryBean:
        this.state.values.length !== 0
          ? this.state.values
          : [
            {
              firstName: "No criminal records found",
              middleName: "No criminal records found",
              lastName: "No criminal records found",
              relativeName: "No criminal records found",
              fir_reg_num: "No criminal records found",
              fir_district_cd: "No criminal records found",
              fir_ps_cd: "No criminal records found",
              firDisplay: "No criminal records found",
              accused_srno: "No criminal records found",
              address_srno: "No criminal records found",
              listCriminalHistoryAddressBean: [],
              listCriminalHistoryFIRBean: [],
              isSelected: "true"
            }
          ]
    };
    this.props.forwardCCTNSResults(this.props.match.params.applicationID, data);
    this.handleForwardDialogClose();
  };

  handleForwardDialogClose = () => {
    this.setState({
      isOpen: false
    });
  };

  handleForwardDialogOpen = () => {
    this.setState({
      isOpen: true
    });
  };

  onSearchClickedHandler = () => {
    var data = {
      uid: this.state.searchData.uid,
      firstName: this.state.searchData.firstName,
      lastName: this.state.searchData.lastName,
      relativeName: this.state.searchData.relativeName,
      presentHouseNo: this.state.searchData.houseNumber,
      presentStreetName: this.state.searchData.streetName,
      presentColonyLocalArea: this.state.searchData.colonyLocalArea,
      presentVillageTownCity: this.state.searchData.villageTownCity,
      presentTehsilBlockMandal: this.state.searchData.tehsilBlockMandal,
      presentCountry: this.state.searchData.country,
      presentState: this.state.searchData.state,
      presentDistrict: this.state.searchData.district,
      presentPoliceStation: this.state.searchData.policeStation,
      presentPinCode: this.state.searchData.pincode,
      lowerLimit: 0,
      upperLimit: 10
    };
    this.props.retrieveCCTNSResults(data);
  };

  onAddressesDetailsClickHandler = (addressId, accusedSrNo) => {
    let params = {
      address_srno: addressId,
      accused_srno: accusedSrNo
    };
    this.props.retrieveAddresses(params);
  };

  onFIRSectionsDetailsClickHandler = firSectionId => {
    let params = {
      FIR_REG_NUM: firSectionId
    };
    this.props.retrieveFIRSections(params);
  };

  checkIfFIRSectionAlreadyCalled = firSectionId => {
    let values = [...this.state.FIRActSection];
    for (var i = 0; i < values.length; i++) {
      if (values[i].FIR_REG_NUM === firSectionId) {
        return true;
      }
    }
  };

  checkIfAddressesAlreadyCalled = accused_srno => {
    let values = [...this.state.accusedOtherAddresses];
    for (var i = 0; i < values.length; i++) {
      if (values[i].accused_srno === accused_srno) {
        return true;
      }
    }
  };

  checkIfSelected = accused_srno => {
    let values = [...this.state.values];
    console.log(values);
    for (var i = 0; i < values.length; i++) {
      if (values[i].accused_srno === accused_srno) {
        console.log("inside truth");
        return true;
      }
    }
  };

  resetStates = () => {
    this.props.resetProps();
    this.setState({
      values: [],
      isNothingSelected: true,
      isOpen: false,
      accusedOtherAddresses: [],
      FIRActSection: [],
      searchData: {
        firstName: "",
        lastName: "",
        relativeName: "",
        uid: "",
        policeStation: "",
        houseNumber: "",
        streetName: "",
        colonyLocalArea: "",
        villageTownCity: "",
        tehsilBlockMandal: "",
        country: "",
        state: "",
        district: "",
        pincode: ""
      }
    });
  };

  countryDropDownChange = event => {
    this.setState(
      {
        ...this.state,
        searchData: {
          ...this.state.searchData,
          country: event.target.value,
          state: "",
          district: "",
          policeStation: ""
        }
      },
      () => {
        if (event.target.value !== "") {
          let params = {
            country: this.state.searchData.country
          };
          this.props.retrieveStateData(params);
        }
      }
    );
  };

  stateDropDownChange = event => {
    this.setState(
      {
        ...this.state,
        searchData: {
          ...this.state.searchData,
          state: event.target.value,
          district: "",
          policeStation: ""
        }
      },
      () => {
        if (event.target.value !== "") {
          let params = {
            state: this.state.searchData.state
          };
          this.props.retrieveDistrictData(params);
        }
      }
    );
  };

  districtDropDownChange = event => {
    this.setState(
      {
        ...this.state,
        searchData: {
          ...this.state.searchData,
          district: event.target.value,
          policeStation: ""
        }
      },
      () => {
        if (event.target.value !== "") {
          let params = {
            district: this.state.searchData.district
          };
          this.props.retrieveStationData(params);
        }
      }
    );
  };
  stationDropDownChange = event => {
    this.setState({
      ...this.state,
      searchData: {
        ...this.state.searchData,
        policeStation: event.target.value
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.root} justify="center" container>
        <Grid item xs={12} md={8}>
          {this.props.retrieveCCTNSResultsData &&
            this.props.retrieveCCTNSResultsData.length !== 0 ? null : (
              <EmptyListing emptyText="No Results" />
            )}
          <ExpansionPanel className={classes.searchBar}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Grid xs={12} container>
                <Grid item className={classes.headingGrid}>
                  <Typography variant="subheading">
                    Click to enter search keywords
                  </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid row container>
                {this.props.retrievePassportData ? (
                  <Grid spacing={24} container>
                    <Grid item xs={12}>
                      <Typography variant="subheading">
                        Passport Application Details
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.detailsGridStyle}
                    >
                      <Person color="action" />
                      <Typography className={classes.detailStyle}>
                        {this.props.retrievePassportData.applicant_name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.detailsGridStyle}
                    >
                      <PersonPin color="action" />
                      <Typography className={classes.detailStyle}>
                        {this.props.retrievePassportData.verification_address}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                    <Grid spacing={24} container>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Passport Details will appear here...
                      </Typography>
                      </Grid>
                    </Grid>
                  )}
                <Grid spacing={24} container style={{ marginTop: "16px" }}>
                  <Grid item xs={12}>
                    <Typography variant="subheading">
                      Search Parameters
                    </Typography>
                  </Grid>
                </Grid>
                <Grid spacing={24} container>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      id="first-name"
                      label="First Name"
                      name="firstName"
                      onChange={e => this.handleChange(e)}
                      value={this.state.searchData.firstName}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      id="last-name"
                      label="Last Name"
                      name="lastName"
                      onChange={e => this.handleChange(e)}
                      value={this.state.searchData.lastName}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      id="relative-name"
                      label="Relative's Name"
                      name="relativeName"
                      onChange={e => this.handleChange(e)}
                      value={this.state.searchData.relativeName}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      id="u-id"
                      label="Unique ID"
                      name="uid"
                      onChange={e => this.handleChange(e)}
                      value={this.state.searchData.uid}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
                <Grid spacing={24} container>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      id="house-no"
                      label="House Number"
                      name="houseNumber"
                      onChange={e => this.handleChange(e)}
                      value={this.state.searchData.houseNumber}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      id="street-name"
                      label="Street Name"
                      name="streetName"
                      onChange={e => this.handleChange(e)}
                      value={this.state.searchData.streetName}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      id="colony-local-area"
                      label="Colony/Local Area"
                      name="colonyLocalArea"
                      onChange={e => this.handleChange(e)}
                      value={this.state.searchData.colonyLocalArea}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      id="village-town-city"
                      label="Village/Town/City"
                      name="villageTownCity"
                      onChange={e => this.handleChange(e)}
                      value={this.state.searchData.villageTownCity}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
                <Grid spacing={24} container>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      id="tehsil-block-mandal"
                      label="Tehsil/Block/Mandal"
                      name="tehsilBlockMandal"
                      onChange={e => this.handleChange(e)}
                      value={this.state.searchData.tehsilBlockMandal}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      id="pin-code"
                      label="Pincode"
                      name="pincode"
                      onChange={e => this.handleChange(e)}
                      value={this.state.searchData.pincode}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControl fullWidth className={classes.dropDown}>
                      <InputLabel htmlFor="country">Country</InputLabel>
                      <Select
                        inputProps={{
                          name: "country",
                          id: ""
                        }}
                        value={this.state.searchData.country}
                        onChange={this.countryDropDownChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {this.props.countryData.map(country => (
                          <MenuItem value={country.id}>{country.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControl fullWidth className={classes.dropDown}>
                      <InputLabel htmlFor="state">State</InputLabel>
                      <Select
                        inputProps={{
                          name: "State",
                          id: ""
                        }}
                        value={this.state.searchData.state}
                        onChange={this.stateDropDownChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {this.props.stateData.map(state => (
                          <MenuItem value={state.id}>{state.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid spacing={24} container>
                  <Grid item xs={6} sm={3}>
                    <FormControl fullWidth className={classes.dropDown}>
                      <InputLabel htmlFor="district">District</InputLabel>
                      <Select
                        inputProps={{
                          name: "district",
                          id: ""
                        }}
                        value={this.state.searchData.district}
                        onChange={this.districtDropDownChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {this.props.districtData.map(district => (
                          <MenuItem value={district.id}>
                            {district.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControl fullWidth className={classes.dropDown}>
                      <InputLabel htmlFor="police-station">
                        Police Station
                      </InputLabel>
                      <Select
                        inputProps={{
                          name: "station",
                          id: ""
                        }}
                        value={this.state.searchData.policeStation}
                        onChange={this.stationDropDownChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {this.props.stationData.map(station => (
                          <MenuItem value={station.id}>{station.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
            <ExpansionPanelActions style={{ justifyContent: "space-between" }}>
              <div>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.resetStates();
                  }}
                >
                  Clear All
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={e => {
                    e.preventDefault();
                    this.onSearchClickedHandler();
                  }}
                >
                  Search
                </Button>
              </div>
            </ExpansionPanelActions>
          </ExpansionPanel>

          {this.props.retrieveCCTNSResultsData &&
            this.props.retrieveCCTNSResultsData.Accused &&
            this.props.retrieveCCTNSResultsData.Accused.length !== 0
            ? this.props.retrieveCCTNSResultsData.Accused.map((item, i) => {
              return (
                <ExpansionPanel>
                  <ExpansionPanelSummary style={{ paddingRight: 0 }}>
                    <Grid xs={12} container>
                      <Grid item className={classes.headingGrid}>
                        <Typography variant="subheading">
                          {item.firstName +
                            " " +
                            item.middleName +
                            " " +
                            item.lastName}
                        </Typography>
                        <Checkbox
                          onChange={event => {
                            event.stopPropagation();
                            this.handleCheckChange(
                              i,
                              item,
                              event.target.checked
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid row>
                      <Grid xs={12} container>
                        <Grid item md={6} smDown={12}>
                          <Typography className={classes.secondaryHeading}>
                            FIR Reg Number: {item.FIR_REG_NUM}
                          </Typography>
                        </Grid>
                        <Grid item md={6} smDown={12}>
                          <Typography className={classes.secondaryHeading}>
                            FIR District: {item.fir_district_cd}
                          </Typography>
                        </Grid>
                        <Grid item md={6} smDown={12}>
                          <Typography className={classes.detailsHeading}>
                            FIR Police Station: {item.fir_ps_cd}
                          </Typography>
                        </Grid>
                        <Grid item md={6} smDown={12}>
                          <Typography className={classes.detailsHeading}>
                            FIR Display: {item.firDisplay}
                          </Typography>
                        </Grid>
                        <Grid item md={12} smDown={12}>
                          <Typography className={classes.detailsHeading}>
                            Address:{" "}
                            {item.presentHouseNo +
                              ", " +
                              item.presentStreetName +
                              ", " +
                              item.presentColonyLocalArea +
                              ", " +
                              item.presentVillageTownCity +
                              ", " +
                              item.presentTehsilBlockMandal}
                          </Typography>
                        </Grid>
                      </Grid>
                      {this.state.accusedOtherAddresses &&
                        this.state.accusedOtherAddresses.length !== 0
                        ? this.state.accusedOtherAddresses.map(
                          addressData => {
                            return addressData.AccusedAddress &&
                              addressData.AccusedAddress.length !== 0 &&
                              addressData.accused_srno === item.accused_srno
                              ? addressData.AccusedAddress.map(
                                (addressItem, i) => {
                                  if (addressItem.isCurrent !== "true") {
                                    return (
                                      <Grid
                                        container
                                        spacing={24}
                                        className={classes.addressDetails}
                                      >
                                        <Grid item xs={12}>
                                          <Typography
                                            variant="subheading"
                                            color="inherit"
                                          >
                                            Address {i + 1}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <TextField
                                            id="adresses"
                                            name="addresses"
                                            disabled
                                            value={
                                              addressItem.houseNo +
                                              ", " +
                                              addressItem.streetName +
                                              ", " +
                                              addressItem.colonyLocalArea +
                                              ", " +
                                              addressItem.villageTownCity +
                                              ", " +
                                              addressItem.tehsilBlockMandal
                                            }
                                            fullWidth
                                          />
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                }
                              )
                              : null;
                          }
                        )
                        : null}
                      {this.state.FIRActSection &&
                        this.state.FIRActSection.length !== 0
                        ? this.state.FIRActSection.map(firSectionData => {
                          return firSectionData.FIRActSectionDetail &&
                            firSectionData.FIRActSectionDetail.length !==
                            0 &&
                            firSectionData.FIR_REG_NUM === item.FIR_REG_NUM
                            ? firSectionData.FIRActSectionDetail.map(
                              (firItem, i) => {
                                return (
                                  <Grid
                                    container
                                    spacing={24}
                                    className={classes.addressDetails}
                                  >
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="subheading"
                                        color="inherit"
                                      >
                                        FIR Details {i + 1}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        id="fir-act-name"
                                        name="firActName"
                                        disabled
                                        value={
                                          "Act Name: " +
                                          firItem.crimeActName
                                        }
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        id="fir-section"
                                        name="section"
                                        disabled
                                        value={
                                          "Section: " +
                                          firItem.crimeSection
                                        }
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )
                            : null;
                        })
                        : null}
                    </Grid>
                  </ExpansionPanelDetails>
                  <Divider />
                  <ExpansionPanelActions
                    style={{ justifyContent: "space-between" }}
                  >
                    <Button
                      color="primary"
                      disabled={
                        this.checkIfAddressesAlreadyCalled(
                          item.accused_srno
                        ) ||
                        this.checkIfSelected(item.accused_srno) ||
                        this.props.isLoading
                      }
                      onClick={() => {
                        this.onAddressesDetailsClickHandler(
                          item.address_srno,
                          item.accused_srno
                        );
                      }}
                    >
                      View Addresses
                      </Button>
                    <Button
                      color="primary"
                      disabled={
                        this.checkIfFIRSectionAlreadyCalled(
                          item.FIR_REG_NUM
                        ) ||
                        this.checkIfSelected(item.accused_srno) ||
                        this.props.isLoading
                      }
                      onClick={() => {
                        this.onFIRSectionsDetailsClickHandler(
                          item.FIR_REG_NUM
                        );
                      }}
                    >
                      View FIR Details
                      </Button>
                  </ExpansionPanelActions>
                </ExpansionPanel>
              );
            })
            : null}
        </Grid>
        {this.props.isLoading ? <CircularIndeterminate /> : null}
        <Fab
          color="primary"
          aria-label="Forward"
          className={classes.fab}
          disabled={!this.props.searchDone}
          onClick={event => {
            event.preventDefault();
            this.handleForwardDialogOpen();
          }}
        >
          <Send />
        </Fab>
        {this.state.isOpen ? (
          <AlertDialog
            dialogTitle={"CCTNS"}
            alert={
              "Are you sure you want to forward this result?" +
              (this.state.isNothingSelected ? " (No record selected)" : "")
            }
            isOpen={true}
            positiveAction={e => {
              e.preventDefault();
              this.onForwardHandler();
            }}
            negativeAction={e => {
              e.preventDefault();
              this.handleForwardDialogClose();
            }}
          />
        ) : null}
      </Grid>
    );
  }
}

CCTNSResults.propTypes = {
  classes: PropTypes.object.isRequired
};

export const styledCCTNSResults = withStyles(styles)(CCTNSResults);

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    retrieveCCTNSResultsData: state.cctnsPassport.retrieveCCTNSResultsData,
    retrievePassportData: state.cctnsPassport.retrievePassportData,
    retrieveAddressesData: state.cctnsPassport.retrieveAddressesData,
    retrieveFIRSectionsData: state.cctnsPassport.retrieveFIRSectionsData,
    error: state.cctnsPassport.error,
    isLoading: state.cctnsPassport.isLoading,
    forwardMessage: state.cctnsPassport.forwardMessage,
    forwardSuccess: state.cctnsPassport.forwardSuccess,
    searchDone: state.cctnsPassport.searchDone,
    transactionHash: state.cctnsPassport.transactionHash,
    countryData: state.cctnsPassport.countryList,
    stateData: state.cctnsPassport.stateList,
    districtData: state.cctnsPassport.districtList,
    stationData: state.cctnsPassport.stationList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHeader: data => dispatch(setHeader(data)),
    setBackURL: data => dispatch(setBackURL(data)),
    showAlert: messageInfo => dispatch(showAlert(messageInfo)),
    navigateTo: url => dispatch(push(url)),
    retrievePassportDetails: id => dispatch(retrievePassportDetails(id)),
    retrieveCCTNSResults: data => dispatch(retrieveCCTNSResults(data)),
    retrieveAddresses: params => dispatch(retrieveAddresses(params)),
    retrieveFIRSections: params => dispatch(retrieveFIRSections(params)),
    forwardCCTNSResults: (id, data) => dispatch(forwardCCTNSResults(id, data)),
    resetProps: () => dispatch(resetProps()),
    resetAppbar: () => dispatch(resetAppbar()),
    retrieveCountryData: () => dispatch(retrieveCountryData()),
    retrieveStateData: params => dispatch(retrieveStateData(params)),
    retrieveDistrictData: params => dispatch(retrieveDistrictData(params)),
    retrieveStationData: params => dispatch(retrieveStationData(params))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledCCTNSResults);
