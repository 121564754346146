export const styles = theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
      padding: theme.spacing.unit * 4
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit * 4
    },
    overflowX: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "Center"
  },
  button: {
    position: "inherit",
    bottom: theme.spacing.unit * 3,
    width: "100%",
    marginTop: theme.spacing.unit * 4
  },
  heading: {
    width: "100%",
    textAlign: "center",
    padding: theme.spacing.unit * 4,
    // color: theme.palette.primary.main,
    letterSpacing: "2px",
    color: "#fff"
  },
  content: {
    width: "100%",
    marginTop: theme.spacing.unit * 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
  },
  imageStyle: {
    height: "96px"
  },
  imageSpan: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  paper: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "60%"
    },
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 8,
    borderRadius: "8px"
  },
  headingPaper: {
    marginTop: theme.spacing.unit * -8,
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 0, 0,.4)"
  }
});
