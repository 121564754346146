import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { middleware as reduxPackMiddleware } from "redux-pack";
import { createBrowserHistory } from "history";
import createRootReducer from "./rootReducer";

export const history = createBrowserHistory();

const initialState = {};

const middleware = [thunk, routerMiddleware(history), reduxPackMiddleware];

if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}

export default createStore(
  createRootReducer(history),
  initialState,
  compose(applyMiddleware(...middleware))
);
