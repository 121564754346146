import requestHandler from "../../../services/api/requestHandler";

import { SIGNUP_API } from "../../../config/apiUrls.js";

import { SIGNUP } from "./constants";

export function signUp(data) {
  return requestHandler(SIGNUP, SIGNUP_API, "POST", {
    data
  });
}
