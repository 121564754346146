import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { DatePicker } from "material-ui-pickers";

import dropdownData from "../../constants";

const DetailsForm = ({ onChange, onDateChange, data, errors }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Applicant Details
      </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={6}>
          <FormControl error fullWidth>
            <TextField
              required
              id="fname"
              name="firstName"
              value={data.firstName ? data.firstName : ""}
              onChange={onChange}
              label="First Name"
              fullWidth
              autoComplete="fname"
            />
            {errors.firstName ? (
              <FormHelperText>{errors.firstName}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="middleName"
            name="middleName"
            value={data.middleName ? data.middleName : ""}
            onChange={onChange}
            label="Middle Name"
            fullWidth
            autoComplete="middleName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl error fullWidth>
            <TextField
              required
              id="lname"
              name="lastName"
              value={data.lastName ? data.lastName : ""}
              onChange={onChange}
              label="Last Name"
              fullWidth
              autoComplete="lname"
            />
            {errors.lastName ? (
              <FormHelperText>{errors.lastName}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="email"
            name="email"
            value={data.email ? data.email : ""}
            onChange={onChange}
            label="Email"
            fullWidth
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="relativeName"
            name="relativeName"
            value={data.relativeName ? data.relativeName : ""}
            onChange={onChange}
            label="Relative Name"
            fullWidth
            autoComplete="relativeName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="relation-type">Relation Type</InputLabel>
            <Select
              inputProps={{
                name: "relationType",
                id: "relation-type"
              }}
              value={data.relationType ? data.relationType : ""}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dropdownData.relationType.map(item => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={errors.nationality ? true : false}>
            <InputLabel htmlFor="nationality">Nationality*</InputLabel>
            <Select
              inputProps={{
                name: "nationality",
                id: "nationality"
              }}
              value={data.nationality ? data.nationality : ""}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dropdownData.countries.map(item => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            {errors.nationality ? (
              <FormHelperText>{errors.nationality}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={errors.gender ? true : false}>
            <InputLabel htmlFor="gender">Gender*</InputLabel>
            <Select
              inputProps={{
                name: "gender",
                id: "gender"
              }}
              value={data.gender ? data.gender : ""}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dropdownData.gender.map(item => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
            {errors.gender ? (
              <FormHelperText>{errors.gender}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl error fullWidth>
            <TextField
              required
              id="mobNum"
              name="mobileNumber"
              value={data.mobileNumber ? data.mobileNumber : ""}
              onChange={onChange}
              label="Mobile Number"
              fullWidth
              autoComplete="mobnumber"
            />
            {errors.mobileNumber ? (
              <FormHelperText>{errors.mobileNumber}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="landline"
            name="landline"
            value={data.landline ? data.landline : ""}
            onChange={onChange}
            label="Landline Number"
            fullWidth
            autoComplete="landline"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            error={errors.identificationType ? true : false}
          >
            <InputLabel htmlFor="identification-type">
              Identification Type*
            </InputLabel>
            <Select
              inputProps={{
                name: "identificationType",
                id: "identification-type"
              }}
              value={data.identificationType ? data.identificationType : ""}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dropdownData.identificationTypes.map(item => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
            {errors.identificationType ? (
              <FormHelperText>{errors.identificationType}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error>
            <TextField
              id="identificationNumber"
              name="identificationNumber"
              required
              value={data.identificationNumber ? data.identificationNumber : ""}
              onChange={onChange}
              label="Identification Number"
              fullWidth
              autoComplete="idnum"
            />
            {errors.identificationNumber ? (
              <FormHelperText>{errors.identificationNumber}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error>
            <DatePicker
              fullWidth
              required
              disableFuture
              openTo="year"
              views={["year", "month", "day"]}
              placeholder="13/03/2019"
              format="DD/MM/YYYY"
              mask={value =>
                value
                  ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                  : []
              }
              keyboard
              autoOk
              label="DOB"
              name="dob"
              value={data.dob ? data.dob : null}
              onChange={date => onDateChange("dob", date)}
              disableOpenOnEnter
            />
            {errors.dob ? <FormHelperText>{errors.dob}</FormHelperText> : ""}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsForm;
