import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Lock from "@material-ui/icons/LockOutlined";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { push, goBack } from "connected-react-router";
import { styles } from "./styles";
import { logout, logoutclicked } from "../../data/session/actions";
import { resetAppbar } from "./data/actions";
import classNames from "classnames";
import AppbarTabs from "../appbarTabs/";
import { Divider, Hidden } from "@material-ui/core";
import UltsLogo from "../../assets/images/ults-logo.png";
import Tooltip from "@material-ui/core/Tooltip";
import { openClosePasswordDialog } from "../Dialogs/changePassword/data/actions";

class Appbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onChangePasswordClick = () => {
    this.props.openClosePasswordDialog();
  };

  onLogout = () => {
    this.props.logoutclicked();
    setTimeout(() => {
      this.props.logout();
    }, 500);
  };

  render() {
    const { classes, width, isSideDrawerOpen } = this.props;
    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          elevation={0}
          className={
            isWidthUp("sm", width)
              ? classNames(classes.appBar, {
                  [classes.appBarShift]: isSideDrawerOpen
                })
              : classes.appBar
          }
        >
          <Toolbar disableGutters={true}>
            {console.log(this.props.url)}
            {!this.props.url ? (
              <IconButton
                color="default"
                aria-label="Open drawer"
                onClick={() => this.props.openSideDrawer()}
                className={classNames(classes.menuButton, {
                  [classes.hide]: isSideDrawerOpen
                })}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <IconButton
                className={classes.menuButton}
                color="default"
                aria-label="go back"
                onClick={() => {
                  this.props.resetAppbar();
                  this.props.navigateTo(this.props.url);
                }}
              >
                <ArrowBack />
              </IconButton>
            )}
            <section className={classes.headingGrid}>
              {isWidthUp("xs", width) && (
                <Typography
                  variant="h6"
                  color="textPrimary"
                  noWrap
                  className={isSideDrawerOpen ? classes.headingTextStyle : null}
                >
                  {this.props.header}
                </Typography>
              )}
              <div className={classes.poweredByStyle}>
                <Tooltip
                  title="Reset Password"
                  placement="left-start"
                  className={classes.poweredByTextStyle}
                >
                  <IconButton
                    color="default"
                    onClick={() => this.onChangePasswordClick()}
                  >
                    <Lock />
                  </IconButton>
                </Tooltip>
                <Typography className={classes.poweredByStyle}>
                  <Hidden smDown>
                    <span className={classes.poweredByTextStyle}>
                      Powered by
                    </span>
                    <img
                      className={classes.poweredByLogoStyle}
                      src={UltsLogo}
                      alt="ULTS"
                    />
                  </Hidden>
                </Typography>
              </div>
            </section>
          </Toolbar>
          <AppbarTabs />
          <Divider />
        </AppBar>
      </div>
    );
  }
}

Appbar.propTypes = {
  classes: PropTypes.object.isRequired,
  openSideDrawer: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    header: state.appbar.header,
    url: state.appbar.url,
    userId: state.auth.info.user_id,
    path: state.router.location.pathname
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: url => dispatch(push(url)),
    logout: () => dispatch(logout()),
    logoutclicked: () => dispatch(logoutclicked()),
    back: () => dispatch(goBack()),
    resetAppbar: () => dispatch(resetAppbar()),
    openClosePasswordDialog: () => dispatch(openClosePasswordDialog())
  };
}

const styledAppBar = withStyles(styles)(withWidth()(Appbar));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledAppBar);
