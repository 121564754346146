import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { connect } from "react-redux";
import { styles } from "./styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { retrieveNOCApplication } from "./data/action";
import { showAlert } from "../../../components/snackbar/data/actions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CircularIndeterminate from "../../../components/circular/CircularIndeterminate";
import EmptyListing from "../../../components/emptyListing";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ApplicationDetailsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {}
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.props.retrieveNOCApplication(this.props.appliationId);
    }
    if (
      this.props.retrieveNOCApplicationData &&
      prevProps.retrieveNOCApplicationData !==
        this.props.retrieveNOCApplicationData
    ) {
    }
    if (this.props.error !== prevProps.error) {
      let messageInfo = { message: this.props.error, variant: "error" };
      this.props.showAlert(messageInfo);
    }
  }

  handleChange = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: ""
      }
    });
  };

  handleCancel = () => {
    this.props.handleApplicationsDetailsDialogClose();
  };

  resetState = () => {
    this.setState({});
  };

  render() {
    const { classes, retrieveNOCApplicationData } = this.props;
    const { data, errors } = this.state;

    return (
      <>
        <Dialog
          fullScreen
          open={this.props.isOpen}
          onClose={this.handleCancel}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Application Details
              </Typography>
            </Toolbar>
          </AppBar>
          {retrieveNOCApplicationData ? (
            <Grid container spacing={24} className={classes.root}>
              <Grid item xs={12}>
                <Typography
                  variant="button"
                  style={{ marginTop: 8, marginBottom: 0 }}
                >
                  Application Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="firstName"
                  name="firstName"
                  disabled
                  value={
                    "First Name: " +
                    retrieveNOCApplicationData.application_details.firstName
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  disabled
                  value={
                    "Last Name: " +
                    retrieveNOCApplicationData.application_details.lastName
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="mobileNumber2"
                  name="mobileNumber2"
                  disabled
                  value={
                    "Mobile: " +
                    retrieveNOCApplicationData.application_details.mobileNumber2
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="presentHouseNo"
                  name="presentHouseNo"
                  disabled
                  value={
                    "House No/House Name: " +
                    retrieveNOCApplicationData.application_details
                      .presentHouseNo
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="presentVillageTownCity"
                  name="presentVillageTownCity"
                  disabled
                  value={
                    "Village/Town/City: " +
                    retrieveNOCApplicationData.application_details
                      .presentVillageTownCity
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="presentDistrict"
                  name="presentDistrict"
                  disabled
                  value={
                    "District: " +
                    retrieveNOCApplicationData.application_details
                      .presentDistrict
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="presentState"
                  name="presentState"
                  disabled
                  value={
                    "State: " +
                    retrieveNOCApplicationData.application_details.presentState
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="presentCountry"
                  name="presentCountry"
                  disabled
                  value={
                    "Country: " +
                    retrieveNOCApplicationData.application_details
                      .presentCountry
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="button"
                  style={{ marginTop: 16, marginBottom: 0 }}
                >
                  Criminal Verification Details
                </Typography>
              </Grid>
              {retrieveNOCApplicationData.cpo ? (
                <Grid
                  container
                  spacing={24}
                  className={classes.criminalDetailsGrid}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="crime"
                      name="crime"
                      disabled
                      value={
                        retrieveNOCApplicationData.cpo.crime
                          ? "Crime: " + retrieveNOCApplicationData.cpo.crime
                          : "Crime: Nil"
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="jurisdiction"
                      name="jurisdiction"
                      disabled
                      value={
                        retrieveNOCApplicationData.cpo &&
                        retrieveNOCApplicationData.cpo.jurisdiction
                          ? "Jurisdiction: " +
                            retrieveNOCApplicationData.cpo.jurisdiction
                          : "Jurisdiction: Nil"
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="law"
                      name="law"
                      disabled
                      value={
                        retrieveNOCApplicationData.cpo &&
                        retrieveNOCApplicationData.cpo.law
                          ? "Law: " + retrieveNOCApplicationData.cpo.law
                          : "Law: Nil"
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 0 }}
                    color="primary"
                  >
                    Pending...
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography
                  variant="button"
                  style={{ marginTop: 16, marginBottom: 0 }}
                >
                  Approval Details
                </Typography>
              </Grid>
              {retrieveNOCApplicationData.commissioner ? (
                <Grid
                  container
                  spacing={24}
                  className={classes.approvalDetailsGrid}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="remarks"
                      name="remarks"
                      disabled
                      value={
                        retrieveNOCApplicationData.commissioner.remarks
                          ? "Remarks: " +
                            retrieveNOCApplicationData.commissioner.remarks
                          : "Remarks: Nil"
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {retrieveNOCApplicationData.commissioner.attachment ? (
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => {
                          window.open(
                            retrieveNOCApplicationData.commissioner.attachment,
                            "_blank"
                          );
                        }}
                      >
                        Click to view attachment
                        <CloudDownload style={{ marginLeft: 16 }} />
                      </Button>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 0 }}
                    color="primary"
                  >
                    Pending...
                  </Typography>
                </Grid>
              )}
            </Grid>
          ) : (
            <EmptyListing emptyText="Your application details will appear here" />
          )}
          {this.props.isLoading ? <CircularIndeterminate /> : null}
        </Dialog>
      </>
    );
  }
}

ApplicationDetailsDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    userInfo: state.auth.info,
    retrieveNOCApplicationData:
      state.nocApplicationDetails.retrieveNOCApplicationData,
    error: state.nocApplicationDetails.error,
    isLoading: state.nocApplicationDetails.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    retrieveNOCApplication: id => dispatch(retrieveNOCApplication(id)),
    showAlert: messageInfo => dispatch(showAlert(messageInfo))
  };
}

export const styledApplicationDetailsDialog = withStyles(styles)(
  withMobileDialog()(ApplicationDetailsDialog)
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledApplicationDetailsDialog);
