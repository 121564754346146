import { handle } from "redux-pack";

import { AGE_REPORT, RESET_AGE_REPORT_PROPS } from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  ageReportData: [],
  totalCount: 0
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AGE_REPORT:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: false,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => ({
          ...prevState,
          ageReportData: payload.data.data,
          totalCount: payload.data.total_count
        })
      });
    case RESET_AGE_REPORT_PROPS:
      return {
        isLoading: false,
        error: null,
        ageReportData: [],
        totalCount: 0
      };
    default:
      return state;
  }
};
