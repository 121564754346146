import {
  NOC_OUTBOUND_REQUEST_ACTION,
  NOC_OUTBOUND_RESET_REQUEST
} from "./constants";
import requestHandler from "../../../../services/api/requestHandler";
import { NOC_API } from "../../../../config/apiUrls.js";

export function outboundRequestAction(id, data) {
  return requestHandler(
    NOC_OUTBOUND_REQUEST_ACTION,
    `${NOC_API}${id}/`,
    "PUT",
    {
      data
    }
  );
}

export function resetProps() {
  return { type: NOC_OUTBOUND_RESET_REQUEST };
}
