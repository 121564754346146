import { handle } from "redux-pack";

import {
  PCC_HISTORY_LIST,
  NOC_HISTORY_LIST,
  PCC_HISTORY_LOG,
  NOC_HISTORY_LOG,
  RESET_HISTORY_LOG,
  RESET_HISTORY_PROPS,
  PASSPORT_HISTORY_LIST,
  PASSPORT_HISTORY_LOG,
  PV_REPORT_VIEW
} from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  message: "",
  historyList: [],
  historyLog: [],
  pvCertificateData: "",
  totalCount: 0
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PCC_HISTORY_LIST:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null,
            historyList: [],
            historyLog: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            historyList: payload.data.results,
            totalCount: payload.data.total_count
          };
        }
      });
    case NOC_HISTORY_LIST:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null,
            historyList: [],
            historyLog: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            historyList: payload.data.results,
            totalCount: payload.data.total_count
          };
        }
      });
    case PCC_HISTORY_LOG:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            historyLog: payload.data
          };
        }
      });
    case NOC_HISTORY_LOG:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            historyLog: payload.data
          };
        }
      });
    case PASSPORT_HISTORY_LOG:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            historyLog: payload.data
          };
        }
      });
    case PASSPORT_HISTORY_LIST:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null,
            historyList: [],
            historyLog: []
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error[0]
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            historyList: payload.data.results,
            totalCount: payload.data.total_count
          };
        }
      });
    case PV_REPORT_VIEW:
      return handle(state, action, {
        start: prevState => {
          return {
            ...prevState,
            isLoading: true,
            error: null
          };
        },
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({
          ...prevState,
          error:
            payload.response !== undefined
              ? payload.response.data.error
              : "Something went wrong. Please try after some time"
        }),
        success: prevState => {
          return {
            ...prevState,
            pvCertificateData: payload.data
          };
        }
      });
    case RESET_HISTORY_LOG:
      return {
        ...state,
        historyLog: []
      };
    case RESET_HISTORY_PROPS:
      return {
        isLoading: false,
        error: null,
        message: "",
        historyList: [],
        historyLog: [],
        totalCount: 0
      };
    default:
      return state;
  }
};
